import { Dispatch } from "redux";
import API from "../api/index.js";
import { Status } from "./reducers.js";

export const NOOP = "NOOP";
export function noop() {
	return {
		type: NOOP,
	};
}

export const REQUEST_STATUS = "REQUEST_STATUS";
export function requestStatus(): Action {
	return {
		type: REQUEST_STATUS,
	};
}

export const RECEIVE_STATUS = "RECEIVE_STATUS";
export function receiveStatus(status: Status): Action {
	return {
		type: RECEIVE_STATUS,
		status,
	};
}

export const INVALIDATE_STATUS = "INVALIDATE_STATUS";
export function invalidateStatus(): Action {
	return {
		type: INVALIDATE_STATUS,
	};
}

export function fetchStatus() {
	return async function (dispatch: Dispatch): Promise<unknown> {
		dispatch(requestStatus());
		const status = await API.get("/login");
		return dispatch(receiveStatus(status));
	};
}

export const SET_TITLE = "SET_TITLE";
export function setTitle(title: string): Action {
	return {
		type: SET_TITLE,
		title,
	};
}

export type Action =
	| { type: typeof NOOP }
	| { type: typeof REQUEST_STATUS }
	| { type: typeof RECEIVE_STATUS; status: Status }
	| { type: typeof INVALIDATE_STATUS }
	| { type: typeof SET_TITLE; title: string };

export type ActionType = Action["type"];
