import { LitElement, html } from "lit";

const formatter = new Intl.DateTimeFormat("da-DK", {
	year: "numeric",
	month: "2-digit",
	day: "2-digit",
});

// Date -> dd-mm-yyyy
const format = (date) =>
	formatter.format(date).replace(/\//g, "-").replace(/\./g, "-");

// dd-mm-yyyy -> yyyy-mm-dd
// yyyy-mm-dd -> dd-mm-yyyy
const reverse = (str) => str.split("-").reverse().join("-");

// Date -> yyyy-mm-dd
const toInputValue = (date) => reverse(format(date)).replace(/\u200E/g, "");

// dd-mm-yyyy -> Date
const toDateValue = (value) => new Date(...value.split("-").reverse());

export class DateRangeElement extends LitElement {
	constructor() {
		super();
		this.changeFrom = this.changeFrom.bind(this);
		this.changeTo = this.changeTo.bind(this);
	}

	static get properties() {
		return {
			from: {
				type: String,
			},
			to: {
				type: String,
			},
			names: {
				type: String,
			},
		};
	}

	get value() {
		return {
			from: this.from,
			to: this.to,
		};
	}

	render() {
		return html`
  <style>
  :host {
    padding: 16px;
  }
  
  .date-input {
    font-size: 16px;
    border: 1px solid #ddd;
    padding: 8px;
    font-family: 'Roboto', sans-serif;
    max-width:160px;
  }
  
  .date-divider {
    display: inline-flex;
    align-items: center;
    margin: 0 16px;
    font-size: 16px;
  }
  
  .submit-button {
    margin: 0 8px;
    display: inline-flex;
  }
  
  * {
    box-sizing: border-box;
  }
  </style>
    <input type="date" class="date-input" id="date_range_from" value="${this.from}" @change="${this.changeFrom}">
    <div class="date-divider">til</div>
    <input type="date" class="date-input" id="date_range_to" value="${this.to}" @change="${this.changeTo}">`;
	}

	changeFrom() {
		this.from = this.shadowRoot.querySelector("#date_range_from").value;
	}

	changeTo() {
		this.to = this.shadowRoot.querySelector("#date_range_to").value;
	}

	connectedCallback() {
		super.connectedCallback();
		let from = localStorage.getItem(`${this.names || ""}fromdate`);
		const todate = new Date();
		if (!from) {
			const fromdate = new Date(todate.getFullYear(), 0, 1);
			from = toInputValue(fromdate);
		}
		this.from = from;
		let to = localStorage.getItem(`${this.names || ""}todate`);
		if (!to) {
			to = toInputValue(todate);
		}
		this.to = to;
	}

	getStringValues() {
		return {
			from: this.from,
			to: this.to,
		};
	}

	getDateValues() {
		return {
			from: toDateValue(this.from),
			to: toDateValue(this.to),
		};
	}

	setInputValues() {
		requestAnimationFrame(() => {
			this.from = toInputValue(this.from);
			this.to = toInputValue(this.to);
		});
	}
}

customElements.define("fm-date-range", DateRangeElement);
