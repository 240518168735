import { LitElement } from "lit";
import api from "../../api/index.js";
import { DateFormatter } from "../../formatting/dateformats.js";
import { ext_render } from "./account-return-render";

export default class AccountReturnView extends LitElement {
	static get properties() {
		return {
			account_id: {
				type: String,
			},
			rows: {
				type: Array,
			},
			custodies: {
				type: Array,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.custodies = [];
		this.account_name = "";
		this.refresh = this.refresh.bind(this);
		this.download = this.download.bind(this);
		this.accountChange = this.accountChange.bind(this);
		const todate = DateFormatter.format(new Date(), "yyyy-mm-dd");
		localStorage.setItem("return-todate", todate);
		const fromdate = localStorage.getItem("return-fromdate");
		if (!fromdate) {
			localStorage.setItem("return-fromdate", todate);
		}
	}

	render() {
		return ext_render(this);
	}

	async refresh(event) {
		const target = event.target;
		const but = this.shadowRoot.querySelector("#submit_button");
		but.loading = true;
		target.loading = true;
		const f = this.shadowRoot.querySelector("#parms");
		const account_id = f.value.account_id;
		const custody_id = f.value.custody_id;
		const dt = this.shadowRoot.querySelector("#return");
		const from = dt.value.from;
		const to = dt.value.to;
		localStorage.setItem("return-fromdate", from);
		localStorage.setItem("return-todate", to);

		if (account_id) {
			this.rows = await api.get(
				`/accounts/${account_id}/return?from=${from}&to=${to}&custody_id=${custody_id}`,
			);
		} else {
			this.rows = [];
		}
		but.loading = false;
		target.loading = false;
	}

	async download(event) {
		const target = event.target;
		target.loading = true;
		const f = this.shadowRoot.querySelector("#parms");
		const account_id = f.value.account_id;
		const custody_id = f.value.custody_id;
		const dt = this.shadowRoot.querySelector("#return");
		const from = dt.value.from;
		const to = dt.value.to;
		localStorage.setItem("return-fromdate", from);
		localStorage.setItem("return-todate", to);

		if (account_id) {
			const response = await api.post(
				`/accounts/${account_id}/return?from=${from}&to=${to}&custody_id=${custody_id}`,
			);
			const a = document.createElement("a");
			a.href = `data:application/octet-stream;base64,${response.file}`;
			a.download = "AccountReturn.pdf";
			a.click();
		}
		target.loading = false;
	}

	async accountChange(event) {
		const target = event.target;
		const id = target.value;
		this.custodies = await api.get(`/accounts/${id}/custodies`);
	}
}
customElements.define("account-return-view", AccountReturnView);
