import { LitElement, html } from "lit";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { quantity, money } from "../../formatting/numberformats.js";
import { date } from "../../formatting/dateformats.js";

export default class AccountReportView extends LitElement {
	static get properties() {
		return {
			account_id: {
				type: String,
			},
			row: {
				type: Object,
			},
			rerender: {
				type: Number,
			},
		};
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1400px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>Periodic Report</h1>
        </div>
        <div class="card-block">
        <fm-autocomplete 
            id="account" 
            url="/lov/accounts"
            .value="${{
							id: this.account_id || "",
							name: this.row.account_name || "",
						}}"
            select="id"
            display="name"
           >
            </fm-autocomplete>
          From <input type="date" id="fromdate" class="date-input" value="${
						this.fromdate
					}">
          To <input type="date" id="todate" class="date-input" value="${
						this.todate
					}">
          <fm-button class="btn" @click="${this.refresh}" >Refresh</fm-button>
          <button class="btn" @click="${this.download}" >Download</button>
      </div>
      <div class="card-header">
        <h2>${this.row.account_name} Perioderapport fra ${
			this.row.fromdate
		} til ${this.row.todate}</h2>
      </div>
      <div class="card-block">
        ${this.renderOverview()}
      </div>
      <div class="card-block">
      ${this.renderExposure()}
      </div>
      ${(this.row.custodies || []).map((c) => this.renderCustody(c))}
       </div>
    `;
	}

	renderCustody(c) {
		return html`
      <div class="card-block" style="justify-content: space-between;">
        <div style="display:block;width:50%;padding:4px;border-right:1px solid black">
          <table><caption>Investeringskonto ${c.custody_code}</caption>
            <tr>
              <td>Skattetype</td>
              <td class="numeric">${c.custodytype}</td>
            </tr>
            <tr>
            <td>Værdi primo</td>
            <td class="numeric">${money(c.o_primovalue)}</td>
           </tr>
            <tr>
              <td>Indbetalinger</td>
              <td class="numeric">${money(c.o_inflow)}</td>
            </tr>
            <tr>
              <td>Udbetalinger</td>
              <td class="numeric">${money(c.o_outflow)}</td>
            </tr>
            <tr>
              <td>Afkast</td>
              <td class="numeric">${money(c.o_result)}</td>
            </tr>
            <tr>
              <td>Heraf rådgivningshonorar</td>
              <td class="numeric">${money(c.o_advisorfee)}</td>
          </tr>
          <tr>
            <td>Værdi ultimo</td>
            <td class="numeric">${money(c.o_ultimovalue)}</td>
          </tr>

         </table>
        </div>
      </div>
        <div style="display:block;width:50%;padding:4px;">
          <table><caption>Beholdning pr. ${this.row.todate}</caption>
            <thead>
              <tr>
                <th>Inv.bevis</th>
                <th style="text-align:right">Antal beviser</th>
                <th style="text-align:right">Værdi</th>
              </tr>
            </thead>
            <tbody>
              ${(c.positions || []).map(
								(pos) => html`
                    <tr>
                        <td>${pos.name}</td>
                        <td class="numeric">${quantity(pos.quantity)}</td>
                        <td class="numeric">${money(pos.marketvalue_pc)}</td>
                    </tr>`,
							)}
            </tbody>
          </table>
        </div>
      `;
	}

	renderOverview() {
		if (this.row.overview) {
			return html`
    <div style="display:block;width:50%;border-right:1px solid black;padding:4px;">
      <table>
        <caption>Nøgletal</caption>
        <tr>
          <td>Værdi Primo</td>
          <td style="text-align:right">${money(this.row.overview.primovalue)}</td>
        </tr>
        <tr>
          <td>Indbetalinger</td>
          <td style="text-align:right">${money(this.row.overview.inflow)}</td>
        </tr>
        <tr>
          <td>Udbetalinger</td>
          <td style="text-align:right">${money(this.row.overview.outflow)}</td>
        </tr>
        <tr>
          <td>Værdi Ultimo</td>
          <td style="text-align:right">${money(this.row.overview.ultimovalue)}</td>
        </tr>
        <tr>
          <td>Afkast</td>
          <td style="text-align:right">${money(this.row.overview.result)}</td>
        </tr>
        <tr>
          <td>Heraf rådgivningshonorar</td>
          <td style="text-align:right">${money(this.row.overview.advisorfee)}</td>
        </tr>
      </table>
    </div>
  `;
		} else {
			return html``;
		}
	}

	renderMarketExposure() {
		if (this.row.market_exposure) {
			return html`
    <table>
      <caption>Markedseksponering</caption>
      <thead>
        <tr>
          <th>Marked</th>
          <th style="text-align:right">Pct.</th>
        </tr>
      </thead>
      <tbody>
        ${this.row.market_exposure.map(
					(rec, i) =>
						html`<tr>
            <td>${rec.name}</td><td style="text-align:right">${money(
							rec.share,
						)}</td></tr>`,
				)}
      </tbody>
    </table>
    `;
		} else {
			return html``;
		}
	}

	renderCurrencyExposure() {
		if (this.row.currency_exposure) {
			return html`
    <table>
      <caption>Valutaeksponering</caption>
      <thead>
        <tr>
          <th>Valuta</th>
          <th style="text-align:right">Pct.</th>
        </tr>
      </thead>
      <tbody>
        ${this.row.currency_exposure.map(
					(rec, i) =>
						html`<tr>
            <td>${rec.name}</td><td style="text-align:right">${money(
							rec.share,
						)}</td></tr>`,
				)}
      </tbody>
    </table>
    `;
		} else {
			return html``;
		}
	}

	renderExposure() {
		return html`
  <div style="display:block;width:50%;border-right:2px solid black;padding:4px">
    <div style="display:flex">
      <div style="flex-grow:1">
        ${this.renderMarketExposure()}
      </div>
    </div>
 </div>
 <div style="display:block;width:50%;padding:4px;">
  <div style="display:flex">
    <div style="flex-grow:1">
      ${this.renderCurrencyExposure()}
    </div>
  </div>
</div>`;
	}

	constructor() {
		super();
		this.row = {};
		this.refresh = this.refresh.bind(this);
		this.download = this.download.bind(this);

		this.rerender = 0;

		const now = new Date();
		now.setDate(now.getDate() - 20);
		const quarter = Math.floor(now.getMonth() / 3);
		const fromdate = new Date(now.getFullYear(), quarter * 3, 1);
		this.fromdate = date(fromdate);
		this.todate = date(
			new Date(fromdate.getFullYear(), fromdate.getMonth() + 3, 0),
		);
	}

	async refresh(event) {
		const target = event.target;
		target.loading = true;
		const account_id = this.shadowRoot.querySelector("#account").value;
		const fromdate = this.shadowRoot.querySelector("#fromdate").value;
		const todate = this.shadowRoot.querySelector("#todate").value;
		if (account_id) {
			this.row = await api.get(
				`/accounts/${account_id}/periodic-report?from=${fromdate}&to=${todate}`,
			);
		} else {
			this.row = {};
		}
		target.loading = false;
	}

	async download() {
		const account_id = this.shadowRoot.querySelector("#account").value;
		const response = await api.post(`/files/${account_id}`, this.row);
		const a = document.createElement("a");
		a.href = `data:application/octet-stream;base64,${response}`;
		a.download = "UserReport.pdf";
		a.click();
	}
}
customElements.define("account-report-view", AccountReportView);
