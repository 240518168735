import QuestionView from "./question-view.js";
import QuestionListView from "./question-list-view.js";
import QuestionEditView from "./question-edit-view.js";
import { RouteOptions } from "runway";

export default {
	path: "questions",
	component: QuestionView,
	children: [
		{
			path: "",
			component: QuestionListView,
		},
		{
			path: "new",
			component: QuestionEditView,
		},
		{
			path: ":id/newanswer",
			component: QuestionEditView,
			properties: ({ parameters }) => ({
				question_id: parameters.get("id"),
				newanswer: true,
			}),
		},
		{
			path: ":id",
			component: QuestionEditView,
			properties: ({ parameters }) => ({
				question_id: parameters.get("id"),
			}),
		},
	],
} satisfies RouteOptions;
