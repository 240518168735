import { LitElement, html } from "lit";

import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import inputStyles from "../../styles/input.js";
import sharedStyles from "../../styles/shared.js";
import { DateFormatter } from "../../formatting/dateformats.js";
import { money } from "../../formatting/numberformats.js";

export default class ReportcashflowView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
		};
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1400px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h2>Cashflow</h2>
        </div>
        <fm-form id="parms" class="reportform no-print" >
          <div class="form-space">
            <label>Account</label>
            <fm-autocomplete
              name="account_id"
              style="min-width:300px;"
              url="/lov/accounts"
              display="name"
              select="id"
            ></fm-autocomplete>
          </div>
          <div class="form-space">
          <label>Pre period start</label>
          <input type="date"  class="date-input" name="pre_start"  value="${
						this.pre_start
					}" >
        </div>
          <div class="form-space">
            <label>From</label>
            <input type="date"  class="date-input" name="from"  value="${
							this.from
						}" >
          </div>
          <div class="form-space">
          <label>To</label>
          <input type="date"  class="date-input" name="to" value="${this.to}" >
          </div>
          <div class="form-space">
          <fm-button id="submit_button" class="btn submit" @click="${
						this.refresh
					}">Refresh</fm-button>
        </div>
      </fm-form>
      </div>
      <table>
        <thead>
          <tr>
            <th>Custody</th>
            <th>Type</th>
            <th>Owner</th>
            <th class="numeric">Pre inflow</th>
            <th class="numeric">#</th>
            <th class="numeric">Pre outflow</th>
            <th class="numeric">#</th>
            <th class="numeric">Period inflow</th>
            <th class="numeric">#</th>
            <th class="numeric">Period outflow</th>
            <th class="numeric">#</th>
          </tr>
        </thead>
        <tbody>
          ${this.rows.map(
						(r) => html`
            <tr>
                <td>${r.custody_code}</td>
                <td>${r.ct_description}</td>
                <td>${r.owner}</td>
                <td class="numeric">${money(r.pre_inflow)}</td>
                <td class="numeric">${r.pre_inflow_count}</td>
                <td class="numeric">${money(r.pre_outflow)}</td>
                <td class="numeric">${r.pre_outflow_count}</td>
                <td class="numeric">${money(r.per_inflow)}</td>
                <td class="numeric">${r.per_inflow_count}</td>
                <td class="numeric">${money(r.per_outflow)}</td>
                <td class="numeric">${r.per_outflow_count}</td>
            </tr>
        `,
					)}
        </tbody>
      </table>
    `;
	}

	constructor() {
		super();
		this.rows = [];
		this.refresh = this.refresh.bind(this);
		const todate = new Date();
		const fromdate = new Date(todate.getFullYear(), 0, 1);
		this.pre_start = DateFormatter.format(fromdate, "yyyy-mm-dd");
		this.from = DateFormatter.format(fromdate, "yyyy-mm-dd");
		this.to = DateFormatter.format(todate, "yyyy-mm-dd");
	}

	connectedCallback() {
		super.connectedCallback();
		const todate = new Date();
		const fromdate = new Date(todate.getFullYear(), 0, 1);
		this.pre_start = DateFormatter.format(fromdate, "yyyy-mm-dd");
		this.from = DateFormatter.format(fromdate, "yyyy-mm-dd");
		this.to = DateFormatter.format(todate, "yyyy-mm-dd");
	}

	async onChange() {
		const id = this.account_id;
		const pre_start = this.pre_start;
		const from = this.from;
		const to = this.to;
		if (id) {
			this.rows = await api.get(
				`/accounts/${id}/cashflow?pre_start=${pre_start}&from=${from}&to=${to}`,
			);
		} else {
			this.rows = await api.get(
				`/accounts/cashflow?pre_start=${pre_start}&from=${from}&to=${to}`,
			);
		}
	}

	async refresh() {
		const target = this.shadowRoot.querySelector("#submit_button");
		target.loading = true;
		const params = this.shadowRoot.querySelector("#parms").value;
		this.pre_start = params.pre_start;
		this.from = params.from;
		this.to = params.to;
		this.account_id = params.account_id;
		await this.onChange();
		target.loading = false;
	}
}

customElements.define("report-cashflow-view", ReportcashflowView);
