import { LitElement, html, css } from "lit-element";
import { customElement, state } from "lit/decorators.js";

import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { styles as inputStyles } from "../../styles/input.js";

import { get } from "../../api/client.js";
import { date } from "../../formatting/dateformats.js";
import { toCsv } from "../../utils.js";

declare global {
	interface HTMLElementTagNameMap {
		"custodian-list-view": CustodianListView;
	}
}

type Custodian = {
	fund_name: string;
	subfund_name: string;
	custodian_code: string;
	custodian_name: string;
	position: number;
	position_ex_code: number;
};

@customElement("custodian-list-view")
export class CustodianListView extends LitElement {
	@state()
	private custodians: Custodian[] = [];

	private sortedKey: keyof Custodian | null = null;

	@state()
	private asofdate: string = date(new Date());

	@state()
	private codes: string = "";

	@state()
	private query: string = "";

	@state()
	private total_position: number = 0;

	@state()
	private total_position_ex_code: number = 0;

	@state()
	loading: boolean = false;

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css` :host {
        display: block;
        margin: 24px auto;
        max-width: 1400px;
      }
	  th {
			cursor: pointer;
		 } 
	  `,
	];

	render() {
		return html`
      <div class="card">
        <div class="card-header">
          <h1>Custodians</h1>
        </div>
        <fm-form id="parms" class="reportform no-print" @submit="${this.refresh}">
        <div class="form-space">
          <label>Asof date</label>
          <input type="date"  class="date-input" name="asofdate" value="${
						this.asofdate
					}" >
        </div>
        <div class="form-space">
          <label>Advisor-code</label>
          <input type="text" class="search-input" name="codes" placeholder="Advisor code" .value=${
						this.codes
					}
		  @change=${(e) => {
				this.codes = e.target.value;
			}}
		  >
        </div>
        <div class="form-space">
          <label>Search</label>
          <input type="search" class="search-input" name="query" placeholder="Search" .value=${
						this.query
					}
		  @change=${(e) => {
				this.query = e.target.value;
			}}
		  >
        </div>
        <div class="form-space">
            <fm-button-v2 id="submit_button" class="btn submit" type="submit" .loading="${
							this.loading
						}">Refresh</fm-button-v2>
         </div>
         <div class="form-space">
            <fm-button-v2 id="download_button" class="btn" @click="${
							this.download
						}">Download</fm-button-v2>
        </div>
      </fm-form>

        <table>
          <thead>
            <tr>
              <th @click="${() => this.sortBy("custodian_code")}">Custodian ID</th>
              <th @click="${() => this.sortBy("custodian_name")}">Custodian</th>
              <th @click="${() => this.sortBy("fund_name")}">Fund</th>
              <th @click="${() => this.sortBy("subfund_name")}">SubFund</th>
              <th @click="${() => this.sortBy("position")}">Position</th>
              <th @click="${() => this.sortBy("position_ex_code")}">Ex code</th>
            </tr>
          </thead>
          <tbody>
            ${this.custodians.map(
							(custodian) => html`
              <tr>
                <td>${custodian.custodian_code}</td>
                <td>${custodian.custodian_name}</td>
                <td>${custodian.fund_name}</td>
                <td>${custodian.subfund_name}</td>
                <td class="numeric">${custodian.position}</td>
                <td class="numeric">${custodian.position_ex_code}</td>
              </tr>
            `,
						)}
          </tbody>
          <tfoot>
          <tr>
            <td colspan="4" style="text-align:right">Total</td>
            <td class="numeric">${this.total_position}</td>
            <td class="numeric">${this.total_position_ex_code}</td>
          </tr>
          </tfoot>

        </table>
      </div>
    `;
	}

	async connectedCallback(): Promise<void> {
		super.connectedCallback();
	}

	async fetch(values: { asofdate: string; codes: string; query: string }) {
		const result = await get<{ data: Custodian[] }>(
			`/etfs/custodians?asof=${values.asofdate}&codes=${values.codes}&query=${values.query}`,
		);

		if (result.ok === false) {
			return;
		}

		this.custodians = result.value.data;

		this.total_position = this.custodians.reduce((a, e) => a + e.position, 0);
		this.total_position_ex_code = this.custodians.reduce(
			(a, e) => a + e.position_ex_code,
			0,
		);
	}

	async refresh() {
		this.loading = true;
		await this.fetch({
			asofdate: this.asofdate,
			codes: this.codes,
			query: this.query,
		});
		this.loading = false;
	}

	private sortBy(key: keyof Custodian): void {
		if (key === this.sortedKey) {
			this.custodians = this.custodians.slice(0).reverse();
			return;
		}

		this.sortedKey = key;
		this.custodians = this.custodians.slice(0).sort((a, b) => {
			const x = a[key];
			const y = b[key];
			if (typeof x === "string" && typeof y === "string") {
				return x.localeCompare(y);
			} else if (typeof x === "number" && typeof y === "number") {
				return x - y;
			} else {
				return 0;
			}
		});
	}

	async download(event) {
		const csvHeaders = [
			"fund_name",
			"subfund_name",
			"custodian_code",
			"custodian_name",
			"position",
			"position_ex_code",
		];
		const csvFields = [
			"fund_name",
			"subfund_name",
			"custodian_code",
			"custodian_name",
			"position",
			"position_ex_code",
		];

		const txtdata = btoa(toCsv(csvHeaders, csvFields, this.custodians));
		const a = document.createElement("a");
		a.setAttribute("href", `data:text/csv;base64,${txtdata}`);
		a.setAttribute("download", "fm_custodians.csv");
		a.click();
	}
}
