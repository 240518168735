import { css } from "lit";

export const styles = css`
  :root {
    --primary-color: #00b96f;
    -webkit-font-smoothing: antialiased;
    font-family: 'Roboto', sans-serif;
	font-size: 14px;
    color: #333;
  }

  * {
    box-sizing: border-box;
  }

  router-link a {
    text-decoration: none;
    color: inherit;
  }

  router-link[active] a {
    color: var(--primary-color);
  }

  h1 {
    font-weight: 500;
    font-size: 20px;
    margin: 0;
  }

  h2 {
    font-weight: 500;
    font-size: 18px;
    margin: 0;
  }
`;
