import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../api/index.js";
import tableStyles from "../styles/tables.js";
import sharedStyles from "../styles/shared.js";
import inputStyles from "../styles/input.js";
import { toast } from "../utils.js";

class RelationElement extends LitElement {
	static get properties() {
		return {
			account_id: {
				type: String,
				reflect: true,
			},
			rerender: {
				type: Number,
			},
		};
	}

	constructor() {
		super();
		this.account_id = null;
		this.onSubmit = this.onSubmit.bind(this);
	}

	async connectedCallback() {
		super.connectedCallback();
		this.rerender = 1;
	}

	render() {
		return html`
    <style>
    ${sharedStyles}
    ${tableStyles}
    ${inputStyles}
    </style>
    <div class="card">
        <div class="card-header">
            <h2>Relation</h2>
        </div>
        <fm-form id="form"  class="form-grid" @submit="${this.onSubmit}">
        <label class="form-field">
        User
        <fm-autocomplete id="user1_id" url="/lov/users?account_id=${this.account_id}" name="user1_id" select="id" display="name"></fm-autocomplete>
        </label>
        <label class="form-field">
        is 
        <fm-autocomplete id="type_id" url="/lov/relationtypes" name="type_id" select="id" display="description"></fm-autocomplete>
        </label>
        <label class="form-field">
        to user
        <fm-autocomplete id="user2_id" url="/lov/users" name="user2_id" select="id" display="name"></fm-autocomplete>
        </label>
        <label class="form-field">
        Adm.comment
        <input type="text"  name="adm_comment">
        </label>

        <label class="form-field">
        Startdate
        <input type="date"  class="date-input" name="startdate"   >
        </label>
        <label class="form-field">
        Enddate
        <input type="date"  class="date-input" name="enddate"   >
        </label>
        <fm-button type="submit" >Save</fm-button>
        </fm-form>
        
    </div>
    `;
	}

	async onSubmit() {
		const formValue = this.shadowRoot.querySelector("#form").value;
		formValue.user1_id = this.shadowRoot.querySelector("#user1_id").value;
		formValue.user2_id = this.shadowRoot.querySelector("#user2_id").value;
		formValue.type_id = this.shadowRoot.querySelector("#type_id").value;
		try {
			const response = await api.post(
				`/accounts/${this.account_id}/relations`,
				formValue,
			);
			toast("Saved");
		} catch (err) {
			Sentry.captureException(err);
			console.log(err);
		}
	}
}

customElements.define("fm-relation", RelationElement);
