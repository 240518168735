import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import router from "../../router/index.js";
import { money } from "../../formatting/numberformats.js";
import { toast } from "../../utils.js";
import "../../components/fm-progress-dots.js";
import { DateFormatter } from "../../formatting/dateformats.js";

export default class UserEditView extends LitElement {
	static get properties() {
		return {
			userId: {
				type: String,
			},
			row: {
				type: Object,
			},
			company: {
				type: Object,
			},
			loading: {
				type: Boolean,
			},
		};
	}

	constructor() {
		super();
		this.userId = "";
		this.row = {};
		this.files = [];
		this.custodies = [];
		this.usertypes = [];
		this.risk = [];
		this.userrisks = [];
		this.relations = [];
		this.reports = [];
		this.company = {};
		this.advisor = {};
		this.loading = false;
		this.accnumber = this.accnumber.bind(this);
		this.deleteFile = this.deleteFile.bind(this);
		this.updateFile = this.updateFile.bind(this);
		this.eventSave = this.eventSave.bind(this);
		this.riskSave = this.riskSave.bind(this);
		this.downloadFile = this.downloadFile.bind(this);
		this.approve = this.approve.bind(this);
		this.save = this.save.bind(this);
		this.savecredentials = this.savecredentials.bind(this);
		this.onAddressImport = this.onAddressImport.bind(this);
		this.changeSurveillance = this.changeSurveillance.bind(this);
		this.moveuser = this.moveuser.bind(this);
		this.pepinfo = this.pepinfo.bind(this);
		this.peps = [];
		this.events = [];
		this.contacts = [];
		this.cousers = [];
		this.questions = [];
		this.eventtypes = [];
		this.contact_id = null;
		this.realOwners = [];
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1500px;
      }

      .loading-container {
        width: 100%;
        padding: 16px 0;
        display: flex;
        justify-content: center;
      }

      div.approved_yn {
        display: flex;
        flex-direction: row;
        gap: 16px;
      }

      label.approved_yn div {
        display: flex;
        flex-direction: row;
      }

      label.approved_yn input {
        min-width: initial !important;
        width: 60px !important;
      }
      
      label.approved_yn button {
        margin: 0px 0px 8px 8px;
        max-width: 100px;
      }

      td.success,
      td.success {
        color: green;
        font-weight: bold;
      }

      tr.error td,
      td.error {
        color: red;
        font-weight: bold;
      }
      </style>
      ${this.renderLoading()}
      <div class="card">
        <div class="card-header">
          <h1>User information</h1>
          <h2> Advisor: ${this.advisor.advisor_name}</h2>
        </div>
        <div class="card-block">
          <fm-form id="move_form" style="width:400px">
            <label class="form-field">
              Move to another account
              <fm-autocomplete url="/lov/accounts" select="id" display="name" name="to_account_id" ></fm-autocomplete>
            </label>
            <fm-button id="move_button" class="button" @click="${this.moveuser}" >Move</fm-button>
          </fm-form>
        </div>
        <fm-form id="userform" class="form-grid" method="put" @submit="${
					this.save
				}">
          <label class="form-field">
            Account Name
            <input type="text" name="account_name" value="${
							this.row.account_name
						}">
          </label>
          <label class="form-field">
            Name
            <input type="text" name="name" value="${this.row.name || ""}">
          </label>
          <label class="form-field">
            Cpr
            <input type="text" name="cpr" value="${this.row.cpr || ""}">
          </label>
          <label class="form-field">
            CVR
            <input type="text" name="cvr" value="${this.row.cvr || ""}">
          </label>
          <label class="form-field">
            Person-ID CVR
            <input type="text" name="cvrid" value="${this.row.cvrid || ""}">
          </label>
          <label class="form-field">
            E-mail
            <input type="text" name="email" value="${this.row.email || ""}">
          </label>
          <label class="form-field">
            Phone
            <input type="text" name="phone" value="${this.row.phone || ""}">
          </label>
          <label class="form-field">
            PID
            <input type="text" name="uniquename" value="${
							this.row.uniquename || ""
						}">
          </label>
          <label class="form-field">
            Address1
            <input type="text" name="address1" value="${this.row.address1 || ""}">
          </label>
          <label class="form-field">
            Address2
            <input type="text" name="address2" value="${this.row.address2 || ""}">
          </label>
          <label class="form-field">
             Zip, city
              <input type="text" name="zipcity" value="${this.row.zipcity || ""}">
          </label>
          <label class="form-field">
              Country (address)
              <fm-autocomplete url="/lov/countries" select="id" display="name" name="country_id" 
              .rawValue="${{
								id: this.row.country_id || "",
								name: this.row.country_name || "",
							}}">
              </fm-autocomplete>
          </label>
          <label class="form-field">
            Tin (if address not in DK)
            <input type="text" name="tin" value="${this.row.tin || ""}">
          </label>
          <label class="form-field">
            Primary Citizen country
            <fm-autocomplete url="/lov/countries" select="id" display="name" name="citizen1_id" 
            .rawValue="${{
							id: this.row.citizen1_id || "",
							name: this.row.citizen1_country || "",
						}}">
            </fm-autocomplete>
          </label>
          <label class="form-field">
            Secondary Citizen country
            <fm-autocomplete url="/lov/countries" select="id" display="name" name="citizen2_id" 
            .rawValue="${{
							id: this.row.citizen2_id || "",
							name: this.row.citizen2_country || "",
						}}">
            </fm-autocomplete>
        </label>
        <label class="form-field">
          Birth country
          <fm-autocomplete url="/lov/countries" select="id" display="name" name="birthcountry_id" 
            .rawValue="${{
							id: this.row.birthcountry_id || "",
							name: this.row.birthcountry || "",
						}}">
            </fm-autocomplete>
        </label>
        <label class="form-field">
          Birth place
          <input type="text" name="birthplace" value="${
						this.row.birthplace || ""
					}">
        </label>
        <label class="form-field">
          User Type
          <select name="type_id">
          ${this.usertypes.map(
						(r) =>
							html`<option value="${r.id}" ?selected=${
								r.id === this.row.type_id
							}>${r.description}</option>`,
					)}
          </select>
        </label>
        <label class="form-field">
          Remark
          <input type="text" name="remark" value="${this.row.remark || ""}">
        </label>
        <label class="form-field">
          Bind
          <input type="text" name="bind" value="${this.row.bind || ""}">
        </label>
        <label class="form-field">
          Sector
          <fm-autocomplete url="/lov/sectors" select="id" display="name" name="sector_id" 
          .rawValue="${{
						id: this.row.sector_id || "",
						name: this.row.sector_name || "",
					}}">
          </fm-autocomplete>
        </label>
        <div class="form-field approved_yn">
          <label class="approved_yn">
            Info OK (Y/N)
            <div><input readonly type="text" name="infook_yn" value="${
							this.row.infook_yn || ""
						}" />
            ${
							this.row.infook_yn === "N"
								? html`<button class="btn btn--small" @click="${
										this.approveInfo
								  }" ?disabled="${this.row.infook_yn === "Y"}">Approve</button>`
								: html`<button class="btn btn--small" style="background-color: red;" @click="${
										this.unapproveInfo
								  }" ?disabled="${this.infook_yn === "N"}">Unapprove</button>`
						}</div>
          </label>
          <label class="approved_yn">
            Approved (Y/N)
            <div><input readonly type="text" name="approved_yn" value="${
							this.row.approved_yn || ""
						}" />
            ${
							this.row.approved_yn === "N"
								? html`<button class="btn btn--small" @click="${
										this.approve
								  }" ?disabled="${
										this.row.approved_yn === "Y"
								  }">Approve</button>`
								: html`<button class="btn btn--small" style="background-color: red;" @click="${
										this.unapprove
								  }" ?disabled="${
										this.row.approved_yn === "N"
								  }">Unapprove</button>`
						}
            </div>
          </label>
        </div>
        <div class="form-field"><fm-button id="user_submit" class="button" type="submit" >Save</fm-button></div>
        </fm-form>
      </div>
      <div class="card">
        <div class="card-header">
          <h1>User Credentials</h1>
          Last login: ${
						this.lastLogin?.reg_date
							? `${DateFormatter.format(
									new Date(this.lastLogin.reg_date),
									"dd-mm-yyyy HH:MM:ss",
							  )} (${this.lastLogin.ip_address})`
							: "Never"
					}
        </div>
        <fm-form id="usercredentials" class="form-grid"  method="put">
        <label class="form-field">
            User Name
            <input type="text" name="username" value="${this.row.username || ""}">
          </label>
        <label class="form-field">
            Password
            <input type="password" name="password">
          </label>
          <label class="form-field">
          Use SMS-code
          <input type="checkbox" name="twophase" ?checked="${
						this.row.twophase === "S"
					}">
        </label>
          <button class="button" @click="${this.savecredentials}" >Save</button>
        </fm-form>
      </div>


      ${this.renderEvents()}

      ${this.renderRealOwners()}

      ${this.renderContact()}

     <div class="card">
        <div class="card-header">
         <h2>Files</h2>
         <router-link class="btn btn--light" to="/accounts/users/${this.userId}/newfile">
            <a href="/users/${this.userId}/newfile">
              New
            </a>
         </router-link>
       </div>
      <table>
      <tbody>
        ${this.files.map(
					(f) => html`
        ${this.renderFile(f)}
        `,
				)}
      </tbody>
      </table>
      </div>
      <table>
        <thead>
        <tr>
          <th>Tax Report</th>
          <th>Custody-code</th>
          <th>Type</th>
          <th>ShoppingList</th>
          <th>Accountnumber</th>
          <th>IBAN</th><th>PublicID</th>
          <th>Apply BankAcc</th></tr>
      </thead>
      <tbody>
        ${this.custodies.map(
					(c) => html`
          <tr>
          <td><router-link to="/accounts/users/${c.id}/tax/${c.id}"><a>Tax Report</a></router-link></td>
            <td>${c.custody_code}</td>
            <td>${c.custody_type}</td>
            <td>
              <table>
                <thead>
                    <tr><th>Security</th>
                    <th>Share</th>
                    </tr>
                </thead>
                <tbody>
                  ${c.composition.map(
										(cp) => html`
                      <tr>
                      <td>${cp.name}</td><td>${cp.share}</td>
                      </tr>`,
									)}
                </tbody>
              </table>
            </td>
            <td>${c.accountnumber}</td>
            <td>${c.iban}</td>
            <td>${c.publicid}</td>
            <td><button class="button" @click="${this.accnumber}" ?disabled="${
						c.iban != null
					}" data-index="${c.custody_id}">Apply account-number</button></td>
          </tr>
        `,
				)}
      </tbody>
      </table>
      ${this.renderRelations()}
      ${this.renderReports()}
      ${this.renderQuestions()}
      ${this.renderCousers()}
      ${this.renderPeps()}
      ${this.renderRisk()}
    `;
	}

	connectedCallback() {
		super.connectedCallback();
		this.loading = true;
		Promise.all([
			api.get(`/users/${this.userId}/files`),
			api.get(`/users/${this.userId}/advisor`),
			api.get(`/users/${this.userId}/custodies`),
			api.get(`/peps/birthdate/${this.userId}`),
			api.get(`/users/${this.userId}/events`),
			api.get(`/users/${this.userId}/contacts`),
			api.get("/lov/contactid"),
			api.get(`/users/${this.userId}/cousers`),
			api.get(`/users/${this.userId}/risk`),
			api.get(`/users/${this.userId}/questions`),
			api.get("/lov/usertypes"),
			api.get("/lov/eventcodes"),
			api.get("/lov/userrisk"),
			api.get(`/users/${this.userId}/relations`),
			api.get(`/users/${this.userId}/companyreports`),
			api.get(`/users/${this.userId}`),
			api.get(`/users/${this.userId}/logins?limit=1`),
			api.get(`/users/${this.userId}/realowners`),
		]).then((values) => {
			this.files = values[0];
			this.advisor = values[1];
			this.custodies = values[2];
			this.peps = values[3];
			this.events = values[4];
			this.contacts = values[5];
			this.contact = values[6];
			this.contact_id = this.contact.id;
			this.cousers = values[7];
			this.risk = values[8];
			this.questions = values[9];
			this.usertypes = values[10];
			this.eventtypes = values[11];
			this.userrisks = values[12];
			this.relations = values[13];
			this.reports = values[14];
			this.row = values[15];
			this.lastLogin = values[16].length > 0 ? values[16][0] : null;
			this.realOwners = values[17];
			this.loading = false;
		});
	}

	renderLoading() {
		if (this.loading) {
			return html`
        <div class="loading-container">
          <fm-progress-dots></fm-progress-dots>
        </div>
      `;
		} else {
			return "";
		}
	}

	renderFile(f) {
		return html`
      <tr>
        <td><object width="240" height="240" type="${f.mimetype}" data="${
			api.baseURL
		}/files/u/${f.id}?t=${api.token}"></object></td>
        <td><a target="_blank"  href="${api.baseURL}/files/u/${f.id}?t=${
			api.token
		}">Show in new tab</a></td>
        <td>expires: <input type="date" value="${
					f.expiredate || ""
				}" id="expire_${f.id}"> <button class="button" @click="${
			this.updateFile
		}"  data-index="${f.id}">Save</button></td>
        <td><button class="button" @click="${this.deleteFile}"  data-index="${
			f.id
		}">Delete</button></td>
        <td><input type="checkbox"  @click="${
					this.changeSurveillance
				}" ?checked="${f.surveillance_yn === "Y"}" data-index="${f.id}">Surveillance</td>
      </tr>`;
		/*
  } else {
    return html `
      <tr>
        <td><button class="button" @click="${this.downloadFile}" data-index="${f.id}">Download PDF</button></td>
        <td><button class="button" @click="${this.deleteFile}"  data-index="${f.id}">Delete</button></td>
      </tr>`
  }
  */
	}

	renderQuestions() {
		return html`<div class="card">
    <div class="card-header">
     <h2>AML Questions</h2>
     <router-link class="btn btn--light" to="/accounts/newquestion"><a href="/accounts/newquestion">New</a></router-link>
    </div>
    <table>
    <thead>
    <tr>
      <th>Approved</th>
      <th>Code</th>
      <th>Asked</th>
      <th>Asked by</th>
      <th>Question</th>
      <th>Most recent answer</th>
      <th>Answered by</th>
      <th>Answer</th>
      <th>Comment</th>
      <th>Files</th>
      <th>Details</th>
    </tr>
    </thead>
    <tbody>
      ${this.questions.map(
				(q) =>
					html`<tr class="${(q.answers || []).length === 0 ? "error" : ""}">
             ${
								(q.answers || []).length > 0 && q.answers[0]?.approved
									? html`<td class="success">Yes</td>`
									: html`<td class="error">No</td>`
							}
             <td>${q.code}</td>
             <td>${q.ask_date}</td>
             <td>${q.asked_by}</td>
             <td>${q.question}</td>
             ${
								(q.answers || []).length > 0
									? html`<td>${q.answers[0].answer_date}</td>
             <td>${q.answers[0].answered_by}</td>
             <td>${q.answers[0].answer}</td>
             <td>${q.answers[0].answer_other}</td>
             <td>${
								q.answers[0].files
									? q.answers[0].files.map(
											(f, idx) =>
												html`<a href="#" data-id="${f.id}" data-name="${
													f.name
												}" @click="${this.downloadAnswerFile}">${f.name}${
													idx < q.answers[0].files.length - 1 ? ", " : ""
												}</a> `,
									  )
									: null
							}</td>`
									: html`<td colspan="5"></td>`
							}
            
             <td><a href="${`/aml/answers?ask_id=${q.id}`}" target="_blank" class="btn btn--xsmall">Details</a></td>
             </tr>`,
			)}
    </tbody>
    </table>
   </div>`;
	}

	renderContact() {
		return html`<div class="card">
    <div class="card-header">
     <h2>User Contact</h2>
       <fm-form id="contactform" class="form-grid">
       <input type="hidden" name="type_id" value="${this.contact_id}">
        </label>
        <label>Comment
           <textarea rows="5" cols="80" name="admin_comment"></textarea>
        </label>
        <fm-button style="max-width:80px;" type="submit" @click="${
					this.contactSave
				}">Save</fm-button>
       </fm-form>
    </div>
    <table>
    <thead>
    <tr>
    <th>Created</th>
    <th>By</th>
    <th>Comment</th>
    </tr>
    </thead>
    <tbody>
      ${this.contacts.map(
				(e) =>
					html`<tr>
             <td>${e.reg_date}</td>
             <td>${e.reg_user}</td>
             <td>${e.admin_comment}</td>
             </tr>`,
			)}
    </tbody>
    </table>
   </div>`;
	}

	renderEvents() {
		return html`<div class="card">
      <div class="card-header">
       <h2>User Events</h2>
         <fm-form id="eventform" class="form-grid">
          <label>Type
            <select name="type_id">
               ${this.eventtypes.map(
									(t) =>
										html`<option value="${t.id}">${t.description}</option>`,
								)}
            </select>
          </label>
          <label>Comment
             <input type="text" name="admin_comment">
          </label>
          <fm-button style="max-width:80px;" type="submit" @click="${
						this.eventSave
					}">Save</fm-button>
         </fm-form>
      </div>
      <table>
      <thead>
      <tr>
        <th>Code</th>
      <th>Type</th>
      <th>Created</th>
      <th>By</th>
      <th>Comment</th>
      </tr>
      </thead>
      <tbody>
        ${this.events.map(
					(e) =>
						html`<tr class="${
							e.code === "CVRDATA2" && e.admin_comment !== "CVR Import: done"
								? "error"
								: ""
						}">
               <td>${e.code}</td>
               <td>${e.description} </td>
               <td>${e.reg_date}</td>
               <td>${e.reg_user}</td>
               <td>${e.admin_comment}</td>
               </tr>`,
				)}
      </tbody>
      </table>
     </div>`;
	}

	renderRealOwners() {
		if (!this.row.cvr) {
			return null;
		}

		return html`
    <div class="card">
      <div class="card-header"><h2>Real owners</h2></div>
      <table>
      <thead>
      <tr>
        <th>Registered</th>
        <th>Name</th>
        <th>Start</th>
        <th>End</th>
        <th>Info OK</th>
        <th>Approved</th>
        <th>ID docs approved</th>
        <th>ID docs</th>
      </tr>
      </thead>
      <tbody>
        ${
					this.realOwners.length > 0
						? this.realOwners.map(
								(ro) =>
									html`
            <tr>
              <td>${DateFormatter.format(
								new Date(ro.realowner_reg_date),
								"dd-mm-yyyy HH:MM:ss",
							)}</td>
              <td><a href="/accounts/users/${
								ro.realowner_user_id
							}" target="_blank">${ro.realowner_name}</a></td>
              <td>${DateFormatter.format(
								new Date(ro.realowner_start_date),
								"dd-mm-yyyy",
							)}</td>
              <td>${
								ro.realowner_end_date // Should not happen as this should be filtered out in the backend
									? DateFormatter.format(
											new Date(ro.realowner_end_date),
											"dd-mm-yyyy",
									  )
									: ""
							}</td>
              ${
								ro.realowner_infook_yn === "Y"
									? html`<td class="success">Yes</td>`
									: html`<td class="error">No</td>`
							}</td>
              ${
								ro.realowner_approved_yn === "Y"
									? html`<td class="success">Yes</td>`
									: html`<td class="error">No</td>`
							}</td>
              ${
								ro.realowner_id_docs_approved
									? html`<td class="success">Yes</td>`
									: html`<td class="error">No</td>`
							}</td>
              <td><a href="/aml/answers?ask_id=${ro.realowner_question_ask_id}" target="_blank" class="btn btn--xsmall">Details</a></td>
            </tr>`,
						  )
						: html`<tr><td colspan="7" class="error">No real owners</td></tr>`
				}
      </tbody>
      </table>
    </div>`;
	}

	renderCousers() {
		return html`<div class="card">
      <div class="card-header">
       <h2>Co Users</h2>
       <router-link class="btn btn--light" to="/users/${this.userId}/cousers"></router-link>
      </div>
      <table>
      <thead>
      <tr>
      <th>Username</th>
      <th>Name</th>
      <th>Type</th>
      <th>Cpr</th>
      <th>Cvr</th>
      <th>Birthdate</th>
      <th>Files</th>
      <th>Approved</th>
      <th>Created</th>
      <th>Remark</th>
      </tr>
      </thead>
      <tbody>
        ${this.cousers.map(
					(r) =>
						html`<tr>
               <td>${r.username}</td>
               <td>${r.name}</td>
               <td>${r.type_description}</td>
               <td>${r.cpr}</td>
               <td>${r.cvr}</td>
               <td>${r.birthdate}</td>
               <td>${r.files}</td>
               <td>${r.approved_yn}</td>
               <td>${r.txt_reg_date}</td>
               <td>${r.remark}</td>
               </tr>`,
				)}
      </tbody>
      </table>
     </div>`;
	}

	renderRisk() {
		return html`<div class="card">
      <div class="card-header">
       <h2>Risk level</h2>
       <fm-form id="userrisk" class="form-grid">
       <label>Risk level
         <select name="risk_id">
            ${this.userrisks.map(
							(t) => html`<option value="${t.id}">${t.description}</option>`,
						)}
         </select>
       </label>
       <label>Comment
          <input type="text" name="adm_comment">
       </label>
       <fm-button style="max-width:80px;" type="submit" @click="${this.riskSave}">Save</fm-button>
      </fm-form>
      </div>
      <table>
      <thead>
      <tr>
      <th>Risk</th>
      <th>Comment</th>
      <th>Created</th>
      <th>Created by</th>
      </tr>
      </thead>
      <tbody>
        ${this.risk.map(
					(e) =>
						html`<tr>
               <td>${e.description}</td>
               <td> ${e.adm_comment}</td>
               <td>${e.reg_date}</td>
               <td>${e.reg_user}</td>
               </tr>`,
				)}
      </tbody>
      </table>
     </div>`;
	}

	renderRelations() {
		return html`<div class="card">
      <div class="card-header">
       <h2>Relations</h2>
      </div>
      <table>
      <thead>
      <tr>
      <th>User</th>
      <th >Is</th>
      <th>To</th>
      <th>Start</th>
      <th>End</th>
      <th>Created</th>
      </tr>
      </thead>
      <tbody>
        ${this.relations.map(
					(r) =>
						html`<tr>
               <td>${r.user1_name}</td>
               <td>${r.type_code}/${r.code}/${r.val}</td>
               <td>${r.user2_name}</td>
               <td>${r.startdate}</td>
               <td>${r.enddate}</td>
               <td>${r.txt_reg_date}</td>
               </tr>`,
				)}
      </tbody>
      </table>
     </div>`;
	}

	renderReports() {
		return html`<div class="card">
      <div class="card-header">
       <h2>Annual reports</h2>
      </div>
      <table>
      <thead>
      <tr>
      <th>From</th>
      <th>To</th>
      <th>Link</th>
      <th class="numeric">Current assets </th>
      </tr>
      </thead>
      <tbody>
        ${this.reports.map(
					(r) =>
						html`<tr>
               <td>${r.startdate}</td>
               <td>${r.enddate}</td>
               <td><a href="${r.url}" target="_blank">${r.url}</a></td>
               <td class="numeric">${money(r.currentassets)}</td>
               </tr>`,
				)}
      </tbody>
      </table>
     </div>`;
	}

	renderPeps() {
		return html`<div class="card">
    <div class="card-header">
     <h1>PEP's with same birthdate</h1>
    </div>
    <table>
      ${this.peps.map(
				(p) =>
					html`<tr>
             <td>${p.firstname} ${p.lastname} ${p.title}</td>
             </tr>`,
			)}
    </table>
   </div>`;
	}

	renderCompany() {
		if (this.company.vat) {
			return html`<div class="card">
      <div class="card-header">
        <h1>Company info from CVR</h1>
        </div>
        <table>
        <tr>
        <td>Name</td><td>${this.company.life.name}</td>
        <td></td>
     </tr>
       <tr>
         <td>Address</td>
         <td> ${this.company.address.street}, ${this.company.address.numberfrom}
             ${this.company.address.zipcode}  ${this.company.address.cityname}
          </td>
          <td><button class="button" @click="${this.onAddressImport}">Import</button></td>
       </tr>
       <tr>
          <td>E-mail</td><td>${this.company.contact.email}</td>
          <td></td>
       </tr>
       <tr>
          <td>WEB</td><td> ${this.company.contact.www}</td>
          <td></td>
       </tr>
       <tr>
          <td>Purpose</td><td>${this.company.info.purpose}</td>
          <td></td>
       </tr>
       <tr>
          <td>Bind</td><td>${this.company.info.bind}</td>
          <td></td>
       </tr>
       <tr>
          <td>Status</td><td>${this.company.companystatus.text}</td>
          <td></td>
       </tr>
       <tr>
       <td>Bankrupt</td><td>${this.company.status.bankrupt}</td>
       <td></td>
      </tr>
       </table>
       <table><caption>Documents</caption>
       <tr>
       <th>Type</th>
       <th>Asofdate</th>
       <th>Publicdate</th>
       <th>grossprofitloss</th>
       <th>Assets</th>
       <th>Current Assets</th>
       </tr>
       ${this.company.accounting.documents.map(
					(d) => html`
       <tr>
       <td><a href="${d.url}" target="_blank">${d.type}</a></td>
       <td>${d.end}</td>
       <td>${d.publicdate}</td>
       <td>${d.summary.grossprofitloss}</td>
       <td>${d.summary.assets}</td>
       <td>${d.summary.currentassets}</td>
       </tr>
       `,
				)}
       </table>
        
        <table><caption>Participants</caption>
        ${this.company.participants.map(
					(p) =>
						html`<tr>
          <td></td>
          <td>${p.life.name}</td>
          <td>${(p.roles || []).map((r) => html`${r.type},`)}</td>
          </tr>`,
				)}
        </table>
        <table><caption>Participations</caption>
        ${this.company.participations.map(
					(p) =>
						html`<tr>
          <td>${p.life.name}</td>
          <td>${(p.roles || []).map((r) => html`${r.type},`)}</td>
          </tr>`,
				)}
        </table>

       </div>`;
		} else {
			return "";
		}
	}

	async onAddressImport() {
		this.row.address1 = `${this.company.address.street} ${this.company.address.numberfrom}`;
		this.row.zipcity = `${this.company.address.zipcode} ${this.company.address.cityname}`;
		toast("Imported, NOT Saved");
		this.requestUpdate();
	}

	async accnumber(event) {
		const target = event.target;
		const custody_id = target.getAttribute("data-index");
		await api.get(`/custodies/${custody_id}/bankaccount`);
		this.custodies = await api.get(`/users/${this.userId}/custodies`);
		await this.requestUpdate();
	}

	async downloadFile(event) {
		event.preventDefault();
		const target = event.target;
		const file_id = target.getAttribute("data-index");
		const response = await api.fetch(`/users/files/${file_id}`);
		const data64 = await response.text();
		const a = document.createElement("a");
		if (data64.substr(0, 5) === "data:") {
			a.href = data64;
		} else {
			a.href = `data:image/jpeg;base64,${data64}`;
		}
		a.download = "Userfile.jpg";
		a.click();
	}

	async updateFile(event) {
		const target = event.target;
		const file_id = target.getAttribute("data-index");
		const expire_input = this.shadowRoot.querySelector(`#expire_${file_id}`);
		if (expire_input) {
			try {
				await api.put(`/users/files/${file_id}`, {
					expiredate: expire_input.value,
				});
			} catch (err) {
				Sentry.captureException(err);
				toast("Error saving expiredate");
			}
			toast("Expiredate saved");
			this.files = await api.get(`/users/${this.userId}/files`);
			this.requestUpdate();
		} else {
			toast("Internal Error: expiredate-input not found");
		}
	}

	async deleteFile(event) {
		const target = event.target;
		const file_id = target.getAttribute("data-index");

		try {
			await api.delete(`/users/files/${file_id}`);
			this.files = await api.get(`/users/${this.userId}/files`);
			this.requestUpdate();
			toast("File deleted");
		} catch (err) {
			Sentry.captureException(err);
			toast("Error");
		}
	}

	async downloadAnswerFile(event) {
		event.preventDefault();
		const target = event.target;
		const answerFileId = target.getAttribute("data-id");
		const answerFileName = target.getAttribute("data-name");

		if (answerFileId === "") {
			return;
		}

		try {
			const answerFile = await api.get(`/answers/file/${answerFileId}`);
			const base64Content = answerFile.txtcontent;

			const a = document.createElement("a");
			a.setAttribute("href", base64Content);
			a.setAttribute("download", answerFileName);
			a.click();
		} catch (err) {
			Sentry.captureException(err);
			toast("Der skete en fejl ifm. handling");
			console.log(err);
		}
	}

	async save() {
		const userform = this.shadowRoot.querySelector("#userform").value;
		const b = this.shadowRoot.querySelector("#user_submit");
		b.loading = true;
		try {
			this.row = await api.put(`/users/${this.userId}`, userform);

			this.requestUpdate();
			toast("Saved");
			router.push(`/accounts/${this.row.account_id}`);
		} catch (err) {
			Sentry.captureException(err);
			toast("Error");
		}
	}
	async savecredentials() {
		const userform = this.shadowRoot.querySelector("#usercredentials").value;
		const twophase = userform.twophase ? "S" : null;
		if (twophase) {
			userform.twophase = "S";
		}
		try {
			this.row = await api.put(`/users/${this.userId}/credentials`, userform);
			toast("Saved");
			router.push(`/accounts/${this.row.account_id}`);
		} catch (err) {
			Sentry.captureException(err);
			toast("Error");
		}
	}

	async eventSave() {
		try {
			const formdata = this.shadowRoot.querySelector("#eventform").value;
			await api.post(`/users/${this.userId}/events`, formdata);
			this.events = await api.get(`/users/${this.userId}/events`);
			toast("Event added");
			this.requestUpdate();
		} catch (err) {
			Sentry.captureException(err);
			const error = await err.json();
			console.log(error);
			toast(error.message || " Error occured");
		}
	}

	async riskSave() {
		try {
			const formdata = this.shadowRoot.querySelector("#userrisk").value;
			await api.post(`/users/${this.userId}/risk`, formdata);
			this.risk = await api.get(`/users/${this.userId}/risk`);
			toast("New risk level added");
			this.requestUpdate();
		} catch (err) {
			Sentry.captureException(err);
			const error = await err.json();
			console.log(error);
			toast(error.message || " Error occured");
		}
	}

	async contactSave() {
		try {
			const formdata = this.shadowRoot.querySelector("#contactform").value;
			await api.post(`/users/${this.userId}/events`, formdata);
			this.contacts = await api.get(`/users/${this.userId}/contacts`);
			toast("Event added");
			this.requestUpdate();
		} catch (err) {
			Sentry.captureException(err);
			const error = await err.json();
			console.log(error);
			toast(error.message || " Error occured");
		}
	}

	async approve() {
		try {
			const res = await api.post(`/users/${this.userId}/approve`);
			this.row = await api.get(`/users/${this.userId}`);
			toast("User approved");
		} catch (err) {
			Sentry.captureException(err);
			const error = await err.json();
			console.log(error);
			toast(`ERROR ${error.type}: ${error.message}`);
		}
	}

	async unapprove() {
		try {
			const res = await api.post(`/users/${this.userId}/unapprove`);
			this.row = await api.get(`/users/${this.userId}`);
			toast("User unapproved");
		} catch (err) {
			Sentry.captureException(err);
			const error = await err.json();
			console.log(error);
			toast(`ERROR ${error.type}: ${error.message}`);
		}
	}

	async approveInfo() {
		try {
			const res = await api.post(`/users/${this.userId}/approve-info`);
			this.row = await api.get(`/users/${this.userId}`);
			toast("User info approved");
		} catch (err) {
			Sentry.captureException(err);
			const error = await err.json();
			console.log(error);
			toast(`ERROR ${error.type}: ${error.message}`);
		}
	}

	async unapproveInfo() {
		try {
			const res = await api.post(`/users/${this.userId}/unapprove-info`);
			this.row = await api.get(`/users/${this.userId}`);
			toast("User info unapproved");
		} catch (err) {
			Sentry.captureException(err);
			const error = await err.json();
			console.log(error);
			toast(`ERROR ${error.type}: ${error.message}`);
		}
	}

	async pepinfo() {
		const token = await api.get("/users/peptoken");
		const res = await api.get(
			`/users/${this.userId}/pepdata?t=${token.auth_token}`,
		);
	}

	async moveuser() {
		const b = this.shadowRoot.querySelector("#move_button");
		b.loading = true;
		const f = this.shadowRoot.querySelector("#move_form").value;
		if (!f.to_account_id) {
			toast("Please select account-id to move user to");
		} else {
			try {
				const res = await api.put(
					`/users/${this.userId}/move/${f.to_account_id}`,
				);
				toast(`User moved to account-id ${f.to_account_id} `);
				this.row = await api.get(`/users/${this.userId}`);
			} catch (err) {
				Sentry.captureException(err);
				const response = await err.json();
				toast(`Error moving user ${response.message || ""} `);
			}
		}

		b.loading = false;
	}

	async changeSurveillance(event) {
		const target = event.target;
		const file_id = target.getAttribute("data-index");
		const checked = target.checked;
		const s = checked ? "Y" : "N";
		try {
			const response = await api.put(`/users/files/${file_id}/surveillance`, {
				surveillance_yn: s,
			});
			if (response.status === "OK") {
				toast("Surveillance changed");
			} else {
				toast(response.message);
			}
		} catch (err) {
			Sentry.captureException(err);
			toast(JSON.stringify(err));
		}
	}
}
customElements.define("user-edit-view", UserEditView);
