import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { money } from "../../formatting/numberformats.js";
import { toast } from "../../utils.js";

export default class AMLDeactivateView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			question_id: {
				type: Number,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.dateStr = "";
		this.onWarn = this.onWarn.bind(this);
		this.onDeactivate = this.onDeactivate.bind(this);
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1600px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h2>Deactivate accounts ${this.dateStr}</h2>
        </div>
        <fm-form id = "parms" class="form-grid" @submit="${this.refresh}">

         <div class="form-field">
           <label>Created
             <fm-date-range id="created" name="created"  names="deac-" ></fm-date-range>
            </label>
         </div>
         <div class="form-field">
           <label><input type="checkbox" name="iswarned">Warned
             <fm-date-range id="warned" name="warned"  names="deaw-" ></fm-date-range>
            </label>
         </div>
         <div class="form-field">
           <label>Age (days) unanswered questions
             <input type="number"  name="qage" value="${this.qage || ""}" >
           </label>
         </div>
         <div class="form-field">
           <label>Search 
             <input type="search"  name="query" value="${this.query || ""}" class="search-input" style="width:100%">
           </label>
         </div>
         <div class="form-field">
           <label>Account-IDs 
             <input type="search"  name="ids"  class="search-input" style="width:100%">
           </label>
         </div>

         <fm-button type="submit" id="submit_button" class="btn" style="max-width:80px">Refresh</fm-button>
       </fm-form>
        <table>
          <thead>
            <tr>
             <th>Action</th>
             <th>Warned</th>
             <th>Created</th>
             <th>Name</th>
             <th>Advisor</th>
             <th>#Orders</th>
             <th>Current value</th>
             <th>Oldest unanswered</th>
             <th>Emails</th>
            </tr>
          </thead>
          <tbody>
            ${this.rows.map(
							(row) => html`
              <tr>
                <td>
                  <fm-button type="button" data-id="${row.id}" @click="${
								this.onWarn
							}">Warn</fm-button>
                  <fm-button type="button" data-id="${row.id}" @click="${
								this.onDeactivate
							}">Deactivate</fm-button>
                </td>
                <td>${row.warn_date}</td>
                <td>${row.reg_date}</td>
                <td>${row.name}</td>
                <td>${row.advisor_name}</td>
                <td>${row.order_count}</td>
                <td>${money(row.marketvalue_pc)}</td>
                <td>${row.oldest_unanswered_date}</td>
                <td>${row.emails}</td>
              </tr>
            `,
						)}
          </tbody>
        </table>
      </div>
    `;
	}

	connectedCallback() {
		super.connectedCallback();
		const dt = new Date();
		this.dateStr = dt.toLocaleString();
		this.rows = [];
	}

	async onWarn(event) {
		const target = event.target;
		const id = target.getAttribute("data-id");
		try {
			const response = await api.put(`/accounts/${id}/warn`);
			toast("Users warned by Email");
		} catch (err) {
			Sentry.captureException(err);
			toast("Failed");
		}
	}

	async onDeactivate(event) {
		const target = event.target;
		const id = target.getAttribute("data-id");
		try {
			const response = await api.put(`/accounts/${id}/deactivate`);
			toast("Account deactivated");
		} catch (err) {
			Sentry.captureException(err);
			if (err instanceof Response) {
				const body = await err.json();
				toast(`${body.status}: ${body.message}`);
			}
		}
	}

	async refresh() {
		const parms = this.shadowRoot.querySelector("#parms").value;
		const iswarned = typeof parms.iswarned === "object";
		const cdates = this.shadowRoot.querySelector("#created").value;
		let wdates = { from: null, to: null };
		if (iswarned) {
			wdates = this.shadowRoot.querySelector("#warned").value;
		}
		const submit_button = this.shadowRoot.querySelector("#submit_button");
		submit_button.loading = true;
		localStorage.setItem("deac-fromdate", cdates.from);
		localStorage.setItem("deac-todate", cdates.to);
		localStorage.setItem("deaw-fromdate", wdates.from);
		localStorage.setItem("deaw-todate", wdates.to);
		localStorage.setItem("deac-query", parms.query);
		localStorage.setItem("deac-qage", parms.qage);

		this.query = parms.query;

		const q1 = `?cfrom=${cdates.from}&cto=${cdates.to}&wfrom=${
			wdates.from
		}&wto=${wdates.to}&query=${this.query}&qage=${parms.qage || ""}`;
		const q2 = `&ids=${parms.ids}`;

		try {
			this.rows = await api.get(`/accounts/deactivate${q1}${q2}`);
		} catch (err) {
			Sentry.captureException(err);
			toast("Det lykkedes ikke at hente data");
		}

		submit_button.loading = false;
	}
}

customElements.define("aml-deactivate-view", AMLDeactivateView);
