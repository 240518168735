import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { sort } from "../../utils.js";
import { toast } from "../../utils.js";
import { date } from "../../formatting/dateformats.js";

export default class GeneralMeetingView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
		};
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1400px;
      }
      </style>
       <div class="card">
        <div class="card-header">
          <h1>Positions</h1>
        </div>
        <div class="card-block">
          <input type="date"  class="date-input" id="asofdate" value="${
						this.asofdate
					}" @change="${this.onInput}">
          <input type="search" class="search-input" id="query" placeholder="Investor name" @change="${
						this.onInput
					}">
          <fm-button class="btn" @click="${this.fetch}">Refresh</fm-button>
        </div>
        <table>
          <thead>
            <tr>
              <th data-key="owner_name"  @click="${this.sort}">Investor Name</th>
              <th data-key="custody_code"  @click="${this.sort}">Custody Number</th>
              <th data-key="isin"  @click="${this.sort}">ISIN</th>
              <th data-key="subfund"  @click="${this.sort}">Fund</th>
              <th class="numeric" data-key="quantity" @click="${this.sort}" class="numeric">Units</th>
            </tr>
          </thead>
          <tbody>
            ${this.rows.map(
							(row) => html`
			<tr>
				<td>${row.owner_name}</td>
				<td>${row.custody_code}</td>
				<td>${row.isin}</td>
				<td>${row.subfund}</td>
				<td class="numeric">${row.quantity}</td>
			</tr>
            `,
						)}
          </tbody>
          <tfoot>
          <tr>
          <td colspan="3" class="numeric">Total</td>
          <td class="numeric">${this.total}</td>
          </tr>
          </tfoot>
        </table>
      </div>
   `;
	}

	constructor() {
		super();
		this.rows = [];
		this.query = "";
		this.asofdate = date(Date.now());
		this.onInput = this.onInput.bind(this);
		this.fetch = this.fetch.bind(this);
		this.sort = this.sort.bind(this);
		this.total = 0;
	}

	onInput() {
		this.query = this.shadowRoot.querySelector("#query").value;
		this.asofdate = this.shadowRoot.querySelector("#asofdate").value;
	}

	async fetch(event) {
		const target = event.target;
		target.loading = true;
		try {
			this.rows = await api.get(
				`/funds/quantities?name=${this.query}&asofdate=${this.asofdate}`,
			);
			this.total = this.rows.reduce((t, e) => t + e.quantity, 0);
		} catch (err) {
			Sentry.captureException(err);
			toast("Could not produce list");
		}
		target.loading = false;
	}

	sort(event) {
		const key = event.target.getAttribute("data-key");
		this.rows = sort(this.rows, key);
		this.requestUpdate();
	}
}

customElements.define("general-meeting-view", GeneralMeetingView);
