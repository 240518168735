import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { money, quantity } from "../../formatting/numberformats.js";
import { toCsvF } from "../../utils.js";
import { toast } from "../../utils.js";

export default class AdvisorNewOrdersView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			bookmessage: {
				type: String,
			},
			errormessage: {
				type: String,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.refresh = this.refresh.bind(this);
		this.book = this.book.bind(this);
		this.bookmessage = "";
		this.errormessage = "";
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1600px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>Book New Orders</h1>
        </div>
        <fm-form id = "parms" class="form-grid" @submit="${this.refresh}" style="max-width:800px">
          <div class="form-field">
            <fm-button type="submit" id="submit_button" class="btn" style="max-width:80px">Refresh</fm-button>
            <fm-button type="button" id="download_button" class="btn" @click="${
							this.download
						}" style="max-width:80px">Download</fm-button>
          </div>
          <div class="form-field">
            <span>${this.bookmessage}</span>
            <fm-button type="button" id="book_button" class="btn" @click="${
							this.book
						}" style="max-width:80px">Book</fm-button>
          </div>
        </fm-form>
        <table>
          <thead>
            <tr>
              <th data-key="advisor" @click="${this.sort}">Advisor</th>
              <th data-key="owner" @click="${this.sort}">Owner</th>
              <th data-key="custody_code" @click="${this.sort}">Custody</th>
              <th class="numeric" data-key="val" @click="${this.sort}">Current Value</th>
              <th data-key="method" @click="${this.sort}">Fee Method</th>
              <th class="numeric" data-key="unit" @click="${this.sort}">Unit</th>
              <th class="numeric" data-key="amount" @click="${this.sort}">Amount</th>
            </tr>
          </thead>
          <tbody>
            ${this.rows.map(
							(row) => html`
              <tr>
                <td>${row.advisor}</td>
                <td>${row.owner}</td>
                <td>${row.custody_code}</td>
                <td class="numeric">${money(row.val)}</td>
                <td>${row.method}</td>
                <td class="numeric">${quantity(row.unit)}</td>
                <td class="numeric">${money(row.amount)}</td>
              </tr>
                `,
						)}
          </tbody>
        </table>
      </div>
    `;
	}

	async fetch() {
		try {
			this.rows = await api.get("/advisors/neworders");
		} catch (err) {
			Sentry.captureException(err);
			const json = await err.json();
			console.log(json);
			const txt_err = `Fejl: ${json.message || "(Ingen fejlmeddelelse)"}`;
			toast(txt_err);
		}
	}

	async refresh() {
		const button = this.shadowRoot.querySelector("#submit_button");
		button.loading = true;
		await this.fetch();
		button.loading = false;
	}

	async book() {
		const button = this.shadowRoot.querySelector("#book_button");
		button.loading = true;
		let failures = 0;
		const l = this.rows.length;
		const book = { amount: 0, val: 0 };
		for (let i = 0; i < l; i++) {
			this.bookmessage = `${i + 1}/${l} ${this.rows[i].owner}`;
			try {
				book.amount = this.rows[i].amount;
				book.val = this.rows[i].val;
				await api.post(`/advisors/redemptions/${this.rows[i].id}/book`, book);
			} catch (err) {
				Sentry.captureException(err);
				this.errormessage = `${i + 1}/${l} ${this.rows[i].owner} FAILED`;
				failures += 1;
			}
		}
		this.bookmessage = `${l} Orders booked. ${failures} failures.`;
		button.loading = false;
	}

	async download(event) {
		const download_button = this.shadowRoot.querySelector("#download_button");
		try {
			download_button.loading = true;
			await this.fetch();
			const csvHeaders = [
				"advisor",
				"owner",
				"custody_code",
				"val",
				"method",
				"unit",
				"amount",
			];

			const csvFields = csvHeaders;
			const csvFormats = ["t", "t", "t", "m", "t", "q", "m"];

			let txtdata = toCsvF(csvHeaders, csvFields, csvFormats, this.rows);

			try {
				txtdata = btoa(txtdata);
			} catch (err) {
				Sentry.captureException(err);
				txtdata = btoa(unescape(encodeURIComponent(txtdata)));
			}
			const a = document.createElement("a");
			a.setAttribute("href", `data:text/csv;base64,${txtdata}`);
			a.setAttribute("download", "fm_feeorders.csv");
			a.click();
			download_button.loading = false;
		} catch (err) {
			Sentry.captureException(err);
			console.log(err);
			toast(err);
			download_button.loading = false;
		}
	}
}

customElements.define("advisor-neworders-view", AdvisorNewOrdersView);
