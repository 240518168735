import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import inputStyles from "../../styles/input.js";
import sharedStyles from "../../styles/shared.js";
import { toast } from "../../utils.js";
import { money, quantity } from "../../formatting/numberformats.js";
import { DateFormatter } from "../../formatting/dateformats.js";

export default class AdvisorFeeView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			redemption_id: {
				type: String,
			},
			actiontypes: {
				type: Array,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.methods = [];
		this.actiontypes = [];
		this.rerender = 0;
		this.refresh = this.refresh.bind(this);
		this.saveAction = this.saveAction.bind(this);
		this.importRedemptions = this.importRedemptions.bind(this);
		this.downloadFile = this.downloadFile.bind(this);
		this.editRedemption = this.editRedemption.bind(this);
	}

	async connectedCallback() {
		super.connectedCallback();
		let todate = localStorage.getItem("agreement-todate");
		const fromdate = localStorage.getItem("agreement-fromdate");

		if (!todate) {
			todate = DateFormatter.format(new Date(), "yyyy-mm-dd");
			localStorage.setItem("agreement-todate", todate);
		}
		if (!fromdate) {
			localStorage.setItem("agreement-fromdate", todate);
		}

		this.qactiontypes = localStorage.getItem("fee-actiontypes") || "";
		this.actiontypes = await api.get(
			`/lov/ractiontypes?query=${this.qactiontypes}`,
		);
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1400px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>Advisor fee</h1>
        </div>
        <fm-form id = "parms" class="form-grid" @submit="${this.refresh}">
        <div class="form-field">
        <label>Created
          <fm-date-range id="created" name="created"  names="agreement-" ></fm-date-range>
         </label>
       </div>
       <div class="form-field">
      </div>
       <div class="form-field">
         <label>Advisor search
          <input type="search"  name="advisor"  class="search-input" style="width:100%">
         </label>
        </div>
        <div class="form-field">
          <label>Client search
            <input type="search"  name="client"  class="search-input" style="width:100%">
          </label>
        </div>
        <div class="form-field">
          <label>Order Status
            ${this.actiontypes.map(
							(t) =>
								html`<input data-id="${t.id}" id="chk${
									t.id
								}" type="checkbox" @change="${this.chkbox}" ?checked=${
									t.selected === "Y"
								}>${t.name}`,
						)}
          </label>
        </div>
        <div class="form-field" style="display:flex">
          <fm-button type="submit" id="submit_button" style="max-width:80px;margin:4px;">Refresh</fm-button>
          <fm-button type="button" id="import_button" style="max-width:80px;margin:4px;" @click="${
						this.importRedemptions
					}">Import</fm-button>
      </div>
        </fm-form>
        </div>
        <div class="card">
        <table>
        <thead>
        <th>Advisor</th>
        <th>Client</th>
        <th>Created</th>
        <th>Filename</th>
        </thead>
        <tbody>
          ${this.rows.map(
						(r) => html`
          <tr>
            <td>${r.advisor_name}</td>
            <td>${r.client_name}</td>
            <td>${r.txt_reg_date}</td>
            <td>
               <table>${(r.documents || []).map(
									(d) => html`
                  <tr>
                    <td>${d.txt_reg_date}</td>
                    <td><a href="${api.baseURL}/files/${d.document_id}?ft=AADOC&t=${api.token}" target="_blank">${d.filename}</a></td>
                  </tr>`,
								)}
               </table>
            </td>
           </tr>
           <tr>
           <td colspan="4">
           ${this.renderCustodies(r.custodies || [])}
           </td>
           </tr>
          `,
					)}
        </tbody>
        </table>
      </div>
    `;
	}

	renderCustodies(cs) {
		return html`
    <div class="card">
      <table>
    <thead>
    <tr>
    <th>Edit</th>
    <th>Custody</th>
    <th>Type</th>
    <th>Name</th>
    <th class="numeric">Value</th>
    <th>First date</th>
    <th>Last date</th>
    <th>Method</th>
    <th class="numeric">Amount</th>
    <th class="numeric">Value from</th>
    <th class="numeric">Value to</th>
    </tr>
    </thead>
    <tbody>
    ${cs.map(
			(c) => html`
      <tr>
      <td><fm-button type="button" data-id="${c.redemption_id}" @click="${
				this.editRedemption
			}">Edit</fm-button></td>
        <td>${c.custody_code}</td>
        <td>${c.custodytype_code}</td>
        <td>${c.name}</td>
        <td class="numeric">${money(c.current_value)}</td>
        <td>${c.firstdate}</td>
        <td>${c.lastdate}</td>
        <td>${c.method_code}</td>
        <td class="numeric">${quantity(c.amount)}</td>
        <td class="numeric">${c.valuefrom}</td>
        <td class="numeric">${c.valueto}</td>
      </tr>
      ${this.renderActions(c)}
      ${this.renderRedemption(c)}
    `,
		)}
    </tbody>
    </table>
    </div>
    `;
	}

	renderActions(c) {
		return html`
  <tr>
    <td colspan="11">
      <fm-form class="form-grid" @submit="${this.saveAction}">
        <input type="hidden" name="redemption_id" value="${c.redemption_id}">
        <label>Type
          <select name="type_id">
          <option></option>
            ${this.actiontypes.map(
							(t) => html`<option value="${t.id}">${t.name}</option>`,
						)}
          </select>
        </label>
        <label>Comment
          <input type="text" name="admin_comment">
        </label>
        <label>
        <fm-button style="max-width:80px;" type="submit" >Save</fm-button>
        </label>
    </fm-form>
      <table>
       ${(c.actions || []).map(
					(a) => html`
            <tr>
              <td>${a.code}/${a.ts}/${a.admin_comment}/${a.name}</td>
            </tr>
            `,
				)}
      </table>
   </td>
  </tr>
  `;
	}

	renderRedemption(c) {
		if (c.redemption_id === this.redemption_id) {
			return html`
     <tr>
      <td colspan="11" style="background:grey">
        <redemption-edit-view redemption_id="${c.redemption_id}"></redemption-edit-view>
     </td>
     </tr>`;
		} else {
			return "";
		}
	}

	chkbox(event) {
		const target = event.target;
		const id = +target.getAttribute("data-id");
		for (let i = 0; i < this.actiontypes.length; i++) {
			if (this.actiontypes[i].id === id) {
				if (target.checked) {
					this.actiontypes[i].selected = "Y";
				} else {
					this.actiontypes[i].selected = "N";
				}
			}
		}
	}

	async refresh() {
		const button = this.shadowRoot.querySelector("#submit_button");
		button.loading = true;
		const parms = this.shadowRoot.querySelector("#parms").value;

		const dates = this.shadowRoot.querySelector("#created").value;
		localStorage.setItem("agreement-fromdate", dates.from);
		localStorage.setItem("agreement-todate", dates.to);

		const reducer = (a, t) => (t.selected === "Y" ? `${a}${t.id},` : a);
		const actiontypes = this.actiontypes.reduce(reducer, ",");

		localStorage.setItem("fee-actiontypes", actiontypes);

		this.redemption_id = null;
		const q = `/advisors/agreements?advisor=${parms.advisor}&client=${parms.client}&regfrom=${dates.from}&regto=${dates.to}&actiontypes=${actiontypes}`;
		this.rows = await api.get(q);
		button.loading = false;
	}

	async downloadFile(event) {
		event.preventDefault();
		const target = event.target;
		const document_id = target.getAttribute("data-index");
		const response = await api.get(`/advisors/documents/${document_id}`);
		const a = document.createElement("a");
		a.href = response.content;
		a.download = "Aftale.pdf";
		a.click();
	}

	editRedemption(event) {
		const target = event.target;
		const redemption_id = target.getAttribute("data-id");
		this.redemption_id = Number(redemption_id);
	}

	async saveAction(event) {
		try {
			const formdata = event.target.value;
			if (formdata.type_id) {
				await api.post(
					`/advisors/redemptions/${formdata.redemption_id}/actions`,
					formdata,
				);
				toast("Action added");
				await this.refresh();
			}
		} catch (err) {
			Sentry.captureException(err);
			const error = await err.json();
			console.log(error);
			toast(error.message || " Error occured");
		}
	}

	async importRedemptions() {
		try {
			const response = await api.get("/advisors/redemptions/import", {});
			toast(`${response.status} ${response.message} `);
			await this.refresh();
		} catch (err) {
			Sentry.captureException(err);
			const error = await err.json();
			console.log(error);
			toast(error.message || " Error occured");
		}
	}
}

customElements.define("advisor-fee-view", AdvisorFeeView);
