import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { DateFormatter } from "../../formatting/dateformats.js";
import { toast } from "../../utils.js";

export default class OrdersMoveView extends LitElement {
	static get properties() {
		return {
			ordertypes: {
				type: Array,
			},
			order_id: {
				type: Number,
			},
			positions: {
				type: Array,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.query = "";
		this.save = this.save.bind(this);
		this.getCustody = this.getCustody.bind(this);
		this.moveQuantity = this.moveQuantity.bind(this);
		this.qtyChange = this.qtyChange.bind(this);
		this.tradedate = DateFormatter.format(new Date(), "yyyy-mm-dd");
		this.positions = [];
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 800px;
      }

      </style>
     
      <div class="card" >
      <div class="card-header">
       <h2>Move positions</h2>
       Create Fund order to move positions from one custody to another.
       Most frequently used, when a child turns 18 years of age, and gets his own account.
       </div>
       <fm-form id = "parms" class="form-grid" @submit="${this.save}">
        <div class="form-field">
           <label>From custody
              <fm-autocomplete url="/lov/custodies"  id="fromcustody" @select="${
								this.getCustody
							}" name="from" select="id" display="name" ></fm-autocomplete>
           </label>
         </div>
         <div class="form-field">
            <label>To custody
            <fm-autocomplete url="/lov/custodies"  name="to" select="id" display="name" ></fm-autocomplete>
          </label>
         </div>
         <div class="form-field"> 
           <label>Amount to move (blank: total balance)
            <input type="number" name="amount">
			</label>
          </div>
          <div class="form-field"> 
         </label>
           <fm-button type="submit" id="submit_button" class="btn" style="max-width:80px">Move Amount</fm-button>
         </div>
      </fm-form>
      </div>
      ${this.renderPositions()}
      `;
	}

	renderPosition(r, i) {
		return html`
  <tr>
  <td>${r.name}</td>
  <td class="numeric">${r.quantity}</td>
  <td class="numeric"><input data-ix="${i}" type="number" @change="${this.qtyChange}" value="${r.sell}"></td>
  <td><fm-button type="button" style="max-width:80px" class="btn" data-ix="${i}" @click="${this.moveQuantity}">Move Units</fm-button></td>
  </tr>
  `;
	}

	renderPositions() {
		return html`
  <div class="card-block">
  <table>
    <tr>
      <th>Security</th>
      <th class="numeric">Quantity</th>
      <th class="numeric">Move Units</th>
      <th></th>
    </tr>
    ${(this.positions || []).map((r, i) => this.renderPosition(r, i))}
  </table>
  </div>
  `;
	}

	async save() {
		const parms = this.shadowRoot.querySelector("#parms").value;
		try {
			const data = await api.post("/orders/move", parms);
			toast(data.message);
		} catch (err) {
			Sentry.captureException(err);
			console.log(err);
		}
	}

	async getCustody() {
		const custody_id = this.shadowRoot.querySelector("#fromcustody").value;
		const data = await api.get(`/custodies/${custody_id}/positions`);
		this.positions = data;
	}

	async moveQuantity(event) {
		const target = event.target;
		const ix = target.getAttribute("data-ix");
		const parms = this.shadowRoot.querySelector("#parms").value;
		parms.security_id = this.positions[ix].security_id;
		parms.quantity = this.positions[ix].sell;
		if (!parms.from) {
			toast("From Custody missing");
			return;
		}
		if (!parms.to) {
			toast("To Custody missing");
			return;
		}
		if (!parms.security_id) {
			toast("Security_id missing");
			return;
		}
		if (!parms.quantity) {
			toast("Quantity missing");
			return;
		}

		try {
			const data = await api.post("/orders/moveunits", parms);
			toast(data.message);
		} catch (err) {
			Sentry.captureException(err);
			console.log(err);
		}
	}

	async qtyChange(event) {
		const target = event.target;
		const ix = target.getAttribute("data-ix");
		if (ix) {
			this.positions[ix].sell = target.value;
		}
	}
}

customElements.define("orders-move-view", OrdersMoveView);
