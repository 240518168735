import { html } from "lit";

export default html`
<style>
* {
  box-sizing: border-box;
}

:host {
  display: block;
}

aside {
  position: fixed;
  height: 100vh;
  width: 256px;
  border-right: 1px solid #ddd;
  background: white;
}

.backdrop {
  display: none;
}

.menu-button {
  display: none;
}

.menu-button fm-icon {
  color: black;
}

nav {
  display: flex;
  flex-direction: column;
  width: 100%;
}

main {
  padding-left: 256px;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  width: 100%;
  background: var(--primary-color);
  height: 64px;
  color: white;
  font-size: 24px;
}

#toast-container {
  position: sticky;
  top: 0;
  width: 100%;
  height: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

fm-toast {
  position: static;
  z-index: 1;
  min-height: 64px;
  min-width: 320px;
  font-size: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.14);
  transform: translateY(-50%);
}

fm-toast[opened] {
  transform: translateY(0);
}

.notification-icon {
  color: white;
}

.link, a {
  width: 100%;
  height: 48px;
}

a {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 500;
  color: #666;
  padding: 0 24px;
  transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

a:hover {
  color: #333;
  background: rgba(0, 0, 0, 0.07);
}

.link[active] a {
  color: var(--primary-color);
}

.link[disabled] a {
  color: #666;
}

.top-container {
  display: flex;
  align-items: center;
  padding: 0 24px;
  height: 64px;
  border-bottom: 1px solid #ddd;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.logo {
  width: 24px;
}

.logo-text {
  font-weight: 500;
  font-size: 20px;
  margin-right: 24px;
}

.logged-in-user {
  text-align: left;
  padding: 16px 24px;
  margin: 0;
  color: #333;
  font-size: 16px;
  font-weight: 500;
}

@media (max-width: 1024px) {
  aside {
    z-index: 100;
    top: 0;
    bottom: 0;
    left: -300px;
    width: 300px;
    box-shadow: 3px 0 8px 3px rgba(0, 0, 0, 0.1);
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-property: transform;
    transition-duration: 0.3s;
  }

  main {
    padding: 0;
  }

  .backdrop {
    z-index: 100;
    display: block;
    position: fixed;
    visibility: hidden;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    background: black;
    transition-property: opacity;
  }

  .menu-button {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    margin: 16px;
    height: 56px;
    width: 56px;
    padding: 12px;
    border-radius: 3px;
    cursor: pointer;
    background: white;
    border: 1px solid #ddd;
    box-shadow: 0 6px 8px rgba(0,0,0,0.19), 0 2px 2px rgba(0,0,0,0.23);
  }

  :host([open]) aside {
    transform: translateX(300px);
  }

  :host([open]) .backdrop {
    visibility: visible;
    opacity: 0.3;
  }
}
</style>
<style media="print">
  .menu-button {
    display: none
  }
</style>
`;
