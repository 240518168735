import { LitElement, html } from "lit";

import sharedStyles from "../../styles/shared.js";
import tableStyles from "../../styles/tables.js";

export default class FundsView extends LitElement {
	render() {
		return html`
    ${sharedStyles}
    ${tableStyles}
      <header class="horizontal-menu-header">
        <router-link to="/funds/positions">
          <a>Positions</a>
        </router-link>
        <router-link to="/funds/categories">
          <a>Categories</a>
        </router-link>
        <router-link to="/funds/generalmeeting">
          <a>General Meeting</a>
        </router-link>
        <router-link to="/funds/documents">
         <a>Signed Documents</a>
        </router-link>
        <router-link to="/funds/dividend">
          <a>Fund Dividend</a>
        </router-link>
        <router-link to="/funds/calendars">
          <a>Calendars</a>
        </router-link>
      </header>
      <slot></slot>
    `;
	}
}

customElements.define("funds-view", FundsView);
