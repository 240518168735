import { LitElement, css, html } from "lit";
import { customElement } from "lit/decorators.js";
import { styles } from "../../styles/shared.js";

@customElement("not-found-view")
export default class NotFoundView extends LitElement {
	static styles = [
		styles,
		css`
      :host {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 64px 0;
      }

      h1 {
        padding: 16px 0;
      }

      router-link {
        color: var(--primary-color);
      }
    `,
	];

	render() {
		return html`
      <h1>Siden blev ikke fundet</h1>
      <router-link>
        <a href="/accounts">Tilbage til forsiden</a>
      </router-link>
    `;
	}
}
