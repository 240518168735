import { css, html } from "lit";

export const styles = css`
  table {
    border-collapse: collapse;
    border-bottom: none;
    width: 100%;
  }

  table.small {
    width: auto;
  }

  table a {
    color: #448AFF;
    text-decoration: none;
  }

  th, td {
    padding: 12px;
    border-bottom: 1px solid #ddd;
  }

  th {
    text-align: left;
    color: #757575;
    font-size: 13px;
    font-weight: 500;
  }

  th[data-key] {
    cursor: pointer;
  }

  td {
    color: #333;
    font-size: 14px;
  }

  .numeric {
    text-align: right;
  }

  .right-align {
    text-align: right;
  }

  .draft-order {
    width: 100%;
    display: grid;
    grid-template-columns: 5% 10% 20% 20% 10% 10% 10% 15%;
    grid-template-rows: auto;
    border-bottom: 1px solid #ddd;
  }

  .order-detail {
    width: 100%;
    display: grid;
    grid-template-columns: 4% 4% 11% 10% 10% 8% 8% 6% 6% 6% 6% 8% 6% 4%;
    grid-template-rows: auto;
    border-bottom: 1px solid #ddd;
  }
`;

export default html`<style>${styles}</style>`;
