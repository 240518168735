import { LitElement, html } from "lit";

import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import inputStyles from "../../styles/input.js";
import sharedStyles from "../../styles/shared.js";

export default class FormcalcShowView extends LitElement {
	static get properties() {
		return {
			row: {
				type: Object,
			},
			rows: {
				type: Array,
			},
			calcid: {
				type: String,
				observed: true,
			},
		};
	}
	set calcid(val) {
		if (val !== this._calcid) {
			this._calcid = val;
			api.get(`/formcalc/${this._calcid}`).then((r) => {
				this.rows = r;
			});
		}
	}
	constructor() {
		super();
		this.rows = [];
		this._calcid = 0;
	}

	attributeChangedCallback(name, oldVal, newVal) {
		super.attributeChangedCallback(name, oldVal, newVal);
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 12px auto;
        max-width: 1400px;
      }
      </style>
      <slot></slot>
      <div class="card">
        <div class="card-header">
          <h1>Calculation results</h1>
        </div>
        <table>
          <tr>
            <th>Formula</th>
            <th>recno</th>
            <th>colno</th>
            <th>name</th>
            <th>result</th>
          </tr>
        ${this.rows.map(
					(r) =>
						html`
          <tr>
          <td>${r.code}</td>
          <td>${r.recno}</td>
          <td>${r.colno}</td>
          <td>${r.colname}</td>
          <td>${r.result}</td>
        </tr>`,
				)}
       </table>
        </div>
        `;
	}
}

customElements.define("formcalc-show-view", FormcalcShowView);
