import { LitElement, html } from "lit";

import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { date } from "../../formatting/dateformats.js";
import { quantity, money } from "../../formatting/numberformats.js";
import { addDays } from "../../utils.js";

export default class UserTaxView extends LitElement {
	static get properties() {
		return {
			custody_id: {
				type: String,
			},
			report: {
				type: Object,
			},
		};
	}

	render() {
		const fromdateelement = this.shadowRoot.querySelector("#fromdate");
		const todateelement = this.shadowRoot.querySelector("#todate");
		const fromdate = fromdateelement ? fromdateelement.value : null;
		const todate = todateelement ? todateelement.value : null;
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1400px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>Tax report</h1>
        </div>
        <div class="card-block">
        From
            <input type="date" id="fromdate"  class="date-input" value="${
							this.fromdate
						}">
        To
            <input type="date" id="todate"  class="date-input" value="${
							this.todate
						}">
        <fm-button class="btn" @click="${this.refresh}" >Refresh</fm-button>
        </div>
        <table>
        <thead>
            <tr>
                <th>Konto</th>
                <th>Fra</th>
                <th>Til</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>${this.report.title}</td>
                <td>${date(fromdate)}</td>
                <td>${date(todate)}</td>
            </tr>
        </tbody>
        </table>
        <table>
        <thead>
        <tr>
        <th colspan="5"></th>
        <th colspan="2" style="text-align:center">Aktieindkomst</th>
        <th colspan="2"  style="text-align:center">Kapitalindkomst</th>
        </tr>
        <tr>
          <th>Dato</th>
          <th>Transaktion</th>
          <th class="numeric">Antal beviser</th>
          <th class="numeric">Beholdning efter</th>
          <th class="numeric">Beløb</th>
          <th class="numeric">Avance</th>
          <th class="numeric">Udbytte</th>
          <th class="numeric">Avance</th>
          <th class="numeric">Udbytte</th>
        </tr>
        </thead>
                <tbody>
        ${(this.report.content || []).map((c) => this.renderSec(c))}
        <tr>
        <td colspan="9"><hr style="width:100%"></td>
        </tr>
        <tr>
          <td></td>
          <td colspan="4">Ialt ${this.report.title}</td>
          <td class="numeric">${money(this.report.total.profit_loss_eq)}</td>
          <td class="numeric">${money(this.report.total.dividend_eq)}</td>
          <td class="numeric">${money(this.report.total.profit_loss_cap)}</td>
          <td class="numeric">${money(this.report.total.dividend_cap)}</td>
        </tr>
        </tbody>
        </table>
      </div>
    `;
	}

	renderSec(c) {
		return html`
  <tr>
  <td colspan="10">${c.subtitle} ${c.subsubtitle}</td>
  </tr>
  ${c.rows.map((r) => this.renderTran(r))}
  <tr>
  <th></th>
  <th colspan="4">I alt ${c.subtitle}</th>
  <th class="numeric">${money(c.total.profit_loss_eq)}</th>
  <th class="numeric">${money(c.total.dividend_eq)}</th>
  <th class="numeric">${money(c.total.profit_loss_cap)}</th>
  <th class="numeric">${money(c.total.dividend_cap)}</th>
  </tr>
  `;
	}

	renderTran(t) {
		return html`
<tr>
<td>${t.transaction_date}</td>
<td>${t.transaction_type}</td>
<td class="numeric">${
			t.transaction_type === "Ultimo" ? "" : quantity(t.quantity)
		}</td>
<td class="numeric">${quantity(t.balance)}</td>
<td class="numeric">${money(t.buysell)}</td>
<td class="numeric">${money(t.profit_loss_eq)}</td>
<td class="numeric">${money(t.dividend_eq)}</td>
<td class="numeric">${money(t.profit_loss_cap)}</td>
<td class="numeric">${money(t.dividend_cap)}</td>
</tr>`;
	}

	constructor() {
		super();
		this.report = {
			title: "",
			content: [],
			total: {
				profit_loss_eq: 0,
				dividend_eq: 0,
				profit_loss_cap: 0,
				dividend_cap: 0,
			},
		};
		this.refresh = this.refresh.bind(this);
		const today = new Date();
		this.fromdate = date(addDays(today, -5));
		this.todate = date(today);
	}

	async fetch() {
		const fromdate = this.shadowRoot.querySelector("#fromdate").value;
		const todate = this.shadowRoot.querySelector("#todate").value;
		this.report = await api.get(
			`/custodies/${this.custody_id}/tax?from=${fromdate}&to=${todate}`,
		);
	}

	async refresh(event) {
		const target = event.target;
		target.loading = true;
		await this.fetch();
		target.loading = false;
	}
}

customElements.define("user-tax-view", UserTaxView);
