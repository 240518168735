import { LitElement, html } from "lit";

import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { date } from "../../formatting/dateformats.js";
import { sort } from "../../utils.js";

export default class FundsPositionView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			rerender: {
				type: Number,
			},
		};
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1000px;
      }
      </style>
       <div class="card">
        <div class="card-header">
          <h1>Positions</h1>
        </div>
        <div class="card-block">
         <input type="date"  class="date-input" id="asofdate" value="${
						this.asofdate
					}" @change="${this.onInput}">
          <input type="search" class="search-input" id="fquery" placeholder="Search funds" @change="${
						this.onInput
					}">
          <input type="search" class="search-input" id="iquery" placeholder="Search investors" @change="${
						this.onInput
					}">
          <fm-button class="btn" @click="${this.fetch}">Refresh</fm-button>
        </div>
        <table>
          <thead>
            <tr>
              <th colspan="4" >Fund</th>
              <th class="numeric">Units</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            ${this.rows.map(
							(row) => html`
              <tr>
              <td colspan="4">${row.name}</td>
              <td class="numeric">${row.units_total}</td>
              <td></td>
              </tr>
              ${(row.custodies || []).map(
								(c) =>
									html`
                <tr>
                <td></td>
                <td>${c.owner_name}</td>
                <td>${c.custody_code}</td>
                <td>${c.custodytype_code}</td>
                <td class="numeric">${c.quantity}</td>
                <td class="numeric">${c.marketvalue_qc}</td>
                </tr>`,
							)}
            `,
						)}
          </tbody>
        </table>
      </div>
   `;
	}

	renderDetails(securities) {
		return securities.map(
			(s) => html`
<tr>
<td></td>
<td>${s.security_name}</td>
<td>${s.custody_code}</td>
<td class="numeric">${s.quantity}</td>
<td class="numeric"></td>
</tr>
`,
		);
	}

	constructor() {
		super();
		this.rows = [];
		this.asofdate = date(Date.now());
		this.fquery = "";
		this.iquery = "";
		this.onInput = this.onInput.bind(this);
		this.fetch = this.fetch.bind(this);
		this.sort = this.sort.bind(this);
		this.rerender = 0;
	}

	onInput() {
		this.fquery = this.shadowRoot.querySelector("#fquery").value;
		this.iquery = this.shadowRoot.querySelector("#iquery").value;
		this.asofdate = this.shadowRoot.querySelector("#asofdate").value;
	}

	async fetch(event) {
		const target = event.target;
		target.loading = true;
		this.rows = await api.get(
			`/funds/position?asofdate=${this.asofdate}&fquery=${this.fquery}&iquery=${this.iquery}`,
		);
		target.loading = false;
	}

	sort(event) {
		const key = event.target.getAttribute("data-key");
		this.rows = sort(this.rows, key);
		this.rerender = this.rerender + 1;
	}
}

customElements.define("funds-position-view", FundsPositionView);
