import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { money } from "../../formatting/numberformats.js";
import { toast } from "../../utils.js";
import { DateFormatter } from "../../formatting/dateformats.js";

export default class AMLOrderflowView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			custodytypes: {
				type: Array,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.custodytypes = [];
		this.dateStr = "";
		this.refresh = this.refresh.bind(this);
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1800px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h2>Orderflow versus questions</h2>
        </div>
        ${this.renderQueryForm()}
        <table>
          <thead>
            <tr>
             <th>Violation</th>
             <th>Account</th>
             <th>Advisor</th>
             <th>Custodytype</th>
             <th class="numeric">Balance start</th>
             <th class="numeric">Inflow gross</th>
             <th class="numeric">Inflow net</th>
             <th class="numeric">Inflow q.</th>
             <th class="numeric">Inflow Start q.</th>
             <th class="numeric">Inflow Count</th>
             <th class="numeric">Inflow Count q.</th>
             <th class="numeric">Outflow count</th>
             <th class="numeric">Outflow count q.</th>
             <th class="numeric">Income q.</th>
             </tr>
          </thead>
          <tbody>
            ${this.rows.map(
							(row) => html`
              <tr>
              <td>${row.violation}</td>
                <td>${row.account_name}</td>
                <td>${row.advisor_name}</td>
                <td>${row.custodytype_code}</td>
                <td class="numeric">${money(row.amount_start)}</td>
                <td class="numeric">${money(row.amount_inflow_gross)}</td>
                <td class="numeric">${money(row.amount_inflow_net)}</td>
                <td class="numeric">${money(row.amount_inflow_q)}</td>
                <td class="numeric">${money(row.amount_start_q)}</td>
                <td class="numeric">${row.count_inflow}</td>
                <td class="numeric">${row.count_inflow_q}</td>
                <td class="numeric">${row.count_outflow}</td>
                <td class="numeric">${row.count_outflow_q}</td>
                <td class="numeric">${money(row.amount_income_q)}</td>
              </tr>
            `,
						)}
          </tbody>
        </table>
      </div>
    `;
	}

	renderCustodyTypes() {
		return html`
    <select name="custodytypes" multiple size="5" style="width:100%">
      ${this.custodytypes.map(
				(t) =>
					html`<option value="${t.id}"  ?selected=${t.selected === "Y"}>${
						t.description
					}</option>`,
			)}
    </select>`;
	}

	renderQueryForm() {
		return html`
   <fm-form id = "parms" class="form-grid" @submit="${this.refresh}">
     <div class="form-field">
        <label>Order Period
          <fm-date-range id="flow" name="flow"  names="flow-" ></fm-date-range>
         </label>
      </div>
       <div class="form-field">
          <label>Custody types
            ${this.renderCustodyTypes()}
          </label>
       </div>
        <div class="form-field">
         <label>Search
          <input type="search"  name="query"  class="search-input" style="width:100%">
         </label>
       </div>
        <div class="form-field" style="display:flex">
          <fm-button type="submit" id="submit_button" style="max-width:80px;margin:4px;">Refresh</fm-button>
      </div>
  </fm-form>
    `;
	}

	async connectedCallback() {
		super.connectedCallback();
		let todate = localStorage.getItem("orderflow-todate");
		const fromdate = localStorage.getItem("orderflow-fromdate");

		if (!todate) {
			todate = DateFormatter.format(new Date(), "yyyy-mm-dd");
			localStorage.setItem("orderflow-todate", todate);
		}
		if (!fromdate) {
			localStorage.setItem("orderflow-fromdate", todate);
		}

		this.types = localStorage.getItem("orderflow-types") || "";
		this.custodytypes = await api.get(`/lov/custodytypes?s=${this.types}`);
	}

	async refresh() {
		const button = this.shadowRoot.querySelector("#submit_button");
		button.loading = true;
		const parms = this.shadowRoot.querySelector("#parms").value;
		const dates = this.shadowRoot.querySelector("#flow").value;
		localStorage.setItem("orderflow-fromdate", dates.from);
		localStorage.setItem("orderflow-todate", dates.to);

		const reducer = (a, t) => `${a}${t},`;
		const types = parms.custodytypes.reduce(reducer, ",");
		localStorage.setItem("orderflow-types", types);

		try {
			const q = `/aml/orderflow?query=${parms.query}&from=${dates.from}&to=${dates.to}&types=${types}`;
			this.rows = await api.get(q);
		} catch (err) {
			Sentry.captureException(err);
			toast("Fetch failed");
			this.rows = [];
		}
		button.loading = false;
	}
}

customElements.define("aml-orderflow-view", AMLOrderflowView);
