import { LitElement, html } from "lit";

class OptionsElement extends LitElement {
	static get properties() {
		return {
			name: {
				type: String,
				reflect: true,
			},
		};
	}

	constructor() {
		super();
		this.activeDescendant = null;
		this.onSelect = this.onSelect.bind(this);
	}

	render() {
		return html`
    <style>
      :host {
        display: flex;
        flex-direction: column;
        width: 100%;
        box-sizing: border-box;
        max-height: 160px;
        overflow-y: auto;
        border: 1px solid #ddd;
        background: white;
      }
    </style>
    <slot @select="${this.onSelect}">
      No results.
    </slot>
    `;
	}

	firstRendered() {
		this.$slot = this.shadowRoot.querySelector("slot");
		this.activeDescendant = this.$slot.firstElementChild;
	}

	connectedCallback() {
		super.connectedCallback();
		this.setAttribute("role", "listbox");
	}

	get value() {
		return this.activeDescendant?.value;
	}

	get options() {
		return this.querySelectorAll("fm-option");
	}

	first() {
		return this.options[0];
	}

	last() {
		return this.options[this.options.length - 1];
	}

	previous() {
		if (this.current() == null) {
			return this.last();
		} else if (this.current().previousElementSibling == null) {
			return this.last();
		} else {
			return this.current().previousElementSibling;
		}
	}

	current() {
		return this.activeDescendant;
	}

	next() {
		if (this.current() == null) {
			return this.first();
		} else if (this.current().nextElementSibling == null) {
			return this.first();
		} else {
			return this.current().nextElementSibling;
		}
	}

	select(descendant) {
		if (this.activeDescendant) {
			this.activeDescendant.deselect();
		}
		this.activeDescendant = descendant;
		this.activeDescendant.select();

		const parent = this.getBoundingClientRect();
		const child = this.activeDescendant.getBoundingClientRect();

		if (child.bottom > parent.bottom + 10) {
			// Align child bottom to parent bottom
			this.activeDescendant.scrollIntoView({
				block: "end",
				inline: "nearest",
			});
		} else if (child.top < parent.top - 10) {
			// Align child top to parent top
			this.activeDescendant.scrollIntoView({
				block: "start",
				inline: "nearest",
			});
		}
	}

	commit() {
		this.dispatchEvent(new Event("select"));
	}

	onSelect(event) {
		console.log("fm-options select");
		this.select(event.path[0]);
		this.commit();
	}
}

customElements.define("fm-options", OptionsElement);
