import { LitElement, html } from "lit";
import { customElement } from "lit/decorators.js";

import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";

@customElement("transaction-view")
export class TransactionView extends LitElement {
	static styles = [sharedStyles, tableStyles];

	render() {
		return html`
      <header class="horizontal-menu-header">
        <router-link to="/transactions" exact>
          <a>List</a>
        </router-link>
        <router-link to="/transactions/dividends">
        <a>Fund Dividends</a>
      </router-link>
      </header>
      <slot></slot>
    `;
	}
}
