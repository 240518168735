import { html } from "lit";

import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { money } from "../../formatting/numberformats.js";

export function renderAMLJobs(classobj) {
	return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1800px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h2>Monitor AML Breaches</h2>
        </div>
        ${renderQueryForm(classobj)}
        <table>
          <thead>
            <tr>
              <th>Id</th>
              <th>Reg.date</th>
              <th>Reference</th>
              <th>Evaluations</th>
            </tr>
          </thead>
          <tbody>
            ${classobj.rows.map(
							(breach) => html`
              <tr>
                <td>${breach.id}</td>
                <td>${breach.reg_date}</td>
                <td>${renderReference(breach)}</td>
                <td>${renderEvaluations(
									breach.id,
									breach.evaluations,
									classobj,
								)}</td>
            </tr>
            `,
						)}
          </tbody>
        </table>
      </div>
    `;
}

function renderQueryForm(classobj) {
	return html`
 <fm-form id="parms" class="form-grid" @submit="${classobj.refresh}">
  <div class="form-field">
      <label>Breach types
         ${renderJobTypes(classobj)}
      </label>
  </div>
  <div class="form-field">
      <label>Latest Evaluation
         ${renderEvaluationTypes(classobj)}
      </label>
  </div>
    <div class="form-field">
       <label>Search
        <input type="search"  name="query"  class="form-field search-input" >
       </label>
    </div>
    <div class="form-field">
      <fm-button type="submit" id="submit_button" class="button" >Refresh</fm-button>
    </div>
</fm-form>
  `;
}

function renderReference(breach) {
	if (breach.type === "order_foreign") {
		return html`
    <table>
      <tr>
        <td>Order-Id</td><td>${breach.order.id}</td>
      </tr>
      <tr>
        <td>Reg-date</td><td>${breach.order.reg_date}</td>
      </tr>
      <tr>
        <td>Custody</td><td>${breach.order.custody.custody_code}</td>
      </tr>
      <tr>
        <td>Type</td><td>${breach.order.custody.custodytype_code}</td>
      </tr>
      <tr>
        <td>Owner(s)</td><td><table>${breach.order.custody?.owners?.map(
					(o) => html`<tr><td>${o.name}</td></tr>`,
				)}</table></td>
      </tr>
      <tr>
        <td>Amount</td><td>${money(breach.order.amountqc)}</td>
      </tr>
      <tr>
        <td>Source</td><td>${breach.order.source}</td>
      </tr>
      <tr>
        <td>Bank acc.</td><td>${breach.order.from_bank}</td>
      </tr>
      <tr>
        <td>Message</td><td>${breach.order.message}</td>
      </tr>
      <tr>
        <td>Info</td><td>${breach.order.from_info}</td>
      </tr>
      <tr>
        <td>Ref</td><td>${breach.order.from_ref}</td>
      </tr>
  </table>`;
	} else {
		return "";
	}
}

function renderJobTypes(classobj) {
	return html`
      <select name="breachtypes" multiple size="4" class="form-field">
        ${classobj.breachtypes.map(
					(t) =>
						html`<option value="${t.id}"  ?selected=${t.selected === "Y"}>${
							t.description
						}</option>`,
				)}
      </select>`;
}

function renderEvaluationTypes(classobj) {
	return html`
      <select name="evaluationtypes" multiple size="4" class="form-field">
        ${classobj.evaluationtypes.map(
					(t) =>
						html`<option value="${t.id}"  ?selected=${t.selected === "Y"}>${
							t.description
						}</option>`,
				)}
      </select>`;
}

function renderNewEvaluation(id, classobj) {
	return html`
    <tr>
      <td>
        <select id="select-${id}" data-id="${id}">
        ${classobj.evaluationtypes.map(
					(a) => html`<option  value="${a.id}">${a.description}</option>`,
				)}
      </select>
    </td>
    <td colspan="2">
      Comment: <input id="cmt-${id}"  type="text" class="form-field" />
    </td>
    <td>
     <fm-button type="button" data-id="${id}" @click="${
		classobj.onEvaluationSave
	}">Save</fm-button>
    </td>
  </tr>
  `;
}

function renderEvaluationsData(evaluations) {
	return html`
  ${evaluations?.map(
		(a) => html`
   <tr>
   <td>${a.description}</td>
   <td>${a.adm_comment}</td>
    <td>${a.reg_date}</td>
    <td>${a.name}</td>
</tr>`,
	)}
  `;
}

function renderEvaluations(breach_id, evaluations, classobj) {
	return html`
     <table>
        ${renderEvaluationsData(evaluations)}
        ${renderNewEvaluation(breach_id, classobj)}
    </table>
`;
}
