import FundsView from "./funds-view.js";
import FundsPositionView from "./position-view.js";
import FundsCategoryView from "./funds-category-view.js";
import GeneralMeetingView from "./general-meeting-view.js";
import FundsSignaturesView from "./funds-signatures-view.js";
import FundsDividendView from "./funds-dividend-view.js";
import { FundsCalendarsView } from "./funds-calendars-view.js";
import type { RouteOptions } from "runway";

export default {
	path: "funds",
	component: FundsView,
	children: [
		{
			path: "",
			exact: true,
			redirect: "positions",
		},
		{
			path: "positions",
			component: FundsPositionView,
		},
		{
			path: "categories",
			component: FundsCategoryView,
		},
		{
			path: "generalmeeting",
			component: GeneralMeetingView,
		},
		{
			path: "documents",
			component: FundsSignaturesView,
		},
		{
			path: "dividend",
			component: FundsDividendView,
		},
		{
			path: "calendars",
			component: FundsCalendarsView,
		},
	],
} satisfies RouteOptions;
