import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import inputStyles from "../../styles/input.js";
import sharedStyles from "../../styles/shared.js";
import { toast } from "../../utils.js";
import router from "../../router/index.js";

export default class FormCalculationsView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			new: {
				type: Boolean,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.new = false;
		this.refresh = this.refresh.bind(this);
		this.newCalc = this.newCalc.bind(this);
		this.saveNewCalc = this.saveNewCalc.bind(this);
		this.showCalc = this.showCalc.bind(this);
		this.deleteCalc = this.deleteCalc.bind(this);
		this.q_code = "";
		this.q_name = "";
		this.forms = [];
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 12px auto;
        max-width: 1400px;
      }
      </style>
      <slot></slot>
      <div class="card">
        <div class="card-header">
          <h1>Form calculations</h1>
          <fm-button type="button" @click="${this.newCalc}">New</fm-button>
        </div>
        <div class="card">
      <fm-form id="parms" class="form-grid"  @submit="${this.refresh}" >
       <label class="form-field">
        As of Date
          <fm-date-range id="asofdate" name="asofdate"  names="calculation-" ></fm-date-range>
       </label>
      <label class="form-field">
        Search
        <input type="text" name="query" value="${this.query || ""}">
      </label>
      <fm-button type="submit" id="submit_button" >Refresh</fm-button>
    </fm-form>
   </div>
   ${this.renderNewCalcForm()}
        <table>
          <thead>
            <tr>
              <th>Show</th>
              <th>Delete</th>
              <th>Code</th>
              <th>Name</th>
              <th>As of date</th>
              <th>Created</th>
            </tr>
          </thead>
          <tbody>
            ${this.rows.map(
							(r) => html`
              <tr>
                <td><fm-button data-id="${r.calc_id}" type="button" @click="${this.showCalc}" style="max-width:80px">Show</fm-button></td>
                <td><fm-button data-id="${r.calc_id}" type="button" @click="${this.deleteCalc}" style="max-width:80px">Delete</fm-button></td>
                <td>${r.fc_code}</td>
                <td>${r.fc_name}</td>
                <td>${r.todate}</td>
                <td>${r.txt_reg_date}</td>
              </tr>
            `,
						)}
          </tbody>
        </table>
      </div>
    `;
	}

	renderNewCalcForm() {
		if (this.new) {
			return html`
      <div class="card-block">
      <h2>New calculation</h2>
        <fm-form id="newcalc" class="form-grid"  @submit="${this.saveNewCalc}">
         <label class="form-field">
         Form
          <select name="calc_id">
             ${this.forms.map(
								(f) => html`
                <option value="${f.id}">${f.name}</option>
            `,
							)}
          </select>

      </label>
      <label class="form-field">
      Name
        <input type="text" name="name" >
      </label>
      <label class="form-field">
        Asof date <input type="text" name="asofdate" >
      </label>
        <fm-button type="submit" id="submit_button" >Save</fm-button>
      </fm-form> 
      </div>`;
		}
		return "";
	}

	async refresh() {
		const parms = this.shadowRoot.querySelector("#parms").value;
		const dates = this.shadowRoot.querySelector("#asofdate").value;
		const submit_button = this.shadowRoot.querySelector("#submit_button");
		submit_button.loading = true;
		localStorage.setItem("calculation-fromdate", dates.from);
		localStorage.setItem("calculation-todate", dates.to);
		localStorage.setItem("calculation-query", parms.query);

		const q = `?fromdate=${dates.from}&todate=${dates.to}&query=${parms.query}`;
		try {
			this.rows = await api.get(`/formcalc${q}`);
		} catch (err) {
			Sentry.captureException(err);
			const response = await err.json();
			toast(JSON.stringify(response));
		}
		submit_button.loading = false;
	}

	async newCalc() {
		this.forms = await api.get("/forms/listforms");
		this.new = true;
	}

	async saveNewCalc() {
		const data = this.shadowRoot.querySelector("#newcalc").value;
		const calc = await api.post("/formcalc", data);
		const response = await api.post(`/formcalc/${calc.id}`);
	}

	showCalc(event) {
		const target = event.target;
		const id = target.getAttribute("data-id");
		router.push(`/formcalc/calculations/${id}`);
	}

	async deleteCalc(event) {
		const target = event.target;
		const id = target.getAttribute("data-id");
		try {
			await api.delete(`/formcalc/${id}`);
			toast("Deleted");
			await this.refresh();
		} catch (err) {
			Sentry.captureException(err);
			const error = await err.json();
			toast(JSON.stringify(error));
		}
	}
}

customElements.define("formcalculations-view", FormCalculationsView);
