import OrdersView from "./orders-view.js";
import OrdersListView from "./orders-list-view.js";
import OrdersMoveView from "./orders-move-view.js";
import OrdersPensionView from "./orders-pension-view.js";
import OrdersReportView from "./orders-report-view.js";
import OrdersSellView from "./orders-sell-view.js";
import OrdersRedemptionsView from "./orders-redemptions-view.js";
import { RouteOptions } from "runway";

export default {
	path: "orders",
	component: OrdersView,
	children: [
		{
			path: "",
			exact: true,
			component: OrdersListView,
		},
		{
			path: "move",
			component: OrdersMoveView,
		},
		{
			path: "sell",
			component: OrdersSellView,
		},
		{
			path: "pension",
			component: OrdersPensionView,
		},
		{
			path: "report",
			component: OrdersReportView,
		},
		{
			path: "redemptions",
			component: OrdersRedemptionsView,
		},
	],
} satisfies RouteOptions;
