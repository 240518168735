import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { DateFormatter } from "../../formatting/dateformats.js";
import { money } from "../../formatting/numberformats.js";
import { toast } from "../../utils.js";

export default class AMLRiskOutflowView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.query = "";
		this.refresh = this.refresh.bind(this);
		let todate = localStorage.getItem("ri-todate");
		const fromdate = localStorage.getItem("ri-fromdate");
		if (!todate) {
			todate = DateFormatter.format(new Date(), "yyyy-mm-dd");
			localStorage.setItem("ri-todate", todate);
		}
		if (!fromdate) {
			localStorage.setItem("ri-fromdate", todate);
		}
		this.query = localStorage.getItem("ri-query");
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1800px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>Outflow invested period</h1>
       </div>

       <fm-form id = "parms" class="form-grid" @submit="${this.refresh}">
         <div class="form-field">
           <label>Order date
             <fm-date-range id="regdate" name="regdate"  names="ri-" ></fm-date-range>
            </label>
         </div>
         <div class="form-field"></div>
         <div class="form-field">
           <label>Search owner
             <input type="search"  name="query" value="${this.query || ""}" class="search-input" style="width:100%">
           </label>
         </div>
         <div class="form-field">
           <label>Invested less than (days)
             <input type="number"  name="age" value="${this.age || ""}"  >
           </label>
         </div>

         <fm-button type="submit" id="submit_button" class="btn" style="max-width:80px">Refresh</fm-button>
       </fm-form>

        <table>
          <thead>
            <tr>
              <th>Order-ID</th>
              <th>Trade date</th>
              <th>Owner Name</th>
              <th>Account Name</th>
              <th>Advisor Name</th>
              <th>Custody</th>
              <th class="numeric">Amount</th>
              <th class="numeric">Invested period</th>
            </tr>
            </thead>
            <tbody>
            ${this.rows.map((r) => this.renderOrder(r))}
            </tbody>
        </table>
      </div>
    `;
	}

	renderOrder(row) {
		return html`
    <tr>
      <td>${row.id}</td>
      <td>${row.tradedate}</td>
      <td>${row.owner_name}</td>
      <td>${row.account_name}</td>
      <td>${row.advisor_name}</td>
      <td>${row.custody_code}</td>
      <td class="numeric">${money(row.amount)}</td>
      <td class="numeric">${row.age}</td>
    </tr>`;
	}

	connectedCallback() {
		super.connectedCallback();
		let todate = localStorage.getItem("ri-todate");
		const fromdate = localStorage.getItem("ri-fromdate");

		if (!todate) {
			todate = DateFormatter.format(new Date(), "yyyy-mm-dd");
			localStorage.setItem("ri-todate", todate);
		}
		if (!fromdate) {
			localStorage.setItem("ri-fromdate", todate);
		}
		this.query = localStorage.getItem("ri-query");
	}

	async refresh() {
		const parms = this.shadowRoot.querySelector("#parms").value;
		const dates = this.shadowRoot.querySelector("#regdate").value;
		const submit_button = this.shadowRoot.querySelector("#submit_button");
		submit_button.loading = true;
		localStorage.setItem("ri-fromdate", dates.from);
		localStorage.setItem("ri-todate", dates.to);
		localStorage.setItem("ri-query", parms.query);

		this.query = parms.query;
		this.age = parms.age;
		const q1 = `?from=${dates.from}&to=${dates.to}&query=${this.query}&age=${this.age}`;

		try {
			this.rows = await api.get(`/aml/riskoutflow${q1}`);
		} catch (err) {
			Sentry.captureException(err);
			toast("Det lykkedes ikke at hente data");
		}

		submit_button.loading = false;
	}
}

customElements.define("aml-riskoutflow-view", AMLRiskOutflowView);
