import {
	BrowserTracing,
	setContext,
	init as initSentry,
} from "@sentry/browser";
import { RouterLink } from "runway";
import router from "./router/index.js";
import api from "./api/index.js";
import store from "./store/index.js";
import "./elements.js";
import { receiveStatus } from "./store/actions.js";
import "./components/fm-date-range.js";
import "./components/fm-option.js";
import "./components/fm-options.js";
import "./components/fm-autocomplete.js";
import "./components/fm-relation.js";
import "./components/fm-accountinfo.js";
import "./components/redemption-edit-view.js";
import { ENVIRONMENT, SENTRY_DSN, VERSION } from "./env.js";

console.log("Environment:", ENVIRONMENT);
console.log("Version:", VERSION);

declare global {
	interface ToastElement extends HTMLElement {
		duration: number;
		open: () => void;
	}

	interface HTMLElementTagNameMap {
		"fm-toast": ToastElement;
	}
}

RouterLink.use(router);
customElements.define("router-link", RouterLink);

if (ENVIRONMENT !== "development") {
	initSentry({
		dsn: SENTRY_DSN,
		integrations: [new BrowserTracing()],
		environment: ENVIRONMENT,
		release: VERSION,
		tracesSampleRate: 0.6,
	});
}

api.get("/login").then((status) => {
	store.dispatch(receiveStatus(status));
	if (status.authenticated) {
		setContext("user", {
			session: localStorage.getItem("APIToken"),
		});
	}
	router.connect(document.body);
});
