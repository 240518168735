import { LitElement } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import { toast } from "../../utils.js";
import { ext_render } from "./report-pepdata-render";

export default class ReportPEPDataView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			status_message: {
				type: String,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.eventtypes = [];
		this.status_message = "";
		this.relevanceChange = this.relevanceChange.bind(this);
		this.uuidDetails = this.uuidDetails.bind(this);
		this.refresh = this.refresh.bind(this);
		this.onPEPUpdate = this.onPEPUpdate.bind(this);
	}

	render() {
		return ext_render(this);
	}

	async onPEPUpdate() {
		const but = this.shadowRoot.querySelector("#upd");
		but.loading = true;
		try {
			const token = await api.get("/users/peptoken");
			const l = this.rows.length;
			for (let i = 0; i < l; i++) {
				this.status_message = `${i + 1} of ${l}: ${this.rows[i].name} `;
				await api.get(
					`/users/${this.rows[i].id}/pepdata?t=${token.auth_token}`,
				);
			}
			toast("Pepdata updated");
		} catch (err) {
			Sentry.captureException(err);
			toast("Error updating Pepdata");
		}
		but.loading = false;
	}

	async connectedCallback() {
		super.connectedCallback();
		this.eventtypes = await api.get("/lov/eventcodes");
	}

	async refresh() {
		const but = this.shadowRoot.querySelector("#submit_button");
		but.loading = true;
		const form = this.shadowRoot.querySelector("#parms").value;
		const q = `?query=${form.query}&months=${form.months}&pep=${
			form.pep || ""
		}&irrelevant=${form.irrelevant || ""}`;
		this.rows = await api.get(`/users/pepquery${q}`);
		but.loading = false;
	}

	async relevanceChange(event) {
		const target = event.target;
		const id = target.getAttribute("data-id");
		await api.put(`/accounts/0/pepinfo/${id}`, {});
		await this.refresh();
	}

	async uuidDetails(event) {
		const target = event.target;
		const user_id = target.getAttribute("data-id");
		const uuid = target.getAttribute("data-uuid");
		const token = await api.get("/users/peptoken");
		await api.get(
			`/users/${user_id}/entity?uuid=${uuid}&t=${token.auth_token}`,
		);
		await this.refresh();
	}

	async eventSave() {
		try {
			const formdata = this.shadowRoot.querySelector("#eventform").value;
			await api.post(`/users/${formdata.user_id}/events`, formdata);
			toast("Event added");
			this.requestUpdate();
		} catch (err) {
			Sentry.captureException(err);
			const error = await err.json();
			console.log(error);
			toast(error.message || " Error occured");
		}
	}
}

customElements.define("report-pepdata-view", ReportPEPDataView);
