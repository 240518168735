import { RouteOptions } from "runway";
import { TransactionView } from "./transaction-view.js";
import { TransactionListView } from "./transaction-list-view.js";
import { DividendListView } from "./dividend-list-view.js";

export default {
	path: "transactions",
	component: TransactionView,
	children: [
		{
			path: "",
			component: TransactionListView,
		},
		{
			path: "dividends",
			component: DividendListView,
		},
	],
} satisfies RouteOptions;
