import { LitElement, css, html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as inputStyles } from "../../styles/input.js";
import { date } from "../../formatting/dateformats.js";
import { money, quantity } from "../../formatting/numberformats.js";
import { addDays } from "../../utils.js";
import { get } from "../../api/client.js";
import { DateInputElement } from "../../components/fm-date-input.js";
import { TextInputElement } from "../../components/fm-text-input.js";

type Dividend = {
	txt_exdate: unknown;
	security_name: unknown;
	custody_owner: unknown;
	trantype: unknown;
	position: number;
	gross: number;
	tax: number;
	net: number;
	reinvest: number;
	reinvest_price: number;
};

@customElement("dividend-list-view")
export class DividendListView extends LitElement {
	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css`
			:host {
				display: block;
				margin: 24px auto;
				max-width: 1600px;
			}

			.card-header {
				justify-content: flex-start;
				gap: 16px;
			}

			.center-padded {
				width: 100%;
				padding: 32px 0;
				display: flex;
				justify-content: center;
			}

			th {
				cursor: pointer;
			}

			.refresh-button {
				align-self: flex-end;
			}
    `,
	];

	@state()
	private rows: Dividend[] = [];

	private sortedKey: keyof Dividend | null = null;

	@state()
	private loading = true;

	private readonly defaultValues = {
		fromDate: date(addDays(new Date(), -5)),
		toDate: date(new Date()),
	};

	async connectedCallback(): Promise<void> {
		super.connectedCallback();
		this.loading = true;
		await this.fetch({
			fromDate: this.defaultValues.fromDate,
			toDate: this.defaultValues.toDate,
			query: "",
		});
		this.loading = false;
	}

	render() {
		return html`
			<div class="card">
				<div class="card-header">
				<h1>Fund Dividends</h1>
				</div>
				<div class="card-header">
					<fm-form-v2 id="filter-form" @submit="${this.onSubmit}">
					<fm-form-field label="From">
						<fm-date-input
							id="from"
							name="from-date"
							.defaultValue="${this.defaultValues.fromDate}"></fm-date-input>
					</fm-form-field>
					<fm-form-field label="To">
						<fm-date-input
							id="to"
							name="to-date"
							.defaultValue="${this.defaultValues.toDate}"></fm-date-input>
					</fm-form-field>
					<fm-form-field label="Search">
						<fm-text-input
							id="search"
							name="search"></fm-text-input>
					</fm-form-field>
					<fm-button-v2 type="submit" class="refresh-button">Refresh</fm-button-v2>
				</fm-form-v2>
				</div>
				<table>
					<thead>
						<tr>
							<th @click="${() => this.sortBy("txt_exdate")}">Exdate</th>
							<th @click="${() => this.sortBy("security_name")}">Fund</th>
							<th @click="${() => this.sortBy("custody_owner")}">Owner</th>
							<th @click="${() => this.sortBy("trantype")}">Trantype</th>
							<th @click="${() => this.sortBy("position")}" class="numeric">Position</th>
							<th @click="${() => this.sortBy("gross")}" class="numeric">Gross Dividend</th>
							<th @click="${() => this.sortBy("tax")}" class="numeric">Tax</th>
							<th @click="${() => this.sortBy("net")}" class="numeric">Net Dividend</th>
							<th @click="${() => this.sortBy("reinvest")}" class="numeric">Reinvest</th>
							<th @click="${() => this.sortBy("reinvest_price")}">Reinv.price</th>
						</tr>
					</thead>
					<tbody>
						${this.renderTableContent()}
					</tbody>
				</table>
			</div>
		`;
	}

	private renderTableContent() {
		if (this.loading) {
			return html`
				<tr>
					<td colspan="10">
						<div class="center-padded">
							<fm-progress-dots></fm-progress-dots>
						</div>
					</td>
				</tr>
			`;
		} else if (this.rows.length === 0) {
			return html`
				<tr>
					<td colspan="10">
						<div class="center-padded">
							No results.
						</div>
					</td>
				</tr>
			`;
		} else {
			return this.rows.map(
				(row) => html`
				<tr>
					<td>${row.txt_exdate}</td>
					<td>${row.security_name}</td>
					<td>${row.custody_owner}</td>
					<td>${row.trantype}</td>
					<td class="numeric">${quantity(row.position)}</td>
					<td class="numeric">${money(row.gross)}</td>
					<td class="numeric">${money(row.tax)}</td>
					<td class="numeric">${money(row.net)}</td>
					<td class="numeric">${quantity(row.reinvest)}</td>
					<td class="numeric">${quantity(row.reinvest_price)}</td>
				</tr>
			`,
			);
		}
	}

	private findFromDateInput(): DateInputElement | null {
		return this.shadowRoot?.querySelector("#from") ?? null;
	}

	private findToDateInput(): DateInputElement | null {
		return this.shadowRoot?.querySelector("#to") ?? null;
	}

	private findSearchInput(): TextInputElement | null {
		return this.shadowRoot?.querySelector("#search") ?? null;
	}

	private async onSubmit(_event: Event) {
		const fromDate = this.findFromDateInput()?.value ?? "";
		const toDate = this.findToDateInput()?.value ?? "";
		const query = this.findSearchInput()?.value ?? "";

		this.loading = true;
		await this.fetch({
			fromDate,
			toDate,
			query,
		});
		this.loading = false;
	}

	private sortBy(key: keyof Dividend): void {
		if (key === this.sortedKey) {
			this.rows = this.rows.slice(0).reverse();
			return;
		}

		this.sortedKey = key;
		this.rows = this.rows.slice(0).sort((a, b) => {
			const x = a[key];
			const y = b[key];
			if (typeof x === "string" && typeof y === "string") {
				return x.localeCompare(y);
			} else if (typeof x === "number" && typeof y === "number") {
				return x - y;
			} else {
				return 0;
			}
		});
	}

	async fetch(values: { fromDate: string; toDate: string; query: string }) {
		const result = await get<{ data: Dividend[] }>(
			`/transactions/dividend?fromdate=${values.fromDate}&todate=${values.toDate}&query=${values.query}`,
		);

		if (result.ok === false) {
			return;
		}

		this.rows = result.value.data;
	}
}
