import TaxFormView from "./taxform-view.js";
import TaxFormListView from "./taxform-list-view.js";
import { RouteOptions } from "runway";

export default {
	path: "taxform",
	component: TaxFormView,
	children: [
		{
			path: "",
			component: TaxFormListView,
		},
	],
} satisfies RouteOptions;
