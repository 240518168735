import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { toast } from "../../utils.js";

export default class QuestionListView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			row: {
				type: Object,
			},
			question_id: {
				type: String,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.query = "";
		this.refresh = this.refresh.bind(this);
		this.editQuestion = this.editQuestion.bind(this);
		this.toggleDoc = this.toggleDoc.bind(this);
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1600px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>Questions for users</h1>
          <router-link class="btn btn--light" to="/questions/new">
            <a href="/questions/new">New</a>
         </router-link>
        </div>
        <fm-form id = "parms" class="form-grid" @submit="${this.refresh}">
          <div class="form-field">
            <label>Search
              <input type="search"  name="query" value="${this.query || ""}" class="search-input" style="width:100%">
            </label>
          </div>
          <fm-button type="submit" id="submit_button" class="btn" style="max-width:80px">Refresh</fm-button>
        </fm-form>
        ${this.renderEditList()}

      </div>
    `;
	}

	renderEditList() {
		if (this.question_id) {
			return html`<question-edit-view .question_id="${this.question_id}"></question-edit-view>`;
		} else {
			return html`
    <table>
    <thead>
      <tr>
      <th>Edit</th>
       <th>Code</th>
       <th class="numeric">Sortnumber</th>
       <th>Question</th>
       <th>Parms</th>
       <th>Required</th>
       <th>Active</th>
      </tr>
    </thead>
    <tbody>
      ${this.rows.map(
				(row) => html`
        <tr>
        <td><fm-button type="button" data-id="${row.id}" @click="${
					this.editQuestion
				}">Edit</fm-button></td>
          <td>${row.code}</td>
          <td class="numeric">${row.sortnumber}</td>
          <td>${row.question}</td>
          <td>${row.parms}</td>
          <td>${row.required_yn}</td>
          <td>${row.active_yn}</td>
        </tr>
        <tr>
          <td>
          </td>
          <td colspan="3">
            <table>
              <tr>
                <th>Code</th>
                <th>Answer</th>
                <th>Other_yn</th>
                <th>Documentation</th>
             </tr>
            ${(row.answers || []).map(
							(a) => html`
                <tr>
                <td>${a.code}</td>
                <td>${a.answer}</td>
                <td>${a.other_yn}</td>
                <td><input type="checkbox"  
                        @change="${this.toggleDoc}" 
                        ?checked="${a.requiredocumentation}" 
                        data-id="${a.id}" 
                        data-qid="${row.id}" >
                </td>
                </tr>
              `,
						)}
            </table>
          </td>
        </tr>
      `,
			)}
    </tbody>
  </table>`;
		}
	}

	async refresh() {
		this.question_id = null;
		const parms = this.shadowRoot.querySelector("#parms").value;
		const submit_button = this.shadowRoot.querySelector("#submit_button");
		submit_button.loading = true;
		localStorage.setItem("questions-query", parms.query);
		this.query = parms.query;
		try {
			this.rows = await api.get(`/questions?query=${this.query}`);
		} catch (err) {
			Sentry.captureException(err);
			const r = await err.json();
			toast(JSON.stringify(r));
		}
		submit_button.loading = false;
	}

	async toggleDoc(event) {
		const target = event.target;
		const id = target.getAttribute("data-id");
		const qid = target.getAttribute("data-qid");
		try {
			const data = { requiredocumentation: target.checked };
			const response = await api.put(
				`/questions/${qid}/answers/${id}/doc`,
				data,
			);
			toast("OK");
		} catch (err) {
			Sentry.captureException(err);
			const e = await err.json();
			toast(JSON.stringify(e));
		}
	}

	async editQuestion(event) {
		const target = event.target;
		const id = target.getAttribute("data-id");
		this.question_id = id;
	}
}

customElements.define("question-list-view", QuestionListView);
