import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import inputStyles from "../../styles/input.js";
import sharedStyles from "../../styles/shared.js";
import { toast } from "../../utils.js";
import router from "../../router/index.js";

export default class FormcalcNewView extends LitElement {
	static get properties() {
		return {
			row: {
				type: Object,
			},
			categories: {
				type: Array,
			},
		};
	}

	constructor() {
		super();
		this.row = {};
		this.categories = [];
		this.save = this.save.bind(this);
	}

	async connectedCallback() {
		super.connectedCallback();
		this.categories = await api.get("/lov/formcategories");
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 12px auto;
        max-width: 1400px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>Form calculation</h1>
        </div>
        <div class="card-block">
         ${this.renderCalcForm()}
        </div>
      </div>
    `;
	}

	renderCalcForm() {
		return html`
  <fm-form id="form" class="form-grid"  @submit="${this.save}">
    <label class="form-field">
    Code
     <input type="text" name="code" value="${this.row.code || ""}">
    </label>
    <label class="form-field">
      Name
      <input type="text" name="name" value="${this.row.name || ""}">
    </label>
    <label class="form-field">
      <select name="category_id">
        ${this.categories.map(
					(r) => html`<option value="${r.id}">${r.name}</option>`,
				)}
      </select>
    </label>
    <button type="submit" id="submit_button" class="btn btn--light">Save</button>
  </fm-form>
`;
	}

	async save() {
		const but = this.shadowRoot.querySelector("#submit_button");
		but.loading = true;
		const data = this.shadowRoot.querySelector("#form").value;
		try {
			const response = await api.post("/forms", data);
			if (response.message) {
				toast(response.message);
			} else {
				toast("Created");
				router.push("/formcalc");
			}
		} catch (err) {
			Sentry.captureException(err);
			toast("Failed");
		}
		but.loading = false;
	}
}

customElements.define("formcalc-new-view", FormcalcNewView);
