import { ShareholdersView } from "./shareholders-view.js";
import { ShareholderListView } from "./shareholder-list-view.js";
import { ShPositionListView } from "./shareholder-position-list-view.js";
import { CustodianListView } from "./custodian-list-view.js";
import { TransactionListView } from "./transaction-list-view.js";

export default {
	path: "shareholders",
	component: ShareholdersView,
	children: [
		{
			path: "",
			component: ShareholderListView,
		},
		{
			path: "list",
			component: ShareholderListView,
		},
		{
			path: "positions",
			component: ShPositionListView,
		},

		{
			path: "custodians",
			component: CustodianListView,
		},
		{
			path: "transactions",
			component: TransactionListView,
		},
	],
};
