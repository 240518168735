import { LitElement, html } from "lit";

import sharedStyles from "../../styles/shared.js";
import tableStyles from "../../styles/tables.js";

export default class AMLView extends LitElement {
	render() {
		return html`
    ${sharedStyles}
    ${tableStyles}
      <header class="horizontal-menu-header">
      <router-link to="/aml/countries">
          <a>Countries</a>
        </router-link>
        <router-link to="/aml/sectors">
          <a>Sectors</a>
        </router-link>
        <router-link to="/aml/investorcountries">
          <a>Investor Countries</a>
        </router-link>
        <router-link to="/aml/investorsectors">
          <a>Investor sectors</a>
        </router-link>
        <router-link to="/aml/realowners">
          <a>Real owners</a>
        </router-link>
        <router-link to="/aml/uquestions">
          <a>Unanswered questions</a>
        </router-link>
        <router-link to="/aml/sources">
          <a>Payment sources</a>
        </router-link>
        <router-link to="/aml/deactivate">
          <a>Deactivate accounts</a>
        </router-link>
        <router-link to="/aml/orderflow">
          <a>Order flow</a>
        </router-link>
        <router-link to="/aml/riskinflow">
          <a>Risk Inflow</a>
        </router-link>
        <router-link to="/aml/riskoutflow">
          <a>Risk Outflow</a>
        </router-link>
        <router-link to="/aml/answers">
          <a>Answers</a>
        </router-link>
        <router-link to="/aml/jobs">
          <a>Monitor</a>
        </router-link>
      </header>
      <slot></slot>
    `;
	}
}

customElements.define("aml-view", AMLView);
