import { LitElement, css, html } from "lit";
import { customElement, state } from "lit/decorators.js";
import "../../components/fm-autocomplete-v2.js";
import "../../components/fm-button-v2.js";
import "../../components/fm-text-input.js";
import "../../components/fm-card.js";
import { get, post } from "../../api/client.js";
import {
	AutocompleteElementV2,
	AutocompleteOption,
} from "../../components/fm-autocomplete-v2.js";
import { TextInputElement } from "../../components/fm-text-input.js";
import { toast } from "../../utils.js";
import { ButtonElementV2 } from "../../components/fm-button-v2.js";
import { styles as minimal } from "../../styles/minimal.js";

type User = {
	id: number;
	name: string;
};

@customElement("thread-new-view")
export class ThreadNewView extends LitElement {
	static styles = [
		minimal,
		css`
			:host {
				display: block;
				margin: 24px auto;
				max-width: 1000px;
			}

			.thread-compose-subject {
				display: flex;
				flex-direction: column;
				padding: 16px;
				gap: 16px;
			}

			#receiver-bulk-input {
				padding: 8px;
				resize: vertical;
				border: 1px solid #ddd;
			}

			.receivers {
				display: flex;
				flex-direction: column;
				margin: 8px 0;
				border: 1px dashed #ddd;
			}

			.receiver {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin: 8px;
			}

			.errors {
				border: 1px solid #970000;
				background: #ff000021;
				padding: 8px;
			}

			.error {
				color: #970000;
			}

			a {
				font-size: 14px;
				font-weight: 500;
				text-transform: uppercase;
				padding: 8px 16px;
				transition: background-color 0.2s ease;
			}

			a:hover, a:focus {
				background-color: #eee;
			}

			.thread-compose {
				border-top: 1px solid #ddd;
				display: flex;
				flex-wrap: wrap;
			}

			.thread-compose-input {
				padding: 8px;
				border: 1px solid #ddd;
				width: 100%;
				height: 120px;
				resize: vertical;
				background: #eee;
				font-family: var(--primary-font);
			}

			.thread-compose-bar {
				display: flex;
				width: 100%;
				padding: 16px;
				gap: 16px;
			}

			.thread-compose-actions {
				display: flex;
				flex-direction: column;
				justify-content: center;
				gap: 8px;
			}
    	`,
	];

	@state()
	private users: User[] = [];

	private debounceTimeout: number | undefined = undefined;

	@state()
	private autocompleteLoading: boolean = false;

	@state()
	private receivers: User[] = [];

	@state()
	private bulkMode: boolean = false;

	@state()
	private errors: string[] = [];

	constructor() {
		super();
		this.onInputAutocomplete = this.onInputAutocomplete.bind(this);
		this.onClickRemove = this.onClickRemove.bind(this);
		this.onSelectAutocomplete = this.onSelectAutocomplete.bind(this);
		this.onSubmitMessage = this.onSubmitMessage.bind(this);
		this.onClickToggleBulkMode = this.onClickToggleBulkMode.bind(this);
		this.onClickLoadUsers = this.onClickLoadUsers.bind(this);
	}

	render() {
		return html`
			<fm-card>
				<h1 slot="card-header">New message</h1>
				<fm-button-v2
					slot="card-header"
					light
					@click="${this.onClickToggleBulkMode}"
				>
					${this.bulkMode ? "Autocomplete Mode" : "Bulk Mode"}
				</fm-button-v2>
				<div class="thread-compose-subject">
					<fm-form-field label="Subject" class="subject">
						<fm-text-input
							id="subject"
							name="subject"></fm-text-input>
					</fm-form-field>
					${this.renderReceivers()}
				</div>
				<div class="thread-compose">
					<div class="thread-compose-bar">
						<textarea class="thread-compose-input"></textarea>
						<div class="thread-compose-actions">
							<fm-button-v2 @click="${this.onSubmitMessage}">Send</fm-button-v2>
						</div>
					</div>
				</div>
			</fm-card>
		`;
	}

	renderReceivers() {
		return html`
			${this.renderReceiverInput()}
			${this.receivers.length > 0 ? this.renderReceiverList() : null}
			${this.errors.length > 0 ? this.renderErrors() : null}
		`;
	}

	renderReceiverInput() {
		if (this.bulkMode) {
			return html`
				<fm-form-field label="Receivers" class="add-receiver">
					<textarea id="receiver-bulk-input"></textarea>
				</fm-form-field>
				<fm-button-v2 light @click="${this.onClickLoadUsers}">
					Load Users
				</fm-button-v2>
			`;
		}

		return html`
			<fm-form-field label="Receivers" class="add-receiver">
				<fm-autocomplete-v2
					id="user_id"
					name="user_id"
					@input="${this.onInputAutocomplete}"
					@select="${this.onSelectAutocomplete}"
					.options="${this.users.slice(0, 10).map((user) => ({
						id: String(user.id),
						name: user.name,
					}))}"
					.loading="${this.autocompleteLoading}"
				></fm-autocomplete-v2>
			</fm-form-field>
		`;
	}

	renderReceiverList() {
		return html`
			<div class="receivers">
				${this.receivers.map(
					(receiver) => html`
					<div class="receiver">
						${receiver.name}
						<fm-button-v2
							light
							data-id="${receiver.id}"
							@click="${this.onClickRemove}"
						>
							Fjern
						</fm-button-v2>
					</div>
				`,
				)}
			</div>
		`;
	}

	renderErrors() {
		return html`
			<div class="errors">
				${this.errors.map(
					(error) => html`
					<div class="error">
						${error}
					</div>
				`,
				)}
			</div>
		`;
	}

	onClickToggleBulkMode(_event: MouseEvent) {
		this.bulkMode = !this.bulkMode;
		this.errors = [];
	}

	onClickLoadUsers(_event: MouseEvent) {
		const bulkInput: HTMLTextAreaElement | null =
			this.shadowRoot?.querySelector("#receiver-bulk-input") ?? null;
		if (bulkInput === null) {
			console.error("`bulkInput` is null");
			return;
		}

		const userIds = bulkInput.value
			.split(",")
			.map((v) => v.trim())
			.filter((v) => v !== "")
			.map((v) => Number(v));

		this.loadUsers(userIds);
	}

	async loadUsers(userIds: number[]) {
		this.errors = [];

		const response = await get<{ data: User[] }>("/lov/users2?query=");
		if (response.ok === false) {
			console.error(`Error when fetching users: ${response.error}`);
			return;
		}

		const selectedUsers = response.value.data.filter((user) =>
			userIds.includes(user.id),
		);

		const notFoundUserIds = userIds.filter(
			(id) => !selectedUsers.some((user) => user.id === id),
		);

		if (notFoundUserIds.length > 0) {
			this.errors = [
				...this.errors,
				`Could not find the following users: ${notFoundUserIds.join(", ")}`,
			];
		}

		this.receivers = selectedUsers;
	}

	onClickRemove(event: MouseEvent) {
		const target = event.target;
		if (target === null || !(target instanceof ButtonElementV2)) {
			console.error("`event.target` is null or not an autocomplete element");
			return;
		}

		const id = target.getAttribute("data-id");
		if (id === null) {
			console.error("`id` is null");
			return;
		}

		this.receivers = this.receivers.filter(
			(receiver) => String(receiver.id) !== id,
		);
	}

	onInputAutocomplete(event: Event) {
		const target = event.target;
		if (target === null || !(target instanceof AutocompleteElementV2)) {
			console.error("`event.target` is null or not an autocomplete element");
			return;
		}

		const value = target.input?.value;
		this.fetchAutocompleteSuggestions(value ?? "");
	}

	fetchAutocompleteSuggestions(value: string) {
		this.autocompleteLoading = true;
		clearTimeout(this.debounceTimeout);
		this.debounceTimeout = window.setTimeout(async () => {
			const response = await get<{ data: User[] }>(
				`/lov/users2?query=${value}`,
			);
			if (response.ok === false) {
				console.error(`Error when fetching users: ${response.error}`);
				return;
			}

			this.users = response.value.data;
			this.autocompleteLoading = false;
		}, 400);
	}

	onSelectAutocomplete(event: CustomEvent<AutocompleteOption>) {
		const receiver = this.users.find(
			(user) => String(user.id) === event.detail.id,
		);
		if (receiver === undefined) {
			console.error("could not find `receiver`");
			return;
		}

		if (this.receivers.some((user) => user.id === receiver.id)) {
			toast("User already selected");
			return;
		}

		this.receivers = [...this.receivers, receiver];
	}

	onSubmitMessage(_event: MouseEvent) {
		const threadSubjectInput: TextInputElement | null =
			this.shadowRoot?.querySelector("#subject") ?? null;
		if (threadSubjectInput === null) {
			console.error("Failed to find `#subject`");
			return;
		}

		const subject = threadSubjectInput.value;
		if (subject === null) {
			console.error("`subject` is null");
			return;
		}

		const threadComposeInput: HTMLTextAreaElement | null =
			this.shadowRoot?.querySelector(".thread-compose-input") ?? null;
		if (threadComposeInput === null) {
			console.error("Failed to find `.thread-compose-input`");
			return;
		}

		const message = threadComposeInput.value;

		this.createThread(this.receivers, subject, message);
	}

	async createThread(receivers: User[], subject: string, message: string) {
		this.errors = [];

		if (receivers.length === 1) {
			const response = await post("/mails", {
				user_id: receivers[0].id,
				subject,
				message,
			});

			if (response.ok === false) {
				this.errors = [
					...this.errors,
					`Failed to send message to ${receivers[0].name} (ID: ${receivers[0].id})`,
				];
			} else {
				toast("Message sent.");
			}
		} else {
			const responses = await Promise.all(
				receivers.map(async (receiver) => {
					return [
						receiver,
						await post("/mails/broadcast", {
							user_id: receiver.id,
							subject,
							message,
						}),
					] as const;
				}),
			);

			for (const [receiver, response] of responses) {
				if (response.ok === false) {
					this.errors = [
						...this.errors,
						`Failed to send message to ${receiver.name} (ID: ${receiver.id})`,
					];
				}
			}

			const succeeded = responses.filter(([_, response]) => response.ok).length;
			const failed = responses.filter(([_, response]) => !response.ok).length;
			toast(`Messages sent (delivered: ${succeeded}, failed: ${failed})`);
		}
	}
}
