import { LitElement, PropertyValueMap, css, html } from "lit";
import { customElement, property } from "lit/decorators.js";

type TextInputType = "text" | "password";

declare global {
	interface HTMLElementTagNameMap {
		"fm-text-input": TextInputElement;
	}
}

@customElement("fm-text-input")
export class TextInputElement extends LitElement {
	static styles = css`
        :host {
			box-sizing: border-box;
			position: relative;
            display: flex;
            flex-direction: column;
        }

        input {
			font-family: var(--primary-font);
            font-size: 16px;
            border: 1px solid #ddd;
            padding: 8px;
        }

		:host([invalid]) input {
			border-color: #970000;
			border-width: 2px;
		}

        .error {
			display: none;
			position: absolute;
			top: 100%;
			box-sizing: border-box;
			width: 100%;
			padding: 8px;
			margin-top: 4px;
			font-size: 14px;
			font-weight: initial;
            color: #221B1B;
			background-color: #ECECEC;
			border: 1px solid #9F9F9F;
			border-radius: 2px;
			box-shadow:
				0 4px 5px 0 rgba(0, 0, 0, 0.14),
                0 1px 10px 0 rgba(0, 0, 0, 0.12),
                0 2px 4px -1px rgba(0, 0, 0, 0.4);
        }

		:host([invalid]) .error {
			display: flex;
		}

		:host([disabled]) input {
			background: #f0f0f0;
			color: #333;
		}

    `;

	@property({ reflect: true })
	type: TextInputType = "text";

	@property()
	defaultValue = "";

	@property()
	placeholder = "";

	@property()
	name = "";

	@property()
	error = "";

	@property({ type: Boolean, reflect: true })
	disabled = false;

	@property({ type: Boolean, reflect: true })
	invalid = false;

	@property({ type: Boolean, reflect: true })
	autofocus = false;

	private get input(): HTMLInputElement | null {
		return this.shadowRoot?.querySelector("input") ?? null;
	}

	set value(v: string) {
		const input = this.input;
		if (!input) {
			return;
		}

		input.value = v;
	}

	get value(): string | null {
		return this.input?.value ?? null;
	}

	render() {
		return html`
            <input
				autocomplete="off"
				type="${this.type}"
                name="${this.name}"
                placeholder="${this.placeholder}"
				?disabled="${this.disabled}"
				?autofocus="${this.autofocus}"
                .defaultValue="${this.defaultValue}"
				@keypress="${this.onKeypress}"
            />
            <span class="error">${this.error}</span>
        `;
	}

	protected willUpdate(_changedProperties: PropertyValueMap<this>): void {
		this.invalid = this.error !== "";
	}

	private onKeypress(event: KeyboardEvent) {
		if (event.key === "Enter" && !event.shiftKey && !event.altKey) {
			this.dispatchEvent(new CustomEvent("submit"));
		}
	}

	focus() {
		if (this.disabled) {
			return;
		}

		this.input?.focus();
	}

	blur() {
		if (this.disabled) {
			return;
		}

		this.input?.blur();
	}
}
