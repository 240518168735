import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../api/index.js";
import tableStyles from "../styles/tables.js";
import inputStyles from "../styles/input.js";
import sharedStyles from "../styles/shared.js";
import { toast } from "../utils.js";

export default class RedemptionEditView extends LitElement {
	static get properties() {
		return {
			redemption_id: {
				type: String,
			},
			row: {
				type: Object,
			},
			events: {
				type: Array,
			},
		};
	}

	constructor() {
		super();
		this.row = {};
		this.events = [];
		this.methods = [];
		this.eventtypes = [];
		this.subtrantypes = [];
		this.submitRedemption = this.submitRedemption.bind(this);
	}

	async connectedCallback() {
		super.connectedCallback();
		this.methods = await api.get("/lov/redemptionmethods");
		this.eventtypes = await api.get("/lov/reventtypes");
		this.subtrantypes = await api.get("/lov/subtrantypes");
		if (this.redemption_id) {
			this.row = await api.get(`/advisors/redemptions/${this.redemption_id}`);
			this.events = await api.get(
				`/advisors/redemptions/${this.redemption_id}/actions`,
			);
		} else {
			this.requestUpdate();
		}
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <fm-form id="redemption" class="form-grid" @submit="${
				this.submitRedemption
			}">
        <input type="hidden" name="redemption_id" value="${
					this.redemption_id || ""
				}">
        <div class="form-field">
          <label>Custody
            <fm-autocomplete 
              url="/lov/custodies" 
              name="custody_id" 
              display="name" 
              .rawValue="${{
								id: this.row.custody_id || "",
								name: this.row.custody_code || "",
							}}" 
              select="id"
              required
              >
            </fm-autocomplete>
         </label>
        </div>
        <div class="form-field">
          <label>Method
            <fm-autocomplete 
              url="/lov/redemptionmethods" 
              name="method_id" 
              display="description" 
              .rawValue="${{
								id: this.row.method_id || "",
								description: this.row.method_description || "",
							}}" 
              select="id"
              required
              >
            </fm-autocomplete>
          </label>
        </div>
        <div class="form-field">
          <label>Amount
            <input type="number"  name="amount" required value="${
							this.row.amount
						}">
          </label>
       </div>
      <div class="form-field"></div>
        <div class="form-field">
          <label>First date
            <input type="date" value="${this.row.txt_firstdate || ""}" required name="firstdate" >
          </label>
       </div>
       <div class="form-field">
         <label>Last date
           <input type="date" value="${this.row.txt_lastdate || ""}" name="lastdate" >
         </label>
       </div>
       <div class="form-field">
        <label>Value from
           <input type="number"  name="valuefrom"  value="${
							this.row.valuefrom || ""
						}">
        </label>
       </div>
       <div class="form-field">
        <label>Value to
          <input type="number"  name="valueto"  value="${this.row.valueto || ""}">
        </label>
       </div>
      <div class="form-field">
         <label>Frequency months
            <input type="number" value="${this.row.frequency_months || ""}" required name="frequency_months" >
          </label>
      </div>
      <div class="form-field">
          <label>Bankdays from month-start (negative if month-end)
            <input type="number" value="${this.row.frequency_days || ""}" required name="frequency_days" >
          </label>
      </div>
     <div class="form-field">
      <label>Bank regno (pay to) (blank => NEM-KONTO)
        <input type="text" name="bank_reg" value="${this.row.bank_reg || ""}">
      </label>
     </div>
     <div class="form-field">
      <label>Bank regno (pay to) (blank => NEM-KONTO)
        <input type="text" name="bank_account" value="${
					this.row.bank_account || ""
				}">
      </label>
     </div>
      <fm-button type="submit" id="redemption_button">Save</fm-button>
      </fm-form>
     ${this.renderEvents()}`;
	}

	renderEvents() {
		return html`
   <div class="card">
    <div class="card-header">
    <h2>Events</h2>
    </div>
    <fm-form id="eventform" class="form-grid" @submit="${this.eventSave}">
    <div class="form-field">
      <label>Type
      <select name="type_id">
       ${this.eventtypes.map(
					(t) => html`<option value="${t.id}">${t.description}</option>`,
				)}
      </select>
     </label>
     </div>
     <div class="form-field">
    <label>Comment
     <input type="text" name="admin_comment">
  </label>
  </div>
  <div class="form-field"></div>
      <fm-button  type="submit" >Post event</fm-button>
  
 </fm-form>
 <table>
    <thead>
      <tr>
       <th>Reg.date</th>
       <th>Description</th>
       <th>Comment</th>
       <th>Reg.User</th>
      </tr>
    </thead>
    <tbody>
        ${(this.events || []).map((e) => this.renderEvent(e))}
    </tbody>
 </table> 
 </div> `;
	}

	renderEvent(e) {
		return html`
    <tr>
      <td>${e.txt_reg_date}</td>
      <td>${e.description}</td>
      <td>${e.admin_comment}</td>
      <td>${e.reg_user}</td>
    </tr>
`;
	}

	async submitRedemption() {
		const button = this.shadowRoot.querySelector("#redemption_button");
		button.loading = true;
		const data = this.shadowRoot.querySelector("#redemption").value;

		try {
			if (!isNaN(data.redemption_id) && data.redemption_id !== "") {
				await api.put(`/advisors/redemptions/${data.redemption_id}`, data);
				toast("Saved");
			} else {
				const redemption = await api.post("/advisors/redemptions", data);
				toast("Saved");
				this.redemption_id = redemption.id;
			}
		} catch (err) {
			Sentry.captureException(err);
			console.log(err);
			toast(JSON.stringify(err));
		}
		button.loading = false;
	}

	async eventSave() {
		const data = this.shadowRoot.querySelector("#eventform").value;
		try {
			const response = await api.post(
				`/advisors/redemptions/${this.redemption_id}/actions`,
				data,
			);
			this.events = await api.get(
				`/advisors/redemptions/${this.redemption_id}/actions`,
			);
		} catch (err) {
			Sentry.captureException(err);
			toast(JSON.stringify(err));
		}
	}
}

customElements.define("redemption-edit-view", RedemptionEditView);
