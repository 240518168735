import { LitElement, html, css } from "lit-element";
import { customElement, state } from "lit/decorators.js";

import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";
import { styles as inputStyles } from "../../styles/input.js";
import * as Sentry from "@sentry/browser";

import { get } from "../../api/client.js";
import { date } from "../../formatting/dateformats.js";
import { toCsv } from "../../utils.js";

declare global {
	interface HTMLElementTagNameMap {
		"shareholder-list-view": ShareholderListView;
	}
}

type Shareholder = {
	id: number;
	externalid: string;
	name1: string;
	name2: string;
	name3: string;
	name4: string;
	name5: string;
	zipcode: string;
	city: string;
	country: string;
	reg_date: string;
	clean_name: string;
	clean_co: string;
	clean_address1: string;
	clean_address2: string;
	clean_zipcode: string;
	clean_city: string;
	clean_country: string;
	clean_cvr: string;
	email: string;
	q: string;
	positions: Position[];
};

type Position = {
	custodynumber: string;
	custodian: string;
	security: string;
	position: number;
};

@customElement("shareholder-list-view")
export class ShareholderListView extends LitElement {
	@state()
	private shareholders: Shareholder[] = [];

	private sortedKey: keyof Shareholder | null = null;

	@state()
	private externalid: string | null = null;

	@state()
	private asofdate: string = date(new Date());

	@state()
	private query: string = "";

	@state()
	loading: boolean = false;

	static styles = [
		sharedStyles,
		tableStyles,
		inputStyles,
		css` :host {
			display: block;
			margin: 24px auto;
			max-width: 1400px;
		  }
		  th { cursor: pointer; } 
		  tbody.investor td.secondary {display:none}
		  tbody.investor:hover td.secondary {display:table-cell}
		  td.secondary {background:grey}
		  td.link {cursor:pointer;color:blue;}
	  `,
	];

	render() {
		return html`<div class="card">
        <div class="card-header">
          <h1>Shareholders</h1>
        </div>
        <fm-form id="parms" class="reportform no-print" @submit="${this.refresh}">
          <div class="form-space">
            <label>Asof date</label>
            <input type="date"  class="date-input" name="asofdate" value="${
							this.asofdate
						}" >
          </div>
          <div class="form-space">
            <label>Search</label>
              <input type="search" class="search-input" name="query" placeholder="Search" .value=${
								this.query
							}
		  @change=${(e) => {
				this.query = e.target.value;
			}} >
          </div>
          <div class="form-space">
            <fm-button-v2 id="submit_button" class="button submit" @click="${
							this.refresh
						}" .loading="${this.loading}">Refresh</fm-button-v2>
            </div>
          <div class="form-space">
            <fm-button id="download_button" class="button" @click="${
							this.download
						}">Download</fm-button>
          </div>
        </fm-form>
        <table>
          <thead>
            <tr>
             <th @click="${() => this.sortBy("externalid")}"
			  >Id</th>
             <th @click="${() => this.sortBy("clean_name")}"
			  >Name</th>
              <th @click="${() => this.sortBy("clean_address1")}">Address 1</th>
              <th @click="${() => this.sortBy("clean_address2")}"
			   >Address 2</th>
              <th @click="${() => this.sortBy("clean_zipcode")}">Zip Code</th>
              <th @click="${() => this.sortBy("clean_city")}">City</th>
              <th @click="${() => this.sortBy("email")}">E-mail</th>
            </tr>
          </thead>
            ${this.shareholders.map(
							(shareholder) => html`
            <tbody class="investor">
              <tr>
                  <td class="link numeric" data-id="${
										shareholder.externalid
									}" @click="${this.edit}">
                  ${shareholder.externalid}
                  </td>
                <td>${shareholder.clean_name}</td>
                <td>${shareholder.clean_address1}</td>
                <td>${shareholder.clean_address2}</td>
                <td>${shareholder.clean_zipcode}</td>
                <td>${shareholder.clean_city}</td>
                <td>${shareholder.email}</td>
              </tr>
              <tr>
              <td class="numeric secondary">Original</td>
              <td colspan="3" class="secondary">${shareholder.name1}/${
								shareholder.name2
							}/${shareholder.name3}/${shareholder.name4}/${shareholder.name5}</td>
              <td class="secondary">${shareholder.zipcode}</td>
              <td class="secondary">${shareholder.city}</td>
              <td class="secondary"></td>
              </tr>
              ${this.renderForm(shareholder)}
						</tbody>
            `,
						)}
        </table>
      </div>
    `;
	}

	renderForm(shareholder) {
		if (shareholder.externalid === this.externalid) {
			return html`
        <tr>
          <td colspan="7">
          <fm-form class="form-grid" url="/etfs/shareholders/${
						shareholder.id
					}" method="put" @error="${this.formError}" @response="${
				this.formResponse
			}">
            <label class="form-field">
              Name
              <input type="text" name="clean_name" value="${
								shareholder.clean_name || ""
							}">
            </label>
            <label class="form-field">
             Address1
             <input type="text" name="clean_address1" value="${
								shareholder.clean_address1 || ""
							}">
            </label>
            <label class="form-field">
            Address2
            <input type="text" name="clean_address2" value="${
							shareholder.clean_address2 || ""
						}">
           </label>
           <label class="form-field">
           Zip Code
           <input type="text" name="clean_zipcode" value="${
							shareholder.clean_zipcode || ""
						}">
          </label>
          <label class="form-field">
          City
          <input type="text" name="clean_city" value="${
						shareholder.clean_city || ""
					}">
         </label>

           <label class="form-field">
            E-mail
            <input type="text" name="email" value="${shareholder.email || ""}">
           </label>
          
           <table>
           <thead>
           <tr>
           <th>Security</th>
           <th>Custody Number</th>
           <th>Custodian</th>
           <th class="numeric">Quantity</th>
           </tr>
           </thead>
           ${(shareholder.positions || []).map((p) => this.renderPosition(p))}
           </table>
           <fm-button  type="submit" class="btn btn--light" >Save</fm-button>
          </fm-form>
         </td>
        </tr>`;
		} else return "";
	}

	renderPosition(p) {
		return html`
   <tr>
   <td>${p.security}</td>
   <td>${p.custodynumber}</td>
   <td>${p.custodian}</td>
   <td class="numeric">${p.position}</td>
   </tr>
   `;
	}

	async connectedCallback() {
		super.connectedCallback();

		this.loading = true;
		await this.fetch({ asofdate: this.asofdate, query: this.query });
		this.loading = false;
	}

	async fetch(values: { asofdate: string; query: string }) {
		const result = await get<{ data: Shareholder[] }>(
			`/etfs/shareholders?asof=${values.asofdate}&query=${values.query}`,
		);

		if (result.ok === false) {
			return;
		}

		this.shareholders = result.value.data;
	}

	async refresh() {
		this.loading = true;
		this.externalid = null;
		await this.fetch({ asofdate: this.asofdate, query: this.query });
		this.loading = false;
	}

	async formResponse(event) {
		await this.fetch({ asofdate: this.asofdate, query: this.query });
		this.externalid = null;
	}

	formError(event) {
		console.log("Form Error");
	}

	private sortBy(key: keyof Shareholder): void {
		if (key === this.sortedKey) {
			this.shareholders = this.shareholders.slice(0).reverse();
			return;
		}

		this.sortedKey = key;
		this.shareholders = this.shareholders.slice(0).sort((a, b) => {
			const x = a[key];
			const y = b[key];
			if (typeof x === "string" && typeof y === "string") {
				return x.localeCompare(y);
			} else if (typeof x === "number" && typeof y === "number") {
				return x - y;
			} else {
				return 0;
			}
		});
	}

	edit(event) {
		const target = event.target;
		const id = target.getAttribute("data-id");
		this.externalid = id;
	}

	async download(event) {
		try {
			const csvHeaders = [
				"externalid",
				"name1",
				"name2",
				"name3",
				"name4",
				"clean_name",
				"clean_address1",
				"clean_address2",
				"clean_zipcode",
				"clean_city",
				"clean_country",
				"email",
			];

			const csvFields = [
				"externalid",
				"name1",
				"name2",
				"name3",
				"name4",
				"clean_name",
				"clean_address1",
				"clean_address2",
				"clean_zipcode",
				"clean_city",
				"clean_country",
				"email",
			];

			let txtdata = toCsv(csvHeaders, csvFields, this.shareholders);

			try {
				txtdata = btoa(txtdata);
			} catch (err) {
				Sentry.captureException(err);
				// remove n@ascii
				txtdata = btoa(unescape(encodeURIComponent(txtdata)));
			}
			const a = document.createElement("a");
			a.setAttribute("href", `data:text/csv;base64,${txtdata}`);
			a.setAttribute("download", "fm_shareholoders.csv");
			a.click();
		} catch (err) {
			Sentry.captureException(err);
			console.log(err);
		}
	}
}
