import { LitElement, css, html } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { classMap } from "lit/directives/class-map.js";
import "./fm-text-input.js";
import { TextInputElement } from "./fm-text-input.js";

export type AutocompleteOption = {
	id: string;
	name: string;
};

declare global {
	interface HTMLElementTagNameMap {
		"fm-autocomplete-v2": AutocompleteElementV2;
	}
}

@customElement("fm-autocomplete-v2")
export class AutocompleteElementV2 extends LitElement {
	static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            position: relative;
        }

        fm-text-input {
            width: 100%;
        }

        .autocomplete-options {
            visibility: hidden;
            box-sizing: border-box;
            display: flex;
            position: absolute;
            top: calc(100% + 4px);
            width: 100%;
            flex-direction: column;
            border: 1px solid #ddd;
            box-shadow: 0px 4px 8px -1px rgba(0, 0, 0, 0.1);
            max-height: 258px;
            overflow-y: auto;
        }

        :host([open]) .autocomplete-options {
            visibility: visible;
        }

        .autocomplete-option {
            cursor: pointer;
            display: flex;
            background: white;
            border: none;
            padding: 8px;
            text-align: left;
            transition: background-color 0.1s ease;
        }

        .autocomplete-option:hover {
            background-color: #eee;
        }

        .autocomplete-option-highlighted {
            background-color: #eee;
        }

        .autocomplete-loading {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 48px;
            background: white;
        }

        .autocomplete-empty {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 48px;
            background: white;
            color: #999;
        }
    `;

	get input(): TextInputElement | null {
		return this.shadowRoot?.querySelector("fm-text-input") ?? null;
	}

	@property({ type: Boolean, reflect: true })
	open: boolean = false;

	@property({ type: Boolean, reflect: true })
	loading: boolean = false;

	@property({ attribute: false })
	options: AutocompleteOption[] = [];

	@property({ attribute: false })
	selectedItem: AutocompleteOption | null = null;

	@state()
	private highlightedIndex: number | null = null;

	constructor() {
		super();
		this.onFocusInput = this.onFocusInput.bind(this);
		this.onBlurInput = this.onBlurInput.bind(this);
		this.onKeyupInput = this.onKeyupInput.bind(this);
		this.onMousedownOption = this.onMousedownOption.bind(this);
	}

	render() {
		return html`
            <fm-text-input
                @focus="${this.onFocusInput}"
                @blur="${this.onBlurInput}"
                @keyup="${this.onKeyupInput}"
            ></fm-text-input>
            <div
                role="listbox"
                class="autocomplete-options"
            >
                ${this.renderItems()}
            </div>
        `;
	}

	private renderItems() {
		if (this.loading) {
			return html`
                <div class="autocomplete-loading">
                    <fm-progress-dots></fm-progress-dots>
                </div>
            `;
		}

		if (this.options.length === 0) {
			return html`
                <div class="autocomplete-empty">No results.</div>
            `;
		}

		return this.options.map(
			(item, index) => html`
            <button
                role="option"
                class="${classMap({
									"autocomplete-option": true,
									"autocomplete-option-highlighted":
										this.highlightedIndex === index,
								})}"
                data-id="${String(item.id)}"
                aria-selected="${this.selectedItem?.id === item.id}"
                @mousedown="${this.onMousedownOption}"
            >
                ${item.name}
            </button>
        `,
		);
	}

	private onFocusInput(_event: FocusEvent) {
		this.open = true;
	}

	private onBlurInput(_event: FocusEvent) {
		this.open = false;
	}

	private onKeyupInput(event: KeyboardEvent) {
		if (event.key === "ArrowDown") {
			this.incrementHighlightedIndex();
		} else if (event.key === "ArrowUp") {
			this.decrementHighlightedIndex();
		} else if (event.key === "Enter") {
			this.selectHighlightedIndex();
		} else if (event.key === "Escape") {
			this.input?.blur();
			this.open = false;
		}
	}

	private incrementHighlightedIndex() {
		if (this.highlightedIndex === null) {
			this.highlightedIndex = 0;
		} else {
			this.highlightedIndex = Math.min(
				this.options.length - 1,
				this.highlightedIndex + 1,
			);
		}

		this.scrollToHighlighedIndex();
	}

	private decrementHighlightedIndex() {
		if (this.highlightedIndex === null) {
			return;
		}

		if (this.highlightedIndex === 0) {
			this.highlightedIndex = null;
		} else {
			this.highlightedIndex = Math.max(0, this.highlightedIndex - 1);
		}

		this.scrollToHighlighedIndex();
	}

	private scrollToHighlighedIndex() {
		if (this.highlightedIndex === null) {
			return;
		}

		if (
			this.highlightedIndex > this.options.length - 1 ||
			this.highlightedIndex < 0
		) {
			return;
		}

		const container: HTMLElement | null =
			this.shadowRoot?.querySelector(".autocomplete-options") ?? null;
		if (container === null) {
			return;
		}

		const elements: HTMLButtonElement[] = Array.from(
			this.shadowRoot?.querySelectorAll(".autocomplete-option") ?? [],
		);

		const child = elements[this.highlightedIndex];

		const containerGeometry = container?.getBoundingClientRect();
		const childGeometry = child.getBoundingClientRect();

		if (childGeometry.bottom > containerGeometry.bottom + 10) {
			child.scrollIntoView({
				block: "end",
				inline: "nearest",
			});
		} else if (childGeometry.top < containerGeometry.top - 10) {
			child.scrollIntoView({
				block: "start",
				inline: "nearest",
			});
		}
	}

	private selectHighlightedIndex() {
		if (this.highlightedIndex === null) {
			return;
		}

		if (
			this.highlightedIndex > this.options.length - 1 ||
			this.highlightedIndex < 0
		) {
			return;
		}

		this.selectedItem = this.options[this.highlightedIndex];
		if (this.input && this.selectedItem) {
			this.input.value = this.selectedItem?.name ?? "";
		}

		this.open = false;
		this.highlightedIndex = null;
		this.dispatchEvent(
			new CustomEvent("select", {
				detail: this.selectedItem,
			}),
		);
	}

	private onMousedownOption(event: MouseEvent) {
		const target = event.target;
		if (target === null || !(target instanceof HTMLElement)) {
			console.error("`target` is null or not an element");
			return;
		}

		const id = target.getAttribute("data-id");
		if (id === null) {
			console.error("`data-id` is null");
			return;
		}

		this.selectedItem = this.options.find((item) => item.id === id) ?? null;
		if (this.input && this.selectedItem) {
			this.input.value = this.selectedItem?.name ?? "";
		}

		this.dispatchEvent(
			new CustomEvent("select", {
				detail: this.selectedItem,
			}),
		);
	}
}
