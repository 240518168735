import { LitElement, html } from "lit";

import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { DateFormatter } from "../../formatting/dateformats.js";

export default class UserRelationsView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			relationtypes: {
				type: Array,
			},
		};
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1500px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>Relations</h1>
       </div>

       <fm-form id = "parms" class="form-grid" @submit="${this.refresh}">
         <div class="form-field">
           <label>Created
             <fm-date-range id="created" name="created"  names="relations-" ></fm-date-range>
            </label>
         </div>
         <div class="form-field">
         ${this.relationtypes.map(
						(t) =>
							html`<input data-id="${t.id}" id="chk${
								t.id
							}" type="checkbox" @change="${this.chkbox}" ?checked=${
								t.selected === "Y"
							}>${t.description}`,
					)}
       </div>

         <div class="form-field">
           <label>Search1
             <input type="search"  name="query1" value="${this.query1 || ""}" class="search-input" style="width:100%">
           </label>
         </div>
         <div class="form-field">
         <label>Search2
           <input type="search"  name="query2" value="${this.query2 || ""}" class="search-input" style="width:100%">
         </label>
       </div>

         <fm-button type="submit" id="submit_button" class="btn" style="max-width:80px">Refresh</fm-button>
       </fm-form>

        <table>
          <thead>
            <tr>
              <th>Name1</th>
              <th>Relation</th>
              <th>Name2</th>
              <th>Start</th>
              <th>End</th>
              <th>Ownerrate</th>
              <th>Votingrate</th>
              <th>Comment</th>
              <th>Created</th>
            </tr>
          </thead>
          <tbody>
            ${this.rows.map(
							(row) => html`
              <tr>
                <td>${row.name1}</td>
                <td>${row.description}</td>
                <td>${row.name2}</td>
                <td>${row.startdate}</td>
                <td>${row.enddate}</td>
                <td>${row.ownerrate}</td>
                <td>${row.votingrate}</td>
                <td>${row.admin_comment}</td>
                <td>${row.txt_reg_date}</td>
              </tr>
            `,
						)}
          </tbody>
        </table>
      </div>
    `;
	}

	constructor() {
		super();
		this.rows = [];
		this.relationtypes = [];
		this.query = "";
		this.refresh = this.refresh.bind(this);
		this.chkbox = this.chkbox.bind(this);
		let todate = localStorage.getItem("relations-todate");
		const fromdate = localStorage.getItem("relations-fromdate");
		if (!todate) {
			todate = DateFormatter.format(new Date(), "yyyy-mm-dd");
			localStorage.setItem("relations-todate", todate);
		}
		if (!fromdate) {
			localStorage.setItem("relations-fromdate", todate);
		}
		this.query1 = localStorage.getItem("relations-query1");
		this.query2 = localStorage.getItem("relations-query2");
		this.types = localStorage.getItem("relation-types");
	}

	async connectedCallback() {
		super.connectedCallback();
		this.relationtypes = await api.get(
			`/lov/relationtypes?query=${this.types}`,
		);
		let todate = localStorage.getItem("relations-todate");
		const fromdate = localStorage.getItem("relations-fromdate");

		if (!todate) {
			todate = DateFormatter.format(new Date(), "yyyy-mm-dd");
			localStorage.setItem("relations-todate", todate);
		}
		if (!fromdate) {
			localStorage.setItem("relations-fromdate", todate);
		}
		this.query1 = localStorage.getItem("relations-query1");
		this.query2 = localStorage.getItem("relations-query2");
	}

	chkbox(event) {
		const target = event.target;
		const id = Number(target.getAttribute("data-id"));
		for (let i = 0; i < this.relationtypes.length; i++) {
			if (this.relationtypes[i].id === id) {
				if (target.checked) {
					this.relationtypes[i].selected = "Y";
				} else {
					this.relationtypes[i].selected = "N";
				}
			}
		}
	}

	async refresh() {
		const parms = this.shadowRoot.querySelector("#parms").value;
		const dates = this.shadowRoot.querySelector("#created").value;
		const submit_button = this.shadowRoot.querySelector("#submit_button");
		submit_button.loading = true;
		localStorage.setItem("relations-fromdate", dates.from);
		localStorage.setItem("relations-todate", dates.to);
		localStorage.setItem("relations-query1", parms.query1);
		localStorage.setItem("relations-query2", parms.query2);
		const reducer = (a, t) => (t.selected === "Y" ? `${a}${t.id},` : a);
		const types = this.relationtypes.reduce(reducer, ",");
		localStorage.setItem("relation-types", types);

		this.query1 = parms.query1;
		this.query2 = parms.query2;
		this.rows = await api.get(
			`/relations?from=${dates.from}&to=${dates.to}&query1=${this.query1}&query2=${this.query2}&types=${types}`,
		);

		submit_button.loading = false;
	}
}

customElements.define("user-relation-view", UserRelationsView);
