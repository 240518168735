import { LitElement, html } from "lit";
import sharedStyles from "../../styles/shared.js";
import tableStyles from "../../styles/tables.js";

export default class AccountView extends LitElement {
	render() {
		return html`
    ${sharedStyles}
    ${tableStyles}
      <header class="horizontal-menu-header">
        <router-link to="/accounts">
          <a>List</a>
        </router-link>
        <router-link class="link">
          <a href="/accounts/users">Users</a>
        </router-link>
        <router-link class="link">
          <a href="/accounts/logins">MitID Logins</a>
        </router-link>
        <router-link class="link">
          <a href="/accounts/questions">AML Questions</a>
        </router-link>
        <router-link class="link">
          <a href="/accounts/imageid">ImageID</a>
        </router-link>
        <router-link class="link">
          <a href="/accounts/relations">Relations</a>
        </router-link>
        <router-link class="link">
          <a href="/accounts/realowners">Real Owners</a>
        </router-link>
        <router-link class="link">
         <a href="/accounts/custodies">Custodies</a>
        </router-link>
        <router-link class="link">
          <a href="/accounts/return">Return</a>
        </router-link>
      </header>
      <slot></slot>
    `;
	}
}

customElements.define("account-view", AccountView);
