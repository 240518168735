import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { DateFormatter } from "../../formatting/dateformats.js";
import { money, quantity } from "../../formatting/numberformats.js";
import { toCsv, sort, toast } from "../../utils.js";

export default class OrdersListView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			ordertypes: {
				type: Array,
			},
			order_id: {
				type: Number,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.query = "";
		this.fetch = this.fetch.bind(this);
		this.details = this.details.bind(this);
		this.download = this.download.bind(this);
		this.refresh = this.refresh.bind(this);
		this.sort = this.sort.bind(this);
		this.chkboxT = this.chkboxT.bind(this);
		this.chkboxR = this.chkboxR.bind(this);
		this.amlAsk = this.amlAsk.bind(this);
		this.nota = this.nota.bind(this);
		this.saveDetails = this.saveDetails.bind(this);
		this.rerender = 0;
		this.ordertypes = [];
		this.risklevels = [];
		this.qrisklevels = "";
		this.accountUsers = [];
		const todate = DateFormatter.format(new Date(), "yyyy-mm-dd");
		localStorage.setItem("order-list-todate", todate);
		const fromdate = localStorage.getItem("order-list-fromdate");
		if (!fromdate) {
			localStorage.setItem("order-list-fromdate", todate);
		}
		this.query = localStorage.getItem("order-list-query");
		this.types = localStorage.getItem("order-list-types") || "";
		this.qrisklevels = localStorage.getItem("order-list-risk") || "";
		this.firstuser_id = "";
		this.firstquestion_id = "";
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1400px;
      }

      .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
      }
  
      tr.l1 td {border:none}

      </style>
     
      <div class="card">
      <div class="card-header">
       <h2>Fund orders</h2>
       </div>
       <fm-form id = "parms" class="form-grid" @submit="${this.refresh}">
        <div class="form-field">
        <label>Period
          <fm-date-range id="dates" name="dates"  names="order-list-" ></fm-date-range>
          </label>
        </div>
        <div class="form-field">
            <label>Search
              <input type="search"  name="query" value="${this.query || ""}" class="search-input" style="width:100%">
            </label>
         </div>
         <div class="form-field">
          <label>Ordertype
            ${this.ordertypes.map(
							(t) =>
								html`<input data-id="${t.id}" id="chk${
									t.id
								}" type="checkbox" @change="${this.chkboxT}" ?checked=${
									t.selected === "Y"
								}>${t.order_code}`,
						)}
          </label>
        </div>
        <div class="form-field">
          <label>Risk
            ${this.risklevels.map(
							(t) =>
								html`<input data-id="${t.id}" id="chk${
									t.id
								}" type="checkbox" @change="${this.chkboxR}" ?checked=${
									t.selected === "Y"
								}>${t.code}`,
						)}
          </label>
       </div>
       <div class="form-field">
            <fm-button type="submit" id="submit_button" class="btn" style="max-width:80px">Refresh</fm-button>
            <fm-button id="download_button" class="btn" style="max-width:80px;" @click="${
							this.download
						}">Download</fm-button>
        </div>
      </fm-form>
    </div>
    <div class="card">
         <table>
          <thead>
            <tr>
              <th>CustodyOwner</th>
              <th>Custody</th>
              <th>Type</th>
              <th>Source</th>
              <th>Message</th>
              <th>Sub Trantype</th>
              <th>Order date</th>
              <th class="numeric" >Amount</th>
            </tr>
          </thead>
          <tbody>
            ${this.rows.map(
							(row) => html`
              <tr class="l1">
               <td> ${row.custodyowner} </td>
                <td>${row.custody_code}</td>
                <td>${row.custodytype_code}</td>
                <td>${row.source}</td>
                <td>${row.message}</td>
                <td>${row.sub_trantype}</td>
                <td>${row.reg_ts}</td>
                <td class="numeric">${money(row.amountqc)}</td>
                </tr>
                <tr>
                <td>${row.pep}</td>
                <td>Risk:${row.risklevel}</td>
                <td colspan="2">adm:${row.admin_comment}</td>
                <td colspan="2">advisor:${row.advisor}</td>
                <td colspan="2" class="numeric">
                  <fm-button data-order="${row.order_id}" class="btn" @click="${
								this.nota
							}" style="display:inline;max-width:80px">Nota</fm-button>
                  <fm-button data-order="${row.order_id}" class="btn" @click="${
								this.details
							}" style="display:inline;max-width:80px">Details</fm-button>
                </td>
              </tr>
              ${this.renderDetails(row.order_id)}
            `,
						)}
          </tbody>
        </table>
      </div>
   `;
	}

	renderDetails(order_id) {
		if (order_id === this.order_id) {
			return html`
    <tr><td colspan="10">
    <div class="card">
    <div class="card-header">
      <h1>Oder Details</h1>
    </div>
    <fm-form class="form-grid"  method="put" url="/orders/money/${
			this.row.order_id
		}" @submit="${this.saveDetails}">
    <label class="form-field">
        Account Name
        <input type="text" name="account_name" value="${this.row.account_name}">
      </label>
    <label class="form-field">
        Source
        <input type="text" name="source" value="${this.row.source || ""}">
      </label>
      <label class="form-field">
        Message
        <input type="text" name="message" value="${this.row.message || ""}">
      </label>
      <label class="form-field">
        Amount
        <input type="text" name="amountqc" value="${this.row.amountqc || ""}">
      </label>
      <label class="form-field">
        Admin Comment
        <textarea name="admin_comment"  rows="10" cols="60">${
					this.row.admin_comment || ""
				}</textarea>
      </label>
      <label class="form-field">
        Risk level
        <select name="risklevel_id">
          ${this.risklevels.map(
						(r) => html`
            <option value="${r.id}" ?selected="${r.id === this.row.risklevel_id}">
              ${r.description}
            </option>
          `,
					)}
        </select>
      </label>
      <label class="form-field">
        Admin user
        <input type="text" name="mod_user" value="${this.row.mod_user || ""}">
      </label>
      <label class="form-field">
        Updated
        <input type="text" name="mod_ts" value="${this.row.mod_ts || ""}">
      </label>
      <button type="submit" class="button" >Save</button>
    </fm-form>
    <table style="border:1px solid black">
      <thead>
        <tr>
          <th style="width:50%;text-align:center;">Questions</th>
          <th style="width:50%;text-align:center">Transactions</th>
        </tr>
    </thead>
    <tbody>
      <tr>
        <td>
        ${this.renderQuestions()}
        </td>
        <td>
        ${this.renderTransactions()}
        </td>
      </tr>
    </tbody>
    </table>
  </div>
  </td>
  </tr>    `;
		} else {
			return "";
		}
	}

	initTotals() {
		this.sumAmt = 0;
		this.sumRequest = 0;
		return " ";
	}

	renderTransactions() {
		this.sumAmt = 0;
		this.sumRequest = 0;
		return html`
  <table>
    <tr>
      <th>Security</th>
      <th>Quantity</th>
      <th>Amount</th>
      <th>Nav</th>
      <th>Price</th>
      <th>Requested</th>
    </tr>
    ${(this.transactions || []).map((t) => this.renderTransaction(t))}
    ${this.renderTransactionSum()}
  </table>
  `;
	}

	renderTransaction(t) {
		this.sumAmt += t.amountqc;
		this.sumRequest += t.requestamountqc;
		return html`
  <tr>
    <td>${t.security_name}</td>
    <td class="numeric">${quantity(t.quantity)}</td>
    <td class="numeric">${money(t.amountqc)}</td>
    <td class="numeric">${quantity(t.price)}</td>
    <td class="numeric">${quantity(t.nav)}</td>
    <td class="numeric">${money(t.requestamountqc)}</td>
  </tr>
  `;
	}

	renderTransactionSum() {
		return html`
  <tr>
    <td colspan="2"></td>
    <th class="numeric">${money(this.sumAmt)}</th>
    <td colspan="2"></td>
    <th class="numeric">${money(this.sumRequest)}</th>
  </tr>
  `;
	}

	async setQuestion(event) {
		const target = event.target;
		const question_id = target.value;
		let q = (await api.get(`/lov/questions/${question_id}`)).question;
		if (q.indexOf("%s2") >= 0) {
			q = q.replace("%s2", this.row.custody_code);
		}
		const q_input = this.shadowRoot.querySelector("#question");
		q_input.value = q;
	}

	renderQuestions() {
		return html`
  <fm-form @submit="${this.amlAsk}">
  <label>Question</label>
  <input type="hidden" name="order_id" id="order_id" value="${this.order_id}">
  <input type="text" name="question" id="question" style="width:100%">
   <div class="divider"></div>
  <label>Pre-select question</label>
  <select name="question_id" @change="${this.setQuestion}"> 
  ${(this.orderQuestions || []).map(
		(q) =>
			html`<option value="${q.id}">${q.code} ${`${q.question.substring(
				0,
				10,
			)}...`}</option>`,
	)}
  </select>
  <label>Receiver of question</label>
  <select name="user_id" > 
  ${(this.accountUsers || []).map(
		(u) => html`<option value="${u.id}">${u.name}</option>`,
	)}
  </select>
    <fm-button type="submit" class="btn" style="max-width:80px">Ask</fm-button>
    </fm-form>
  <table>
    ${(this.questions || []).map((q) => this.renderQuestion(q))}
  </table>
  `;
	}

	renderQuestion(q) {
		return html`
  <tr>
  <td>${q.ask_date}</td><td>${q.question}</td>
  </tr> 
  ${this.renderAnswers(q.answers)}
`;
	}

	renderAnswers(a) {
		return html`
  <tr>
    <td></td>
    <td>
      <table>
        <thead>
          <tr><th>Answered</th><th>Answer</th></tr>
        </thead>
        <tbody>
          ${(a || []).map((r) => this.renderAnswer(r))}
        </tbody>
      </table>
    </td>
  </tr>
`;
	}

	renderAnswer(a) {
		return html`
    <tr>
    <td>${a.answer_date}</td><td>${a.answer} ${a.answer_other}</td>
    </tr> 
  `;
	}

	async connectedCallback() {
		super.connectedCallback();
		this.orderQuestions = await api.get("/lov/questions?query=payment");
		this.risklevels = await api.get(
			`/lov/risklevels?query=${this.qrisklevels}`,
		);
		this.ordertypes = await api.get(`/lov/ordertypes?query=${this.types}`);
	}

	chkboxT(event) {
		const target = event.target;
		const id = Number(target.getAttribute("data-id"));
		for (let i = 0; i < this.ordertypes.length; i++) {
			if (this.ordertypes[i].id === id) {
				if (target.checked) {
					this.ordertypes[i].selected = "Y";
				} else {
					this.ordertypes[i].selected = "N";
				}
			}
		}
	}

	chkboxR(event) {
		const target = event.target;
		const id = Number(target.getAttribute("data-id"));
		for (let i = 0; i < this.risklevels.length; i++) {
			if (this.risklevels[i].id === id) {
				if (target.checked) {
					this.risklevels[i].selected = "Y";
				} else {
					this.risklevels[i].selected = "N";
				}
			}
		}
	}

	firstQuestionId() {
		if ((this.questions || []).length > 0) {
			return this.questions[0].id;
		} else {
			return "";
		}
	}

	firstUserId() {
		if ((this.accountUsers || []).length > 0) {
			return this.accountUsers[0].id;
		} else {
			return "";
		}
	}

	async saveDetails() {
		this.row = await api.get(`/orders/money/${this.order_id}`);
		toast("OK");
	}

	async details(event) {
		const target = event.target;
		const id = Number(target.getAttribute("data-order"));
		this.row = await api.get(`/orders/money/${id}`);
		this.questions = await api.get(`/orders/${id}/questions`);
		if (this.questions.length > 0) {
			this.firstquestion_id = this.questions[0].id;
		} else {
			this.firstquestion_id = "";
		}
		this.accountUsers = await api.get(`/orders/${id}/users`);
		if (this.accountUsers.length > 0) {
			this.firstuser_id = this.accountUsers[0].id;
		} else {
			this.firstuser_id = "";
		}

		this.transactions = await api.get(`/orders/${id}/transactions`);
		this.order_id = id;
	}

	async fetch() {
		const parms = this.shadowRoot.querySelector("#parms").value;
		const dates = this.shadowRoot.querySelector("#dates").value;
		localStorage.setItem("order-list-fromdate", dates.from);
		localStorage.setItem("order-list-todate", dates.to);
		localStorage.setItem("order-list-query", parms.query);
		this.query = parms.query;
		const reducer = (a, t) => (t.selected === "Y" ? `${a}${t.id},` : a);
		const types = this.ordertypes.reduce(reducer, ",");

		const risks = this.risklevels.reduce(reducer, ",");
		localStorage.setItem("order-list-types", types);
		this.order_id = null;
		this.rows = await api.get(
			`/orders?from=${dates.from}&to=${dates.to}&types=${types}&query=${parms.query}&risks=${risks}`,
		);
	}

	async refresh() {
		const submit_button = this.shadowRoot.querySelector("#submit_button");
		submit_button.loading = true;
		await this.fetch();
		submit_button.loading = false;
	}

	async amlAsk(event) {
		const target = event.target;
		const question = target.value;
		target.loading = true;
		try {
			await api.post(`/orders/${this.order_id}/amlquestion`, question);
			toast("OK");
		} catch (err) {
			Sentry.captureException(err);
			toast("Det lykkedes ikke at gemme spørgsmålet");
			console.log(err);
		}
		target.loading = false;
	}

	sort(event) {
		const key = event.target.getAttribute("data-key");
		this.rows = sort(this.rows, key);
	}

	async download() {
		this.shadowRoot.querySelector("#download_button").loading = true;
		const csvHeaders = [
			"order_id",
			"custody_code",
			"custodytype_code",
			"custodytype",
			"amountqc",
			"source",
			"message",
			"admin_comment",
			"mod_user",
			"mod_ts",
			"reg_date",
			"reg_ts",
			"account_name",
			"status_code",
			"order_status",
			"risklevel_id",
			"risklevel_code",
			"risklevel",
			"custodyowner",
			"pep",
		];

		const csvFields = [
			"order_id",
			"custody_code",
			"custodytype_code",
			"custodytype",
			"amountqc",
			"source",
			"message",
			"admin_comment",
			"mod_user",
			"mod_ts",
			"reg_date",
			"reg_ts",
			"account_name",
			"status_code",
			"order_status",
			"risklevel_id",
			"risklevel_code",
			"risklevel",
			"custodyowner",
			"pep",
		];

		let txtdata = toCsv(csvHeaders, csvFields, this.rows);
		try {
			txtdata = btoa(txtdata);
		} catch (err) {
			Sentry.captureException(err);
			txtdata = btoa(unescape(encodeURIComponent(txtdata)));
		}
		const a = document.createElement("a");
		a.setAttribute("href", `data:text/csv;base64,${txtdata}`);
		a.setAttribute("download", "fm_orders.csv");
		a.click();
		this.shadowRoot.querySelector("#download_button").loading = false;
	}

	async nota(event) {
		const target = event.target;
		const id = target.getAttribute("data-order");
		const data = await api.get(`/orders/${id}/nota`);

		const a = document.createElement("a");
		a.setAttribute("href", `data:application/pdf;base64,${data.nota}`);
		a.setAttribute("download", "fm_nota.pdf");
		a.click();
	}
}

customElements.define("orders-list-view", OrdersListView);
