import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import inputStyles from "../../styles/input.js";
import sharedStyles from "../../styles/shared.js";

import { toast } from "../../utils.js";

export default class ReportParticipationView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			status_message: {
				type: String,
			},
		};
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1400px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>Users (Persons)</h1>
        </div>
        <div class="card-block">
        <input type="search" class="search-input" id="query" placeholder="Search" @change="${
					this.onInput
				}">
        <fm-button id="refresh" class="btn" @click="${this.refresh}">Refresh</fm-button>
        </div>
        <div class="card-block">
          ${this.status_message}
        </div>
        <table>
            <thead>
                <th>CPR</th>
                <th>CVRID</th>
                <th>Name</th>
                <th>Approved</th>
                <th>Custodies</th>
                <th>Address</th>
                <th>Country</th>
            </thead>
            <tbody>
          ${this.rows.map(
						(r) => html`
          <tr>
            <td>
              <router-link to="/accounts/users/${r.id}">
                    <a>${r.cpr}</a>
              </router-link>
            </td>
            <td>${r.cvrid}</td>
            <td>${r.name}</td>
            <td>${r.approved_yn}</td>
            <td>${r.custody_count}</td>
            <td>${r.address1} ${r.address2} ${r.zipcity}</td>
            <td>${r.country} ${r.country_name}</td>
           </tr>
          `,
					)}
        </tbody>
        </table>
      </div>
    `;
	}

	constructor() {
		super();
		this.rows = [];
		this.query = "";
		this.status_message = "";
		this.onInput = this.onInput.bind(this);
		this.refresh = this.refresh.bind(this);
	}

	onInput(event) {
		this.query = event.target.value;
	}

	async refresh() {
		const but = this.shadowRoot.querySelector("#refresh");
		but.loading = true;
		this.rows = await api.get(`/users/privateowners?query=${this.query}`);
		but.loading = false;
	}
}

customElements.define("report-participation-view", ReportParticipationView);
