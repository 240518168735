import { LitElement, html } from "lit";

import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";

export default class AMLInvestorSectorListView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
		};
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1200px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h2>Investor sectors ${this.dateStr}</h2>
        </div>
        <table>
          <thead>
            <tr>
            <th>Sector</th>
             <th>Level</th>
             <th>Description</th>
             <th>Investor count</th>
             <th>High Risk</th>
            </tr>
          </thead>
          <tbody>
            ${this.rows.map(
							(row) => html`
              <tr>
                <td>${row.code}</td>
                <td>${row.codelevel}</td>
                <td>${row.description}</td>
                <td>${row.investor_count}</td>
                <td>${row.sanction}</td>
              </tr>
            `,
						)}
          </tbody>
        </table>
      </div>
    `;
	}

	constructor() {
		super();
		this.rows = [];
		this.dateStr = "";
	}

	async connectedCallback() {
		super.connectedCallback();
		const dt = new Date();
		this.dateStr = dt.toLocaleString();
		this.rows = await api.get("/aml/investorsectors");
	}
}

customElements.define(
	"aml-investor-sector-list-view",
	AMLInvestorSectorListView,
);
