import { LitElement, html, css } from "lit";
import { customElement, property } from "lit/decorators.js";

declare global {
	interface HTMLElementTagNameMap {
		"fm-dialog": Dialog;
	}
}

@customElement("fm-dialog")
export class Dialog extends LitElement {
	@property({ type: Boolean, reflect: true })
	opened: boolean = false;

	static styles = [
		css`.fullscreen {
  height: 100vh;
  width: 100vw;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.noninteractive {
  pointer-events: none;
}

.interactive {
  pointer-events: all;
}

.dialog {
  background: white;
  border: 1px solid #ddd;
  border-radius: 3px;
  max-width: 640px;
  padding: 48px;
  transform: translate3d(0px, -15px, 0px);
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

fm-overlay {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.25, 0.8, 0.25, 1),
    visibility 0s cubic-bezier(0.25, 0.8, 0.25, 1) 0.3s;
}

fm-overlay[opened] {
  opacity: 1;
  transition-delay: 0s;
}

fm-overlay[opened] .dialog {
  transform: translate3d(0px, 0px, 0px);
}`,
	];

	render() {
		return html`
      <style>
        
      </style>
      <fm-overlay .opened="${this.opened}" @close="${this.onClose}">
        <div class="fullscreen center noninteractive">
          <div class="dialog interactive">
            <slot></slot>
          </div>
        </div>
      </fm-overlay>
    `;
	}

	onClose() {
		this.opened = false;
		this.dispatchEvent(new Event("close"));
	}

	open() {
		this.opened = true;
	}

	close() {
		this.opened = false;
	}
}
