import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import router from "../../router/index.js";
import { toast } from "../../utils.js";

export default class AccountNewUserView extends LitElement {
	static get properties() {
		return {
			account_id: {
				type: Number,
			},
			row: {
				type: Object,
			},
			usertypes: {
				type: Array,
			},
			countryMissing: {
				type: Boolean,
			},
			citizen1Missing: {
				type: Boolean,
			},
		};
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1000px;
      }

      .invalid {
        color: #970000;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>New User</h1>
        </div>
        <fm-form id="userform" class="form-grid"  method="put">
          <label class="form-field">
            Name
            <input type="text" name="name">
          </label>
          <label class="form-field">
            Cpr
            <input type="text" name="cpr">
          </label>
          <label class="form-field">
            CVR
            <input type="text" name="cvr">
          </label>
          <label class="form-field">
            Person-ID CVR
            <input type="text" name="cvrid">
          </label>
          <label class="form-field">
            E-mail
            <input type="text" name="email">
          </label>
          <label class="form-field">
            Phone
            <input type="text" name="phone">
          </label>
          <label class="form-field">
            PID
            <input type="text" name="uniquename">
          </label>
          <label class="form-field">
            Address1
            <input type="text" name="address1">
          </label>
          <label class="form-field">
            Address2
            <input type="text" name="address2">
          </label>
          <label class="form-field">
            Zip, city
            <input type="text" name="zipcity">
          </label>
          <label class="form-field">
            Country (address) <span class="invalid">${
							this.countryMissing ? "Required" : null
						}</span>
            <fm-autocomplete url="/lov/countries" select="id" display="name" name="country_id" 
              .rawValue="${{
								id: this.row.country_id,
								name: this.row.country_name || "",
							}}"
            ></fm-autocomplete>
        </label>
        <label class="form-field">
          Tin (if address not in DK)
          <input type="text" name="tin" value="${this.row.tin || ""}">
        </label>
        <label class="form-field">
          Primary Citizen country <span class="invalid">${
						this.citizen1Missing ? "Required" : null
					}</span>
          <fm-autocomplete url="/lov/countries" select="id" display="name" name="citizen1_id" 
            .rawValue="${{
							id: this.row.citizen1_id,
							name: this.row.citizen1_name || "",
						}}"
          ></fm-autocomplete>
        </label>
        <label class="form-field">
          Secondary Citizen country
          <fm-autocomplete url="/lov/countries" select="id" display="name" name="citizen2_id" 
          .rawValue="${{
						id: this.row.citizen2_id,
						name: this.row.citizen2_name || "",
					}}"
          ></fm-autocomplete>
        </label>
        <label class="form-field">
          User Type
          <select name="type_id">
            ${this.usertypes.map(
							(r) =>
								html`<option value="${r.id}" ?selected=${
									r.id === this.row.type_id
								}>${r.description}</option>`,
						)}
          </select>
          </label>
          <label class="form-field">
            Remark
            <input type="text" name="remark" >
          </label>
          <button class="button" @click="${this.save}" >Save</button>
        </fm-form>
      </div>
    `;
	}

	constructor() {
		super();
		this.row = {};
		this.usertypes = [];
		this.save = this.save.bind(this);
		this.countryMissing = false;
		this.citizen1Missing = false;
	}

	async connectedCallback() {
		super.connectedCallback();
		this.usertypes = await api.get("/lov/usertypes");
	}

	async save(event) {
		event.preventDefault();
		const formdata = this.shadowRoot.querySelector("#userform").value;

		this.countryMissing = formdata.country_id === undefined;
		this.citizen1Missing = formdata.citizen1_id === undefined;

		if (this.citizen1Missing || this.countryMissing) {
			return;
		}

		try {
			await api.post(`/accounts/${this.account_id}/users`, {
				name: formdata.name,
				cpr: formdata.cpr,
				cvr: formdata.cvr,
				cvrid: formdata.cvrid,
				email: formdata.email,
				phone: formdata.phone,
				uniquename: formdata.uniquename,
				address1: formdata.address1,
				address2: formdata.address2,
				zipcity: formdata.zipcity,
				tin: formdata.tin,
				remark: formdata.remark,
				country_id: formdata.country_id,
				type_id: Number(formdata.type_id),
				citizen1_id: formdata.citizen1_id,
				citizen2_id: formdata.citizen2_id,
			});
			router.push(`/accounts/${this.account_id}`);
			toast("User created");
		} catch (err) {
			console.log(err);
			Sentry.captureException(err);
		}
	}
}

customElements.define("account-new-user-view", AccountNewUserView);
