import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import inputStyles from "../../styles/input.js";
import sharedStyles from "../../styles/shared.js";
import { toast } from "../../utils.js";

export default class FormcalcEditView extends LitElement {
	static get properties() {
		return {
			row: {
				type: Object,
			},
			rows: {
				type: Array,
			},
			form_id: {
				type: String,
			},
			new: {
				type: Boolean,
			},
			formula_id: {
				type: String,
			},
			formula: {
				type: Object,
			},
		};
	}

	constructor() {
		super();
		this.row = {};
		this.rows = [];
		this.formula = {};
		this.categories = [];
		this.save = this.save.bind(this);
		this.newFormula = this.newFormula.bind(this);
		this.editFormula = this.editFormula.bind(this);
		this.closeEdit = this.closeEdit.bind(this);
		this.closeNew = this.closeNew.bind(this);
		this.new = false;
		this.formula_id = null;
	}

	async connectedCallback() {
		super.connectedCallback();
		this.categories = await api.get("/lov/formcategories");
		this.row = await api.get(`/forms/${this.form_id}`);
		this.rows = await api.get(`/forms/${this.form_id}/formulas`);
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 12px auto;
        max-width: 1400px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>Form calculation</h1>
        </div>
        <div class="card-block">
         ${this.renderCalcForm()}
        </div>
        <div class="card-block">
        ${this.renderFormulas()}
        </div>
      </div>
    `;
	}

	renderCalcForm() {
		return html`
  <fm-form id="form" class="form-grid"  @submit="${this.save}">
    <label class="form-field">
    Code
     <input type="text" name="code" value="${this.row.code || ""}">
    </label>
    <label class="form-field">
      Name
      <input type="text" name="name" value="${this.row.name || ""}">
    </label>
    <label class="form-field">
    <select name="category_id">
      ${this.categories.map(
				(r) =>
					html`<option value="${r.id}"  ?selected=${
						r.id === this.row.category_id
					} >${r.name}</option>`,
			)}
    </select>
  </label>
    <fm-button  type="submit" id="submit_button" >Save</fm-button>
    Created: ${this.row.txt_reg_date} By: ${this.row.reg_user}
    <br>
    Last changed ${this.row.txt_mod_date} By: ${this.row.mod_user}
  </fm-form>
`;
	}

	renderNewFormula() {
		return html`
  <fm-form id="formula" class="form-grid"  style="border:1px solid black" @submit="${this.saveFormula}">
    <span style="flex:100%;text-align:right;" @click="${this.closeNew}">X</span>
    <label class="form-field">
    Code
     <input type="text" name="code" >
    </label>
    <label class="form-field">
      Name
      <input type="text" name="name">
    </label>
    <label class="form-field">
    Parm1
      <input type="text" name="parm1">
    </label>
    <label class="form-field">
    Parm2
      <input type="text" name="parm2">
    </label>
    <label class="form-field">
    Parm3
      <input type="text" name="parm3">
    </label>
    <label class="form-field">
    Parm4
      <input type="text" name="parm4">
    </label>
    <label class="form-field">
      <textarea name="sqltext" rows="20" cols="80"></textarea>
    </label>
   <label class="form-field">
      <fm-button  type="submit" id="submit_formula" style="max-width:120px" >Save Formula</fm-button>
    </label>
  </fm-form>`;
	}

	renderEditFormula() {
		return html`
  <fm-form id="formula" class="form-grid"  style="border:1px solid black" @submit="${
		this.saveFormula
	}">
   <input type="hidden" name="id" value="${this.formula_id}">
   <span style="flex:100%;text-align:right;" @click="${this.closeEdit}">X</span>
    <label class="form-field">
    Code
     <input type="text" name="code" value="${this.formula.code || ""}">
    </label>
    <label class="form-field">
      Name
      <input type="text" name="name" value="${this.formula.name || ""}">
    </label>
    <label class="form-field">
    Parm1
      <input type="text" name="parm1" value="${this.formula.parm1 || ""}">
    </label>
    <label class="form-field">
    Parm2
      <input type="text" name="parm2"  value="${this.formula.parm2 || ""}">
    </label>
    <label class="form-field">
    Parm3
      <input type="text" name="parm3"  value="${this.formula.parm3 || ""}">
    </label>
    <label class="form-field">
    Parm4
      <input type="text" name="parm4"  value="${this.formula.parm4 || ""}">
    </label>
    <label class="form-field">
      <textarea name="sqltext" rows="20" cols="80">${this.formula.sqltext || ""}</textarea>
    </label>
   <label class="form-field">
      <fm-button  type="submit" id="submit_formula" style="max-width:120px" >Save Formula</fm-button>
      <br>
      Created: ${this.formula.txt_reg_date} by: ${this.formula.reg_user}
      <br>
      Last changed: ${this.formula.txt_mod_date} by: ${this.formula.mod_user}
    </label>
  </fm-form>`;
	}

	renderFormulas() {
		if (this.new) {
			return this.renderNewFormula();
		}
		if (this.formula_id) {
			return this.renderEditFormula();
		}

		return html`
    <div> <hr style="width:100%"> </div>
    <table>
      <tr>
        <th colspan="4">Formulas</th>
      </tr>
      <tr>
       <th>Edit</th>
        <th>Code</th>
        <th>Name</th>
        <th>Parms</th>
        <th><fm-button style="max-width:80px" type="button" @click="${
					this.newFormula
				}">New</fm-button></th>
      </tr>
      ${this.rows.map(
				(r) => html`
          <tr>
          <td><fm-button style="max-width:80px" data-id="${r.id}" @click="${this.editFormula}">Edit</fm-button></td>
          <td>${r.code}</td>
          <td>${r.name}</td>
          <td>${r.parm1}/${r.parm2}/${r.parm3}/${r.parm4}</td>
          </tr>`,
			)}
    </table>
`;
	}

	async save() {
		const but = this.shadowRoot.querySelector("#submit_button");
		but.loading = true;
		const data = this.shadowRoot.querySelector("#form").value;
		try {
			const response = await api.put(`/forms/${this.form_id}`, data);
			if (response.message) {
				toast(response.message);
			} else {
				toast("OK");
				const ev = new CustomEvent("Success", { cancelable: true });
				this.dispatchEvent(ev);
			}
		} catch (err) {
			Sentry.captureException(err);
			const r = await err.json();
			toast(JSON.stringify(r));
		}
		but.loading = false;
	}

	async saveFormula() {
		const but = this.shadowRoot.querySelector("#submit_formula");
		but.loading = true;
		const data = this.shadowRoot.querySelector("#formula").value;
		if (data.id) {
			try {
				const response = await api.put(
					`/forms/${this.form_id}/formulas/${data.id}`,
					data,
				);
				if (response.message) {
					toast(response.message);
				} else {
					toast("OK");
					this.formula_id = null;
					this.rows = await api.get(`/forms/${this.form_id}/formulas`);
				}
			} catch (err) {
				Sentry.captureException(err);
				const r = await err.json();
				toast(JSON.stringify(r));
			}
		} else {
			try {
				const response = await api.post(
					`/forms/${this.form_id}/formulas`,
					data,
				);
				if (response.message) {
					toast(response.message);
				} else {
					toast("OK");
					this.new = false;
					this.rows = await api.get(`/forms/${this.form_id}/formulas`);
				}
			} catch (err) {
				Sentry.captureException(err);
				const r = await err.json();
				toast(JSON.stringify(r));
			}
		}
	}

	newFormula() {
		this.new = true;
	}

	closeEdit() {
		this.formula_id = null;
	}

	closeNew() {
		this.new = false;
	}

	async editFormula(event) {
		const target = event.target;
		const id = target.getAttribute("data-id");
		this.formula = await api.get(`/forms/${this.form_id}/formulas/${id}`);
		this.formula_id = id;
	}
}

customElements.define("formcalc-edit-view", FormcalcEditView);
