import { LitElement, html } from "lit";

import sharedStyles from "../../styles/shared.js";
import tableStyles from "../../styles/tables.js";

export default class AdvisorView extends LitElement {
	render() {
		return html`
    ${sharedStyles}
    ${tableStyles}
      <header class="horizontal-menu-header">
        <router-link to="/advisors">
          <a>Advisors</a>
        </router-link>
        <router-link to="/advisors/fee">
         <a>Fee</a>
        </router-link>
        <router-link to="/advisors/orders">
        <a>Orders</a>
       </router-link>
      </header>
      <slot></slot>
    `;
	}
}

customElements.define("advisor-view", AdvisorView);
