import { money, quantity } from "./formatting/numberformats.js";

export function toast(message: string, duration = 8000) {
	const container =
		document
			.querySelector("main-element")
			?.shadowRoot?.querySelector("#toast-container") ?? document.body;
	const toast = document.createElement("fm-toast");
	toast.textContent = message;
	toast.duration = duration;
	container.appendChild(toast);
	toast.addEventListener(
		"done",
		() => {
			toast.remove();
		},
		{ once: true },
	);
	requestAnimationFrame(() => {
		toast.open();
	});
	return toast;
}

export function toCsv(
	headers: string[],
	fields: string[],
	data: Record<string, unknown>[],
) {
	let csv = headers.map((s) => `"${s}"`).join(";") + "\n";
	for (let i = 0; i < data.length; i++) {
		csv = csv + fields.map((f) => `"${data[i][f] || ""}"`).join(";") + "\n";
	}
	return csv;
}

export type Formats = "m" | "q";

export function toCsvF(
	headers: string[],
	fields: string[],
	formats: Formats[],
	data: Record<string, unknown>[],
) {
	let csv = headers.map((s) => `"${s}"`).join(";") + "\n";
	for (let i = 0; i < data.length; i++) {
		for (let j = 0; j < fields.length; j++) {
			switch (formats[j]) {
				case "m":
					csv = `${csv}${money(Number(data[i][fields[j]]))};`;
					break;
				case "q":
					csv = `${csv}${quantity(Number(data[i][fields[j]]))};`;
					break;
				default:
					csv = `${csv}"${data[i][fields[j]]}";`;
			}
		}
		csv = csv + "\n";
	}
	return csv;
}

export function addDays(date: Date, days: number): Date {
	const result = new Date(date);
	result.setDate(result.getDate() + days);
	return result;
}

const keys = new WeakMap();
export function sort(array: Record<string, unknown>[], key: string) {
	if (key === keys.get(array)) {
		// already sorted, reverse
		return array.reverse();
	} else {
		// not yet sorted
		keys.set(array, key);
		const isString = typeof array[0][key] === "string";
		return array.sort((a, b) => {
			let A = a[key] || "";
			let B = b[key] || "";
			if (isString) {
				A = (A as string).toLowerCase();
				B = (B as string).toLowerCase();
			}
			return A > B ? 1 : B > A ? -1 : 1;
		});
	}
}
