import { html } from "lit";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { money, quantity } from "../../formatting/numberformats.js";
import OrdersEditView from "./orders-edit-view.js";

export function ext_render() {
	this.account_sum = 0;
	this.account_request_sum = 0;
	this.total_sum = 0;
	this.total_request_sum = 0;
	this.account_id = -1;
	return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1800px;
      }

      .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
      }
  
      tr.l1 td {border:none}

      </style>
     
      <div class="card">
      <div class="card-header">
       <h2>Orders</h2>
       </div>
       <fm-form id = "parms" class="form-grid" @submit="${this.refresh}">
        <div class="form-field">
        <label>Trade date
          <fm-date-range id="dates" name="dates"  names="order-" ></fm-date-range>
          </label>
        </div>
        <div class="form-field">
        <label style="border-right:1px solid black">Order Status
          ${this.orderstatus.map(
						(t) =>
							html`<input data-id="${t.id}" id="chk${
								t.id
							}" type="checkbox" @change="${this.chkbox}" ?checked=${
								t.selected === "Y"
							}>${t.description}`,
					)}
        </label>
        <label style="border-left:1px solid black">
          <input type="checkbox" name="chkopen" >Open Funds
          <input type="checkbox" name="chkclosed" >Closed Funds
        </label>

        </div>
        <div >
            <label>Search investor
              <input type="search"  name="c_query" value="${this.c_query || ""}" class="search-input">
            </label>
            <label>Search fund
            <input type="search"  name="s_query" value="${this.s_query || ""}" class="search-input">
          </label>
         </div>
         <div>
         <label>Account sum from
         <input type="text"  name="account_from" >
       </label>
         <label>To
           <input type="text"  name="account_to" >
         </label>
      </div>
       <div style="margin-right:auto">
            <fm-button type="submit" id="submit_button" class="btn" style="max-width:80px">Refresh</fm-button>
            <fm-button type="button" id="download_button" class="btn" @click="${
							this.download
						}" style="max-width:80px">Download</fm-button>
        </div>
        <div style="margin-right:auto">
         ${this.status_change.map(
						(b) => html`
         <fm-button type="button"  data-from="${b.fromstatus_id}" data-to="${b.tostatus_id}" class="btn" @click="${this.statuschange}" style="max-width:100px">${b.description}</fm-button>`,
					)}
        </div>

      </fm-form>
    </div>
    <div class="card">
      ${renderEdit(this)}
         <table>
          <thead>
            <tr>
            <th>Account</th>
              <th>Security</th>
              <th>Custody</th>
              <th>Tradedate</th>
              <th class="numeric">amountqc</th>
              <th class="numeric">Requestamount</th>
              <th class="numeric">Quantity</th>
              <th class="numeric">Nav</th>
              <th class="numeric">Price</th>
              <th class="numeric">Cost</th>
              <th>reg.</th>
              <th>Status</th>
              <th>nota</th>
             </tr>
          </thead>
          <tbody>
            ${this.rows.map(
							(row) => html`
            ${renderAccountSum(this, row)}
              <tr class="l1">
               <td>${row.account_name}</td>
               <td>${row.security_name}</td>
               <td>${row.custody_code} ${row.custodytype_code}</td>
               <td>${row.tradedate}</td>
               <td class="numeric">${money(row.amountqc)}</td>
               <td class="numeric">${money(row.requestamountqc)}</td>
               <td class="numeric">${quantity(row.quantity)}</td>
               <td class="numeric">${quantity(row.nav)}</td>
               <td class="numeric">${quantity(row.price)}</td>
               <td class="numeric">${money(row.costqc)}</td>
               <td>${row.txt_reg_date}</td>
               <td>${row.status_code}</td>
               <td class="numeric">
                  ${renderOrderEdit(this, row)}
                  ${renderStatusChange(this, row)}
                  ${renderNota(this, row)}
                  ${renderPension(this, row)}
               </td>

              </tr>
              <tr>
              <td></td>
              <td colspan="2">${row.custody_owner}</td>
              <td colspan="5">${row.fundorder_id}/${row.id}/${row.order_code}</td>
              <td colspan="5">${row.source} ${row.message}</td>
              </tr>
            `,
						)}
            ${renderAccountSum(this)}
          </tbody>
          ${renderTotalSum(this)}
        </table>
      </div>
   `;
}

function renderEdit(cl) {
	if (cl.edit_id) {
		return html`<orders-edit-view order_id="${cl.edit_id}"></orders-edit-view>`;
	} else {
		return "";
	}
}

function renderOrderEdit(cl, row) {
	if (
		row.status_code === "10" ||
		row.status_code === "21" ||
		row.status_code === "20" ||
		row.status_code === "25"
	) {
		return html`<fm-button data-id="${row.fundorder_id}" class="btn" @click="${cl.edit}" style="max-width:80px">Edit</fm-button>`;
	} else {
		return "";
	}
}

function renderNota(cl, row) {
	if (row.status_code === "30") {
		return html`<fm-button data-id="${row.fundorder_id}" class="btn" @click="${cl.nota}" style="max-width:80px">Nota</fm-button>`;
	} else {
		return "";
	}
}

function renderStatusChange(cl, row) {
	return html`
  ${(row.status_change || []).map(
		(ch) => html`
    <fm-button data-id="${row.fundorder_id}" 
            data-to="${ch.tostatus_id}" 
            class="btn" 
            @click="${cl.statuschange_one}" 
            style="max-width:80px">${ch.description}</fm-button>`,
	)}`;
}

function renderPension(cl, row) {
	if (row.custodytype_code === "PENS") {
		return html`<fm-button data-id="${row.fundorder_id}" class="btn" @click="${cl.ownership}" style="max-width:80px">Pension</fm-button>`;
	} else {
		return "";
	}
}

function renderAccountSum(cl, row) {
	if (!row) {
		return html`
        <tr>
          <td colspan="4"></td>
          <td class="numeric">${money(cl.account_sum)}</td>
          <td class="numeric">${money(cl.account_request_sum)}</td>
          <td colspan="7"></td>
        </tr>`;
	}

	if (row.account_id === cl.account_id) {
		cl.account_sum += row.amountqc;
		cl.account_request_sum += row.requestamountqc ? row.requestamountqc : 0;

		cl.total_sum += row.amountqc;
		cl.total_request_sum += row.requestamountqc ? row.requestamountqc : 0;
		return "";
	}

	const account_sum = cl.account_sum;
	const account_request_sum = cl.account_request_sum;
	const account_id = Number(cl.account_id);
	cl.account_sum = row.amountqc;
	cl.account_request_sum = row.requestamountqc ? row.requestamountqc : 0;
	cl.account_id = row.account_id;

	cl.total_sum += row.amountqc;
	cl.total_request_sum = row.requestamountqc ? row.requestamountqc : 0;

	if (account_id === -1) {
		return "";
	} else {
		return html`
       <tr>
        <td colspan="4"></td>
        <td class="numeric">${money(account_sum)}</td>
        <td class="numeric">${money(account_request_sum)}</td>
        <td colspan="7"></td>
        </tr>`;
	}
}

function renderTotalSum(cl) {
	return html`
        <tr>
          <td colspan="3"></td>
          <td>Grand total</td>
          <td class="numeric">${money(cl.total_sum)}</td>
          <td class="numeric">${money(cl.total_request_sum)}</td>
          <td colspan="7"></td>
        </tr>`;
}
