import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import { toast } from "../../utils.js";
import router from "../../router/index.js";
import api from "../../api/index.js";

import tableStyles from "../../styles/tables.js";
import inputStyles from "../../styles/input.js";
import sharedStyles from "../../styles/shared.js";
import uploadStyles from "../../styles/upload.js";

export default class UserFileUploadView extends LitElement {
	static get properties() {
		return {
			user_id: {
				type: String,
			},
			row: {
				type: Object,
			},
			rerender: {
				type: Number,
			},
		};
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      ${uploadStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1000px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>Upload User File</h1>
        </div>
        <fm-form id="form" 
            class="form-grid" 
            url="/users/${this.user_id}/file" 
            method="post"
            @success="${this.onSuccess}">
            <label class="form-field">
                <input  type="file"  id="file"  @change="${this.onFileChange}"/>
            </label>
          <button class="btn" type="button" @click="${this.onSave}">
            Upload
          </button>
        </fm-form>
      </div>
    `;
	}

	constructor() {
		super();
		this.row = {};
		this.rerender = 0;
		this.onFileChange = this.onFileChange.bind(this);
		this.onSave = this.onSave.bind(this);
		this.onSuccess = this.onSuccess.bind(this);
	}

	fileRead(f) {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.addEventListener("loadend", (e) => {
				this.row.content = e.srcElement.result;
				return resolve("");
			});
			reader.readAsDataURL(f);
		});
	}

	async onFileChange(event) {
		event.preventDefault();
		const file = event.target.files[0];
		this.row.filename = file.name;
		this.row.filetype = file.type;
		await this.fileRead(file);
	}

	async onSave(event) {
		event.preventDefault();
		try {
			await api.post(`/users/${this.user_id}/file`, this.row);
			toast("File Uploaded");
			router.push(`/accounts/users/${this.user_id}`);
		} catch (err) {
			Sentry.captureException(err);
			console.log(err);
		}
	}

	async onSuccess(event) {}
}

customElements.define("user-fileupload-view", UserFileUploadView);
