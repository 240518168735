import { LitElement, css, html } from "lit";
import { customElement } from "lit/decorators.js";

declare global {
	interface HTMLElementTagNameMap {
		"fm-card": CardElement;
	}
}

@customElement("fm-card")
export class CardElement extends LitElement {
	static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            background: white;
            border: 1px solid #ddd;
            border-radius: 2px;
            margin: 16px;
        }

        header {
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
            min-height: 56px;
            border-bottom: 1px solid #ddd;
        }
    `;

	render() {
		return html`
            <header>
                <slot name="card-header"></slot>
            </header>
            <slot></slot>
        `;
	}
}
