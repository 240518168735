import { combineReducers } from "redux";
import {
	SET_TITLE,
	RECEIVE_STATUS,
	INVALIDATE_STATUS,
	Action,
	NOOP,
} from "./actions.js";

export type State = {
	title: string;
	status: Status;
};

export type Status = {
	authenticated: boolean;
};

const initial: State = {
	title: "",
	status: {
		authenticated: false,
	},
};

function status(
	state: Status = initial.status,
	action: Action = { type: NOOP },
): Status {
	switch (action.type) {
		case RECEIVE_STATUS:
			return action.status;
		case INVALIDATE_STATUS:
			return initial.status;
		default:
			return state;
	}
}

function title(state = initial.title, action: Action = { type: NOOP }): string {
	switch (action.type) {
		case SET_TITLE:
			return action.title;
		default:
			return state;
	}
}

const root = combineReducers({
	status,
	title,
});

export default root;
