import { LitElement, html } from "lit";

import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import inputStyles from "../../styles/input.js";
import sharedStyles from "../../styles/shared.js";

export default class AdvisorListView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			rerender: {
				type: Number,
			},
		};
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1000px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>Advisors</h1>
        </div>
        <div class="card-block">
            <input type="search" class="search-input" id="query" placeholder="Search" @change="${
							this.onInput
						}">
        <fm-button class="btn" @click="${this.refresh}">Refresh</fm-button>
        </div>
        <table>
        <thead>
        <th>Name</th>
        <th>CVR</th>
        </thead>
        <tbody>

          ${this.rows.map(
						(r) => html`
          <tr>
            <td>${r.name}</td>
            <td>${r.cvr}</td>
           </tr>
          `,
					)}
        </tbody>
        </table>
      </div>
    `;
	}

	constructor() {
		super();
		this.rows = [];
		this.query = "";
		this.rerender = 0;
		this.onInput = this.onInput.bind(this);
	}

	onInput(event) {
		this.query = event.target.value;
	}

	async connectedCallback() {
		super.connectedCallback();
		this.rows = await api.get("/advisors");
	}

	async refresh(event) {
		const target = event.target;
		target.loading = true;
		this.rows = await api.get("/advisors");
		target.loading = false;
	}
}

customElements.define("advisor-list-view", AdvisorListView);
