import { LitElement, html } from "lit";

import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import inputStyles from "../../styles/input.js";
import sharedStyles from "../../styles/shared.js";

export default class FormcalcListView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			calcid: {
				type: String,
			},
			new: {
				type: Boolean,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.new = false;
		this.endNew = this.endNew.bind(this);
	}

	async connectedCallback() {
		super.connectedCallback();
		this.rows = await api.get("/forms");
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 12px auto;
        max-width: 1400px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>Form</h1>
          <router-link class="btn btn--light" to="/formcalc/new">
            <a href="/formcalc/new"> New </a>
          </router-link>
        </div>
        <div class="card-block">
         ${this.renderCalcForm()}
        </div>
        <table>
          <thead>
            <tr>
              <th>Code</th>
              <th>Name</th>
              <th>Category</th>
            </tr>
          </thead>
          <tbody>
            ${this.rows.map(
							(row) => html`
              <tr>
                <td>
                  <router-link to="/formcalc/${row.id}">
                     <a href="/formcalc/${row.id}">${row.code}</a>
                  </router-link>
                </td>
                <td>${row.name}</td>
                <td>${row.category}</td>
              </tr>
            `,
						)}
          </tbody>
        </table>
      </div>
    `;
	}

	renderCalcForm() {
		if (this.new) {
			return html`<formcalc-edit-view @success="${this.endNew}"></formcalc-edit-view>`;
		}
		if (this.calcid) {
			return html`<formcalc-edit-view calcid="${this.calcid}" ></formcalc-edit-view>`;
		}
		return "";
	}

	endNew() {
		this.new = false;
	}
}

customElements.define("formcalc-list-view", FormcalcListView);
