import { LitElement, html } from "lit";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import inputStyles from "../../styles/input.js";
import sharedStyles from "../../styles/shared.js";
import { DateFormatter } from "../../formatting/dateformats.js";
import { money } from "../../formatting/numberformats.js";

export default class CustodiesListView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			fromdate: {
				type: String,
			},
			rerender: {
				type: Number,
			},
			types: {
				type: Array,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.query = "";
		this.types = [];
		this.selectedtypes = "";
		this.chkboxT = this.chkboxT.bind(this);
		this.refresh = this.refresh.bind(this);
		const todate = DateFormatter.format(new Date(), "yyyy-mm-dd");
		localStorage.setItem("custodies-todate", todate);
		const fromdate = localStorage.getItem("custodies-fromdate");
		if (!fromdate) {
			localStorage.setItem("custodies-fromdate", todate);
		}
		this.query = localStorage.getItem("custodies-query");
	}

	async connectedCallback() {
		super.connectedCallback();
		let todate = localStorage.getItem("custodies-todate");
		const fromdate = localStorage.getItem("custodies-fromdate");

		if (!todate) {
			todate = DateFormatter.format(new Date(), "yyyy-mm-dd");
			localStorage.setItem("custodies-todate", todate);
		}
		if (!fromdate) {
			localStorage.setItem("custodies-fromdate", todate);
		}
		this.query = localStorage.getItem("custodies-query");
		const selectedtypes = localStorage.getItem("custodies-types") || "";
		this.types = await api.get(`/lov/custodytypes?s=${selectedtypes}`);
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 12px auto;
        max-width: 1400px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>Custodies</h1>
        </div>
        <fm-form id = "parms" class="form-grid" @submit="${this.refresh}">
           <div class="form-field">
             <label>Created
               <fm-date-range id="created" name="created"  names="custodies-" ></fm-date-range>
              </label>
            </div>
          <div class="form-field">
            <label>Search
              <input type="search"  name="query" value="${this.query || ""}" class="search-input" style="width:100%">
            </label>
          </div>
          <div class="form-field">
              <label>Types
               ${this.types.map(
									(t) =>
										html`<input data-id="${t.id}" id="chk${
											t.id
										}" type="checkbox" @change="${this.chkboxT}" ?checked=${
											t.selected === "Y"
										}>${t.description}`,
								)}
              </label>
           </div>
   
           <div class="form-field">
           <fm-button type="submit" id="submit_button" class="btn" style="max-width:80px">Refresh</fm-button>
          </div>

       </fm-form>
        <table>
          <thead>
            <tr>
              <th data-key="custody_code" @click="${this.sort}">Code</th>
              <th data-key="custodytype_code" @click="${this.sort}">Type</th>
              <th data-key="account_name" @click="${this.sort}">Acount</th>
              <th data-key="owner_name" @click="${this.sort}">Owner</th>
              <th data-key="reg_date" @click="${this.sort}">Created</th>
              <th data-key="current_value" @click="${this.sort}">Value</th>
              <th data-key="active_yn" @click="${this.sort}">Active</th>
            </tr>
          </thead>
          <tbody>
            ${this.rows.map(
							(row) => html`
              <tr>
                <td><router-link to="/accounts/${row.account_id}"><a>${
								row.custody_code || "(none)"
							}</a></router-link></td>
                <td>${row.custodytype_code}</td>
                <td>${row.account_name}</td>
                <td>${row.owner_name}</td>
                <td>${row.txt_reg_date}</td>
                <td>${money(row.current_value)}</td>
                <td>${row.active_yn}</td>
              </tr>
            `,
						)}
          </tbody>
        </table>
      </div>
    `;
	}

	async refresh(event) {
		const parms = this.shadowRoot.querySelector("#parms").value;
		const dates = this.shadowRoot.querySelector("#created").value;
		const submit_button = this.shadowRoot.querySelector("#submit_button");
		submit_button.loading = true;
		const reducer = (a, t) => (t.selected === "Y" ? `${a}${t.id},` : a);
		const types = this.types.reduce(reducer, ",");
		localStorage.setItem("custodies-types", types);
		localStorage.setItem("custodies-fromdate", dates.from);
		localStorage.setItem("custodies-todate", dates.to);
		localStorage.setItem("custodies-query", parms.query);
		this.query = parms.query;
		this.rows = await api.get(
			`/custodies?from=${dates.from}&to=${dates.to}&types=${types}&query=${parms.query}`,
		);
		submit_button.loading = false;
	}

	chkboxT(event) {
		const target = event.target;
		const id = Number(target.getAttribute("data-id"));
		for (let i = 0; i < this.types.length; i++) {
			if (this.types[i].id === id) {
				if (target.checked) {
					this.types[i].selected = "Y";
				} else {
					this.types[i].selected = "N";
				}
			}
		}
	}
}

customElements.define("custodies-list-view", CustodiesListView);
