import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { DateFormatter } from "../../formatting/dateformats.js";
import { toast } from "../../utils.js";

export default class AMLRealOwnerView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.query = "";
		this.refresh = this.refresh.bind(this);
		let todate = localStorage.getItem("ro-todate");
		const fromdate = localStorage.getItem("ro-fromdate");
		if (!todate) {
			todate = DateFormatter.format(new Date(), "yyyy-mm-dd");
			localStorage.setItem("ro-todate", todate);
		}
		if (!fromdate) {
			localStorage.setItem("ro-fromdate", todate);
		}
		this.query1 = localStorage.getItem("eo-query1");
		this.query2 = localStorage.getItem("ro-query2");
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1800px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>Real Owners</h1>
       </div>

       <fm-form id = "parms" class="form-grid" @submit="${this.refresh}">
         <div class="form-field">
           <label>Created
             <fm-date-range id="created" name="created"  names="ro-" ></fm-date-range>
            </label>
         </div>
         <div class="form-field"></div>
         <div class="form-field">
           <label>Search owner
             <input type="search"  name="query1" value="${this.query1 || ""}" class="search-input" style="width:100%">
           </label>
         </div>
         <div class="form-field">
         <label>Search company
           <input type="search"  name="query2" value="${this.query2 || ""}" class="search-input" style="width:100%">
         </label>
       </div>
       <div class="form-field">
         <label>Missing CPR <input type="checkbox"  name="cprmissing" >
         </label>
       </div>
       <div class="form-field">
         <label>Confirmed <input type="checkbox"  name="confirmed" >
         </label>
       </div>
       <div class="form-field">
         <label>Unconfirmed <input type="checkbox"  name="unconfirmed" >
         </label>
       </div>
       <div class="form-field">
         <label>Not confirmed <input type="checkbox"  name="notconfirmed" >
         </label>
       </div>

         <fm-button type="submit" id="submit_button" class="btn" style="max-width:80px">Refresh</fm-button>
       </fm-form>

        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Advisor Name</th>
              <th>CPR</th>
              <th>Confirm</th>
              <th class="numeric">#Orders</th>
              <th>Address</th>
              <th>From/To</th>
              <th>adm.comment</th>
              <th>Company</th>
              <th>CVR</th>
              <th>Reg.date</th>
            </tr>
            </thead>
            <tbody>
            ${this.rows.map((r) => this.renderOwner(r))}
            </tbody>
        </table>
      </div>
    `;
	}

	renderOwner(row) {
		return html`
    <tr>
      <td>${row.name}</td>
      <td>${row.advisor_name}</td>
      <td>${row.cpr}</td>
      <td>${row.confirmed}</td>
      <td>${row.order_count}</td>
      <td>  
          ${row.address1}<br>
          ${row.address2}<br>
          ${row.zipcity}<br>
          ${row.country_name}
     </td>
      <td>${row.startdate}<br> ${row.enddate}</td>
      <td>${row.admin_comment}</td>
      <td>${row.company_name}</td>
      <td>${row.company_cvr}</td>
      <td>${row.txt_reg_date}</td>
    </tr>`;
	}

	connectedCallback() {
		super.connectedCallback();
		let todate = localStorage.getItem("ro-todate");
		const fromdate = localStorage.getItem("ro-fromdate");

		if (!todate) {
			todate = DateFormatter.format(new Date(), "yyyy-mm-dd");
			localStorage.setItem("ro-todate", todate);
		}
		if (!fromdate) {
			localStorage.setItem("ro-fromdate", todate);
		}
		this.query1 = localStorage.getItem("ro-query1");
		this.query2 = localStorage.getItem("ro-query2");
	}

	async refresh() {
		const parms = this.shadowRoot.querySelector("#parms").value;
		const dates = this.shadowRoot.querySelector("#created").value;
		const submit_button = this.shadowRoot.querySelector("#submit_button");
		submit_button.loading = true;
		localStorage.setItem("ro-fromdate", dates.from);
		localStorage.setItem("ro-todate", dates.to);
		localStorage.setItem("ro-query1", parms.query1);
		localStorage.setItem("ro-query2", parms.query2);

		this.query1 = parms.query1;
		this.query2 = parms.query2;
		const q1 = `?from=${dates.from}&to=${dates.to}&query1=${
			this.query1
		}&query2=${this.query2}&cprmissing=${parms.cprmissing || ""}`;
		const q2 = `&confirmed=${parms.confirmed || ""}&unconfirmed=${
			parms.unconfirmed || ""
		}&notconfirmed=${parms.notconfirmed || ""}`;

		try {
			this.rows = await api.get(`/aml/realowners${q1}${q2}`);
		} catch (err) {
			Sentry.captureException(err);
			toast("Det lykkedes ikke at hente data");
		}

		submit_button.loading = false;
	}
}

customElements.define("aml-realowner-view", AMLRealOwnerView);
