import { LitElement, html } from "lit";

import sharedStyles from "../../styles/shared.js";
import tableStyles from "../../styles/tables.js";

export default class OrdersView extends LitElement {
	render() {
		return html`
    ${sharedStyles}
    ${tableStyles}
      <header class="horizontal-menu-header">
        <router-link to="/orders" exact>
          <a>List</a>
        </router-link>
        <router-link to="/orders/move">
          <a>Move positions</a>
        </router-link>
        <router-link to="/orders/sell">
          <a>Sell</a>
        </router-link>
        <router-link to="/orders/pension">
           <a>Pension</a>
        </router-link>
        <router-link to="/orders/report">
          <a>Report</a>
        </router-link>
        <router-link to="/orders/redemptions">
          <a>Redemptions (standing orders)</a>
        </router-link>
      </header>
      <slot></slot>
    `;
	}
}

customElements.define("orders-view", OrdersView);
