import { LitElement, html } from "lit";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import inputStyles from "../../styles/input.js";
import sharedStyles from "../../styles/shared.js";
import { DateFormatter } from "../../formatting/dateformats.js";

export default class ReportCompanyView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			status_message: {
				type: String,
			},
		};
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1400px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>Company Addresses</h1>
        </div>
        <fm-form id = "parms" class="form-grid" @submit="${this.refresh}">
        <div class="form-field">
          <label>Created
            <fm-date-range id="created" name="created"  names="companies-" ></fm-date-range>
           </label>
        </div>
        <div class="form-field">
        <label>Months since last download
          <input type="text"  name="months" style="width:80px;">
        </label>
      </div>

        <div class="form-field">
          <label>Search
            <input type="search"  name="query" value="${this.query || ""}" class="search-input" style="width:100%">
          </label>
        </div>
         <fm-button type="submit" id="submit_button" class="btn" style="max-width:80px">Refresh</fm-button>
      </fm-form>
        <div class="card-block">
          ${this.status_message}
        </div>
        <table>
        <thead>
        <th>CVR</th>
        <th>Name</th>
        <th>Approved</th>
        <th>Custodies</th>
        <th>Official Name</th>
        <th>Address</th>
        <th>Latest download</th>
        </thead>
        <tbody>

          ${this.rows.map(
						(r) => html`
          <tr>
            <td>
              <router-link to="/accounts/users/${r.id}">
                    <a>${r.cvr}</a>
              </router-link>
            </td>
            <td>${r.name}</td>
            <td>${r.approved_yn}</td>
            <td>${r.custody_count}</td>
            <td>${r.official_name}</td>
            <td>${r.address1} ${r.address2} ${r.zipcity}</td>
            <td>${r.latest_download}</td>
           </tr>
          `,
					)}
        </tbody>
        </table>
      </div>
    `;
	}

	constructor() {
		super();
		this.rows = [];
		this.query = "";
		this.status_message = "";
		this.refresh = this.refresh.bind(this);
		const todate = DateFormatter.format(new Date(), "yyyy-mm-dd");
		localStorage.setItem("companies-todate", todate);
		const fromdate = localStorage.getItem("companies-fromdate");
		if (!fromdate) {
			localStorage.setItem("companies-fromdate", todate);
		}
		this.query = localStorage.getItem("companies-query");
	}

	async refresh() {
		const parms = this.shadowRoot.querySelector("#parms").value;
		const dates = this.shadowRoot.querySelector("#created").value;
		const submit_button = this.shadowRoot.querySelector("#submit_button");
		submit_button.loading = true;
		localStorage.setItem("companies-fromdate", dates.from);
		localStorage.setItem("companies-todate", dates.to);
		localStorage.setItem("companies-query", parms.query);
		this.query = parms.query;
		this.rows = await api.get(
			`/users/companies?from=${dates.from}&to=${dates.to}&query=${this.query}&months=${parms.months}`,
		);
		submit_button.loading = false;
	}
}

customElements.define("report-company-view", ReportCompanyView);
