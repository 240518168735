import { LitElement } from "lit";
import * as Sentry from "@sentry/browser";
import { ext_render } from "./orders-edit-render.js";
import { toast } from "../../utils.js";
import api from "../../api/index.js";

export default class OrdersEditView extends LitElement {
	static get properties() {
		return {
			row: {
				type: Object,
			},
			order_id: {
				type: Number,
			},
		};
	}

	constructor() {
		super();
		this.row = { transactions: [], status_change: [] };
		this.render = ext_render.bind(this);
		this.saveTran = this.saveTran.bind(this);
		this.statuschange = this.statuschange.bind(this);
	}

	async connectedCallback() {
		super.connectedCallback();
		this.row = await api.get(`/orders/${this.order_id}`);
	}

	async saveTran(ev) {
		const target = ev.target;
		const id = target.value.id;
		try {
			await api.put(`/orders/tran/${id}`, target.value);
			toast("Transaction updated");
		} catch (err) {
			Sentry.captureException(err);
			console.log(err);
			toast(`Update Error ${JSON.stringify(err)}`);
		}
	}

	async statuschange(event) {
		const target = event.target;
		const id = target.getAttribute("data-id");
		const status_id = target.getAttribute("data-status");
		try {
			await api.put(`/orders/${id}/status`, { status_id });
			toast("Status changed");
		} catch (err) {
			Sentry.captureException(err);
			toast(`Change Error ${JSON.stringify(err)}`);
		}
	}
}

customElements.define("orders-edit-view", OrdersEditView);
