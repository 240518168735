import { LitElement, css, html } from "lit";
import { customElement } from "lit/decorators.js";

declare global {
	interface HTMLElementTagNameMap {
		"fm-progress-dots": ProgressDots;
	}
}

@customElement("fm-progress-dots")
export class ProgressDots extends LitElement {
	static styles = css`
        :host {
            display: inline-flex;
            gap: 16px;
        }

        .progress-dot {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #123;
            animation-name: fall;
            animation-duration: 1.2s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
        }

        .progress-dot:nth-child(2) {
            animation-delay: 0.2s;
        }

        .progress-dot:nth-child(3) {
            animation-delay: 0.4s;
        }

        @keyframes fall {
            0% {
                transform: translateY(-15px);
                opacity: 0;
            }

            25%, 50%, 75% {
                transform: translateY(0);
                opacity: 1;
            }

            100% {
                transform: translateY(15px);
                opacity: 0;
            }
        }
    `;

	render() {
		return html`
            <div class="progress-dot"></div>
            <div class="progress-dot"></div>
            <div class="progress-dot"></div>
        `;
	}
}
