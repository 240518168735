import { LitElement, html } from "lit";

import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { DateFormatter } from "../../formatting/dateformats.js";

export default class UserRealOwnerView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			relationtypes: {
				type: Array,
			},
		};
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1500px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>Real Owners</h1>
       </div>

       <fm-form id = "parms" class="form-grid" @submit="${this.refresh}">
         <div class="form-field">
           <label>Created
             <fm-date-range id="created" name="created"  names="ro-" ></fm-date-range>
            </label>
         </div>

         <div class="form-field">
           <label>Search1
             <input type="search"  name="query1" value="${this.query1 || ""}" class="search-input" style="width:100%">
           </label>
         </div>
         <div class="form-field">
         <label>Search2
           <input type="search"  name="query2" value="${this.query2 || ""}" class="search-input" style="width:100%">
         </label>
       </div>

         <fm-button type="submit" id="submit_button" class="btn" style="max-width:80px">Refresh</fm-button>
       </fm-form>

        <table>
          <thead>
            <tr>
              <th>Account name</th>
            </tr>
            </thead>
            <tbody>
            ${this.rows.map((r) => this.renderAccount(r))}
            </tbody>
        </table>
      </div>
    `;
	}

	renderAccount(r) {
		return html`
 <table>
 <thead>
    <th>Name</th>
    <th>CPR</th>
    <th>Kategori</th>
    <th>Værdi</th>
    <th>Start</th>
    <th>End</th>
    <th>Comment</th>
    <th>Created</th>
  </tr>
</thead>
<tbody>
  ${r.names.map(
		(row) => html`
    <tr>
      <td>${row.name}</td>
      <td>${row.cpr}</td>
      <td>${row.code}</td>
      <td>${row.val}</td>
      <td>${row.startdate}</td>
      <td>${row.enddate}</td>
      <td>${row.admin_comment}</td>
      <td>${row.txt_reg_date}</td>
    </tr>
  `,
	)}
</tbody>
</table>`;
	}

	constructor() {
		super();
		this.rows = [];
		this.query = "";
		this.refresh = this.refresh.bind(this);
		let todate = localStorage.getItem("ro-todate");
		const fromdate = localStorage.getItem("ro-fromdate");
		if (!todate) {
			todate = DateFormatter.format(new Date(), "yyyy-mm-dd");
			localStorage.setItem("ro-todate", todate);
		}
		if (!fromdate) {
			localStorage.setItem("ro-fromdate", todate);
		}
		this.query1 = localStorage.getItem("eo-query1");
		this.query2 = localStorage.getItem("ro-query2");
	}

	connectedCallback() {
		super.connectedCallback();
		let todate = localStorage.getItem("ro-todate");
		const fromdate = localStorage.getItem("ro-fromdate");

		if (!todate) {
			todate = DateFormatter.format(new Date(), "yyyy-mm-dd");
			localStorage.setItem("ro-todate", todate);
		}
		if (!fromdate) {
			localStorage.setItem("ro-fromdate", todate);
		}
		this.query1 = localStorage.getItem("ro-query1");
		this.query2 = localStorage.getItem("ro-query2");
	}

	async refresh() {
		const parms = this.shadowRoot.querySelector("#parms").value;
		const dates = this.shadowRoot.querySelector("#created").value;
		const submit_button = this.shadowRoot.querySelector("#submit_button");
		submit_button.loading = true;
		localStorage.setItem("ro-fromdate", dates.from);
		localStorage.setItem("ro-todate", dates.to);
		localStorage.setItem("ro-query1", parms.query1);
		localStorage.setItem("ro-query2", parms.query2);

		this.query1 = parms.query1;
		this.query2 = parms.query2;
		this.rows = await api.get(
			`/relations/realowners?from=${dates.from}&to=${dates.to}&query1=${this.query1}&query2=${this.query2}`,
		);

		submit_button.loading = false;
	}
}

customElements.define("user-realowner-view", UserRealOwnerView);
