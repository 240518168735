import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { date } from "../../formatting/dateformats.js";
import { toast, addDays } from "../../utils.js";
import { DateFormatter } from "../../formatting/dateformats.js";

export default class ImageIDListView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
		};
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1400px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h2>Image-ID</h2>
          <fm-form id = "parms" class="form-grid" @submit="${this.refresh}">
           <div class="form-field">
             <label>Created
               <fm-date-range id="created" name="created"  names="images-" ></fm-date-range>
             </label>
           </div>
           <div class="form-field"></div>
           <div class="form-field">
            <label>Expire
               <fm-date-range id="expire" name="expire"  names="imgexpire-" ></fm-date-range>
            </label>
           </div>
           <div class="form-field">
             <label><input type="checkbox" name="missing_date">Missing expiredate</label>
             <label><input type="checkbox" name="missing_id"> Missing image</label>
           </div>
           <div class="form-field">
             <label>Search
              <input type="search"  name="query" value="${this.query || ""}" class="search-input" style="width:100%">
            </label>
           </div>
           <fm-button type="submit" id="submit_button" class="btn" style="max-width:80px">Refresh</fm-button>
         </fm-form>

        </div>
        </div>
        <table>
        <thead>
        <tr>
          <th>Account</th>
          <th>Advisor</th>
          <th>User</th>
          <th>Image</th>
          <th></th>
         <th>Expire</th>
        </tr>
        </thead>
        <tbody>
        ${(this.rows || []).map((r, i) => this.renderRow(r, i))}
        </tbody>
        </table>
    `;
	}

	renderRow(r, i) {
		return html`
   <tr>
    <td>${r.account_name}</td>
    <td>${r.advisor_name}</td>
    <td><router-link to="/accounts/${
			r.account_id
		}/imageid/"><a href="/accounts/${r.account_id}/imageid/"> ${r.user_name}</a></router-link></td>
    <td>
    ${this.renderFiles(r.files, i)}
    </td>
   </tr>
   `;
	}

	renderFiles(fs, i) {
		return html`
 <table>
 ${(fs || []).map((f) => this.renderFile(f, i))}
 </table>
 `;
	}

	renderFile(f, i) {
		if (i < 10) {
			return html`
    <tr>
    <td>
    <object width="240" height="240" type="${f.mimetype}" data="${
				api.baseURL
			}/files/u/${f.file_id}?t=${api.token}"></object>
    <a target="_new"  href="${api.baseURL}/files/u/${f.file_id}?t=${api.token}">Show in new tab</a>
    </td>
    <td>
    expires: <input type="date" value="${f.expiredate || ""}" id="expire_${
				f.file_id
			}"> 
    </td>
    <td>
    <button class="button" @click="${this.updateFile}"  data-index="${f.file_id}">Save</button>
    </td>
    <td>
    <input type="checkbox"  @change="${this.changeSurveillance}"  ?checked="${
				f.surveillance_yn === "Y"
			}" data-index="${f.file_id}">Surveillance
    </td>

    </tr>`;
		} else {
			return html`
    <tr>
    <td>
    <a target="_new"  href="${api.baseURL}/files/u/${f.file_id}?t=${api.token}">Show in new tab</a>
    </td>
    <td>
    expires: <input type="date" value="${f.expiredate || ""}" id="expire_${
				f.file_id
			}"> 
    </td>
    <td>
    <button class="button" @click="${this.updateFile}"  data-index="${f.file_id}">Save</button>
    </td>
    </tr>`;
		}
	}

	constructor() {
		super();
		this.rows = [];
		this.query = "";
		this.refresh = this.refresh.bind(this);
		this.updateFile = this.updateFile.bind(this);
		this.changeSurveillance = this.changeSurveillance.bind(this);
		const today = new Date();
		this.fromdate = date(addDays(today, -5));
	}

	connectedCallback() {
		super.connectedCallback();

		let todate = localStorage.getItem("images-todate");
		let fromdate = localStorage.getItem("images-fromdate");

		if (!todate) {
			todate = DateFormatter.format(new Date(), "yyyy-mm-dd");
			localStorage.setItem("images-todate", todate);
		}
		if (!fromdate) {
			localStorage.setItem("images-fromdate", todate);
		}
		this.query = localStorage.getItem("images-query");

		todate = localStorage.getItem("imgexpire-todate");
		fromdate = localStorage.getItem("imgexpire-fromdate");

		if (!todate) {
			todate = DateFormatter.format(new Date(), "yyyy-mm-dd");
			localStorage.setItem("imgexpire-todate", todate);
		}
		if (!fromdate) {
			localStorage.setItem("imgexpire-fromdate", todate);
		}
	}

	async fetch() {
		const parms = this.shadowRoot.querySelector("#parms").value;
		const created = this.shadowRoot.querySelector("#created").value;
		const expire = this.shadowRoot.querySelector("#expire").value;
		const missing_date = parms.missing_date ? "true" : "false";
		const missing_id = parms.missing_id ? "true" : "false";

		localStorage.setItem("images-fromdate", created.from);
		localStorage.setItem("images-todate", created.to);
		localStorage.setItem("imgexpire-fromdate", expire.from);
		localStorage.setItem("imgexpire-todate", expire.to);
		localStorage.setItem("imgexpire-missing-date", missing_date);
		localStorage.setItem("imgexpire-missing-id", missing_id);

		localStorage.setItem("images-query", parms.query);
		this.rows = [];
		const q_parms = `createdfrom=${created.from}&createdto=${created.to}&expirefrom=${expire.from}&expireto=${expire.to}&expiremissing=${missing_date}&idmissing=${missing_id}&query=${parms.query}`;
		this.rows = await api.get(`/accounts/imageid?${q_parms}`);
	}

	async refresh(event) {
		const target = event.target;
		target.loading = true;
		await this.fetch();
		target.loading = false;
	}

	async updateFile(event) {
		const target = event.target;
		const file_id = target.getAttribute("data-index");
		const expire_input = this.shadowRoot.querySelector(`#expire_${file_id}`);
		if (expire_input) {
			try {
				const response = await api.put(`/users/files/${file_id}`, {
					expiredate: expire_input.value,
				});
				if (response.status === "OK") {
					toast("Expiredate saved");
					await this.fetch();
				} else {
					toast(response.message);
				}
			} catch (err) {
				Sentry.captureException(err);
				toast("Error saving expiredate");
			}
		} else {
			toast("Internal Error: expiredate-input not found");
		}
	}

	async changeSurveillance(event) {
		const target = event.target;
		const file_id = target.getAttribute("data-index");
		const checked = target.checked;
		const s = checked ? "Y" : "N";
		try {
			const response = await api.put(`/users/files/${file_id}/surveillance`, {
				surveillance_yn: s,
			});
			if (response.status === "OK") {
				toast("Surveillance changed");
				await this.fetch();
			} else {
				toast(response.message);
			}
		} catch (err) {
			Sentry.captureException(err);
			toast(JSON.stringify(err));
		}
	}
}

customElements.define("imageid-list-view", ImageIDListView);
