import { css, html } from "lit";

export const styles = css`
  :root {
    --primary-color: #00b96f;
    font-family: 'Roboto', sans-serif;
	  font-size: 14px;
    color: #333;
	  -webkit-font-smoothing: antialiased;
  }

  * {
    box-sizing: border-box;
  }

  :host {
    display: block;
  }

  router-link a {
    text-decoration: none;
    color: inherit;
  }

  router-link[active] a {
    color: var(--primary-color);
  }

  h1 {
    font-weight: 500;
    font-size: 20px;
    margin: 0;
  }

  h2 {
    font-weight: 500;
    font-size: 18px;
    margin: 0;
  }

  .card {
    background: white;
    border: 1px solid #ddd;
    border-radius: 2px;
    margin: 16px;
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    min-height: 56px;
    border-bottom: 1px solid #ddd;
  }

  .card-block {
    display: flex;
    min-height: 64px;
    border-bottom: 1px solid #ddd;
  }

  .btn {
    cursor: pointer;
    margin: 0;
    padding: 12px 16px;
    border: none;
    border-radius: 2px;
    background: var(--primary-color);
    color: #fff;
    text-align: center;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    white-space: nowrap;
    transition-duration: 0.3s;
    transition-property: background-color, box-shadow;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    box-shadow:
      0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12),
      0 3px 1px -2px rgba(0, 0, 0, 0.2);
  }


  .btn:not([disabled]):hover,
  .btn:not([disabled]):focus {
    box-shadow: 
      0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12),
      0 2px 4px -1px rgba(0, 0, 0, 0.4);
  }

  .btn--light {
    background: none;
    color: #111;
    box-shadow: none;
  }

  .btn--light:not([disabled]):hover,
  .btn--light:not([disabled]):focus {
    background: #f2f2f2;
    box-shadow: none;
  }

  .btn--small {
    padding: 8px 12px;
    font-size: 10px;
  }

  .btn--xsmall {
    padding: 4px 8px;
    font-size: 8px;
  }

  .unselectable {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  }


  .horizontal-menu-header {
    display: flex;
    align-items: center;
    height: 64px;
    width: 100%;
    padding: 0 16px;
    background: white;
    border-bottom: 1px solid #ddd;
  }

  .horizontal-menu-header a {
    color: #666;
    font-size: 15px;
    font-weight: 500;
    margin: 0 8px;
    padding: 6px;
  }

  [disabled] {
    background-color: gray;
    cursor: not-allowed;
  }

  @media print {
    .no-print {
      display: none
    }

    .horizontal-menu-header {
      display: none
    }

    router-link {
      display: none
    }

    .search-input {
      display: none
    }
  }
`;

export default html`<style>${styles}</style>`;
