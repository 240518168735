import { LitElement, html } from "lit";

import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { DateFormatter } from "../../formatting/dateformats.js";
import { sort } from "../../utils.js";

export default class OrdersPensionView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			ordertypes: {
				type: Array,
			},
			order_id: {
				type: Number,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.query = "";
		this.fetch = this.fetch.bind(this);
		this.refresh = this.refresh.bind(this);
		this.sort = this.sort.bind(this);
		this.nota = this.nota.bind(this);
		const todate = DateFormatter.format(new Date(), "yyyy-mm-dd");
		localStorage.setItem("pension-todate", todate);
		const fromdate = localStorage.getItem("pension-fromdate");
		if (!fromdate) {
			localStorage.setItem("pension-fromdate", todate);
		}
		this.query = localStorage.getItem("pension-query");
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1400px;
      }

      .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
      }
  
      tr.l1 td {border:none}

      </style>
     
      <div class="card">
      <div class="card-header">
       <h2>Pension Orders</h2>
       </div>
       <fm-form id = "parms" class="form-grid" @submit="${this.refresh}">
        <div class="form-field">
        <label>Period
          <fm-date-range id="dates" name="dates"  names="pension-" ></fm-date-range>
          </label>
        </div>
        <div class="form-field">
            <label>Search
              <input type="search"  name="query" value="${this.query || ""}" class="search-input" style="width:100%">
            </label>
         </div>
       <div class="form-field">
            <fm-button type="submit" id="submit_button" class="btn" style="max-width:80px">Refresh</fm-button>
        </div>
      </fm-form>
    </div>
    <div class="card">
         <table>
          <thead>
            <tr>
              <th class="numeric">ID</th>
              <th>Deleted(Y/N)</th>
              <th>Bank</th>
              <th>CustodyOwner</th>
              <th>Custody</th>
              <th>Contact</th>
              <th>Valuedate</th>
              <th>Amount</th>
              <th>Signed</th>
              <th>Download</th>
             </tr>
          </thead>
          <tbody>
            ${this.rows.map(
							(row) => html`
              <tr class="l1">
                <td class="numeric"> ${row.id} </td>
                <td>${row.deleted_yn}</td>
                <td>${row.issuer_name}</td>
                <td>${row.custody_owner}</td>
                <td>${row.custody_code}</td>
                <td>${row.contact_name}<br>${row.contact_email}</td>
                <td>${row.valuedate}</td>
                <td>${row.amount}</td>
                <td>${row.txt_reg_date}</td>
                <td class="numeric">
                  <fm-button data-id="${row.id}" class="btn" @click="${this.nota}" style="display:inline;max-width:80px">Nota</fm-button>
                </td>
              </tr>
            `,
						)}
          </tbody>
        </table>
      </div>
   `;
	}

	async fetch() {
		const parms = this.shadowRoot.querySelector("#parms").value;
		const dates = this.shadowRoot.querySelector("#dates").value;
		localStorage.setItem("pension-fromdate", dates.from);
		localStorage.setItem("pension-todate", dates.to);
		localStorage.setItem("pension-query", parms.query);
		this.query = parms.query;
		this.rows = await api.get(
			`/orders/pension?from=${dates.from}&to=${dates.to}&query=${parms.query}`,
		);
	}

	async refresh() {
		const submit_button = this.shadowRoot.querySelector("#submit_button");
		submit_button.loading = true;
		await this.fetch();
		submit_button.loading = false;
	}

	sort(event) {
		const key = event.target.getAttribute("data-key");
		this.rows = sort(this.rows, key);
	}

	async nota(event) {
		const target = event.target;
		const id = target.getAttribute("data-id");
		const PDF = await API.get(`/nota/${id}/signed`);
		const a = document.createElement("a");
		a.setAttribute("href", `data:application/pdf;base64,${PDF}`);
		a.setAttribute("download", "fm_pension_order.pdf");
		a.click();
		target.loading = false;
	}
}

customElements.define("orders-pension-view", OrdersPensionView);
