import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import inputStyles from "../../styles/input.js";
import sharedStyles from "../../styles/shared.js";

export default class ReportSQLView extends LitElement {
	static get properties() {
		return {
			report: {
				type: Object,
			},
			error: {
				type: String,
			},
			sqlCommandId: {
				type: Number,
			},
			sqlCommand: {
				type: Object,
			},
		};
	}

	constructor() {
		super();
		this.report = { rows: [], fields: [] };
		this.savedSqlCommandSelected = this.savedSqlCommandSelected.bind(this);
		this.runCommandClick = this.runCommandClick.bind(this);
		this.error = "";
		this.sqlCommandId = null;
		this.sqlCommand = null;
	}

	render() {
		return html`
      ${sharedStyles} ${tableStyles} ${inputStyles}
      <style>
        :host {
          display: block;
          margin: 24px auto;
          max-width: 1400px;
        }
      </style>
      <div class="card">
        <div class="card-header">
          <h2>SQL</h2>
        </div>
        <fm-form id="parms" class="reportform no-print">
          <div class="form-space">
            <div style="display: flex; column-gap: 16px; align-items: center;">
              <fm-autocomplete
                url="/reports/sqlcmd"
                id="sqlCommandId"
                @select="${this.savedSqlCommandSelected}"
                name="sqlCommandId"
                select="id"
                display="code"
              ></fm-autocomplete>
              <fm-button
                id="submit_button"
                class="btn submit unselectable"
                style="max-height:20px;"
                @click="${this.runCommandClick}"
                ?disabled="${this.sqlCommandId == null}"
                >Run command</fm-button
              >
            </div>
            ${
							this.sqlCommand != null
								? html`
                  <div>
                    <strong>Beskrivelse</strong>: ${this.sqlCommand.description}
                  </div>
                  <div>
                    <code> ${this.sqlCommand.command} </code>
                  </div>
                `
								: ""
						}
          </div>
          <div class="form-space"></div>
        </fm-form>
      </div>
      ${this.renderReport()}
    `;
	}

	renderReport() {
		if (this.error) {
			return html`<div clas="card-block">${this.error}</div>`;
		} else {
			return html`<table>
        <thead>
          ${(this.report.fields || []).map((f) => html`<th>${f.name}</th>`)}
        </thead>
        <tbody>
          ${(this.report.rows || []).map(
						(r) =>
							html`<tr>
                ${this.renderRow(r)}
              </tr>`,
					)}
        </tbody>
      </table>`;
		}
	}

	renderRow(r) {
		return html`${(r || []).map((f) => html`<td>${f}</td>`)}`;
	}

	async savedSqlCommandSelected() {
		this.sqlCommandId = this.shadowRoot.querySelector("#sqlCommandId").value;
		this.sqlCommand = await api.get(`/reports/sqlcmd/${this.sqlCommandId}`);
	}

	async runCommandClick(event) {
		this.error = "";
		const target = event.target;
		target.loading = true;

		try {
			this.report = await api.get(
				`/reports/sqlcmd/${this.sqlCommandId}/results`,
			);
			target.loading = false;
		} catch (err) {
			Sentry.captureException(err);
			target.loading = false;
			const resp = await err.json();
			this.error = resp.message;
		}
	}
}

customElements.define("report-sql-view", ReportSQLView);
