import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import store from "../../store/index.js";
import { setTitle } from "../../store/actions.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { addDays } from "../../utils.js";
import { date } from "../../formatting/dateformats.js";
import { toast } from "../../utils.js";

export default class ReportMessagesView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.refresh = this.refresh.bind(this);
		const today = new Date();
		this.todate = date(addDays(today, 5));
		this.fromdate = date(today);
		localStorage.setItem("system-message-todate", this.todate);
		localStorage.setItem("system-message-fromdate", this.fromdate);
	}

	connectedCallback() {
		super.connectedCallback();
		store.dispatch(setTitle("System messages"));
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1400px;
      }
      .tiny-button {
          min-width: 50px;
          font-size:10px;
          padding: 4px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>System messages</h1>
        </div>
        <div class="card-block">
            <fm-form id="parms" class="reportform no-print" @submit="${
							this.refresh
						}">
            <div class="form-space">
              <label>Created
                <fm-date-range id="dates" name="dates" names="system-message-"  ></fm-date-range>
             </label>
            </div>
            
            <div class="form-space">
                <fm-button id="submit_button" type="submit" class="button submit">Refresh</fm-button>
            </div>
            </fm-form>
    </div>
    <div class="card-block">
            <table>
                <thead>
                    <tr>
                        <th>Created</th>
                        <th>Type</th>
                        <th>Message</th>
                    </tr>   
                </thead>
                <tbody>
                ${this.rows.map(
									(r) => html`
                    <tr>
                    <td>${r.txt_reg_date}</td>
                    <td>${r.messagetype}</td>
                    <td>${r.message}</td>
                    </tr>`,
								)}
                </tbody>
            </table>
        </div>
    </div>
    `;
	}

	async search() {
		const dt = this.shadowRoot.querySelector("#dates").value;
		try {
			this.rows = await api.get(
				`/reports/messages?from=${dt.from}&to=${dt.to}`,
			);
		} catch (err) {
			Sentry.captureException(err);
			toast("Det lykkedes ikke at hente rapporten");
		}
	}

	async refresh() {
		this.shadowRoot.querySelector("#submit_button").loading = true;
		await this.search();
		this.shadowRoot.querySelector("#submit_button").loading = false;
	}
}

customElements.define("reports-messages-view", ReportMessagesView);
