import { ThreadsListView } from "./threads-list-view.js";
import { ThreadView } from "./thread-view.js";
import { ThreadNewView } from "./thread-new-view.js";
import { MessagesView } from "./messages-view.js";
import { RouteOptions } from "runway";

export default {
	path: "messages",
	component: MessagesView,
	children: [
		{
			path: "",
			exact: true,
			component: ThreadsListView,
		},
		{
			path: "new",
			component: ThreadNewView,
		},
		{
			path: ":thread_id",
			component: ThreadView,
			properties: ({ parameters }) => ({
				thread_id: parameters.get("thread_id"),
			}),
		},
	],
} satisfies RouteOptions;
