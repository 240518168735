import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import store from "../../store/index.js";
import { setTitle } from "../../store/actions.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { addDays, sort, toCsvF } from "../../utils.js";
import { money, quantity } from "../../formatting/numberformats.js";
import { date } from "../../formatting/dateformats.js";
import { toast } from "../../utils.js";

export default class ReportStatementView extends LitElement {
	static get properties() {
		return {
			statement: {
				type: Object,
			},
		};
	}

	constructor() {
		super();
		this.statement = { primo: [], ultimo: [], tran: [] };
		this.custody = {};
		this.refresh = this.refresh.bind(this);
		this.onDownload = this.onDownload.bind(this);
		this.onNota = this.onNota.bind(this);
		const today = new Date();
		this.fromdate = date(addDays(today, -10));
		this.todate = date(today);
	}

	connectedCallback() {
		super.connectedCallback();
		store.dispatch(setTitle("Kontoudtog"));
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1400px;
      }
      .tiny-button {
          min-width: 50px;
          font-size:10px;
          padding: 4px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>Kontoudtog</h1>
        </div>
        <div class="card-block">
            <fm-form id="parms" class="reportform no-print" @submit="${
							this.refresh
						}">
            <div class="form-space">
                <label>Konto</label>
                <fm-autocomplete url="/lov/custodies" id="custody_id" select="id" display="name"></fm-autocomplete>
            </div>
            <div class="form-space">
                <label>Fra (handelsdato)</label>
                <input type="date"  class="date-input" name="fromdate" value="${
									this.fromdate
								}" >
            </div>
            <div class="form-space">
                <label>Til (statusdato)</label>
                <input type="date"  class="date-input" name="todate" value="${
									this.todate
								}" >
            </div>
            <div class="form-space">
                <fm-button id="submit_button" type="submit" class="button submit">Vis</fm-button>
            </div>
            <div class="form-space">
            <fm-button id="download_button" class="btn" @click="${
							this.onDownload
						}">Download</fm-button>
        </div>

            </fm-form>
        </div>
    </div>
    ${this.renderHeader(this.custody)}
    ${this.renderStatus(this.statement.ultimo)}
    ${this.renderTran(this.statement.tran)}
    ${this.renderStatus(this.statement.primo)}
    `;
	}

	renderHeader(c) {
		if (this.statement.tran.length > 0) {
			return html`
     <div class="card">
        <div class="card-header">
            <h2>Kontoudtog for ${c.custody_code} ${c.custodytype_code} ${c.owner_name}</h2>
            <h2>Perioden ${this.fromdate} til ${this.todate}</h2>
        </div>
     </div>
     `;
		} else {
			return "";
		}
	}

	renderStatusRow(r) {
		this.valueSum += r.beloeb;
		return html`
     <tr>
        <td>${r.dato}</td>
        <td>${r.inv_bevis}</td>
        <td>${quantity(r.antal_stk)}</td>
        <td class="numeric">${money(r.beloeb)}</td>
     </tr>
     `;
	}

	renderStatus(rows) {
		this.valueSum = 0;
		if (rows.length === 0) {
			return "";
		}
		return html`
  <div class="card">
    <div class="card-header">
     <h2>Status pr. ${rows[0].dato}</h2>
    </div>
    <div class="card-block">
        <table><thead><tr><th>Dato</th><th>Inv.bevis</th><th>Beholdning</th><th class="numeric">Værdi</th></tr></thead>
        ${(rows || []).map((r) => this.renderStatusRow(r))}
        <tfoot>
            <tr>
                <td colspan="2">Ialt</td><td></td><td class="numeric">${money(
									this.valueSum,
								)}</td>
            </tr>
        </tfoot>
        </table>
    </div>
</div>
 `;
	}

	renderTranRow(r) {
		const fundorder_id = this.fundorder_id;
		this.fundorder_id = r.fundorder_id;
		if (r.fundorder_id === fundorder_id) {
			return html`
        <tr>
        <td>${r.stmt_date}</td>
        <td>${r.stmt_text}</td>
        <td>${r.security_name}</td>
        <td class="numeric">${quantity(r.quantity)}</td>
        <td class="numeric">${money(r.amountqc)}</td>
        <td class="numeric">${money(r.taxqc)}</td>
        <td class="numeric">${quantity(r.position_after)}</td>
        </tr>
        `;
		} else {
			return html`
        <tr>
        <td>${r.stmt_date}</td>
        <td><button class="button tiny-button"  data-id="${
					r.fundorder_id
				}" @click="${this.onNota}">Nota</button>
             ${r.stmt_text} 
        </td>
        <td>${r.security_name}</td>
        <td class="numeric">${quantity(r.quantity)}</td>
        <td class="numeric">${money(r.amountqc)}</td>
        <td class="numeric">${money(r.taxqc)}</td>
        <td class="numeric">${quantity(r.position_after)}</td>
        </tr>
        `;
		}
	}

	renderTran(rows) {
		if (rows.length === 0) {
			return "";
		}
		this.fundorder_id = 0;
		return html`
     <div class="card">
        <div class="card-header">
            <h2>Bevægelser i perioden</h2>
        </div>
        <div class="card-block">
           <table>
            <thead>
            <tr>
                <th>Dato</th>
                <th>Bevægelse</th>
                <th>Inv.bevis</th>
                <th class="numeric">Antal stk.</th>
                <th class="numeric">Beløb</th>
                <th class="numeric">Skat</th>
                <th class="numeric">Beholdning</th>
            </tr>
            </thead>
            ${(rows || []).map((r) => this.renderTranRow(r))}
           </table>
        </div>
  </div>`;
	}

	async search() {
		const form = this.shadowRoot.querySelector("#parms").value;
		const custody_id = this.shadowRoot.querySelector("#custody_id").value;
		this.custody = await api.get(`/custodies/${custody_id}`);
		this.fromdate = form.fromdate;
		this.todate = form.todate;
		this.statement = await api.get(
			`/custodies/${custody_id}/statement?from=${form.fromdate}&to=${form.todate}`,
		);
	}

	async refresh() {
		this.shadowRoot.querySelector("#submit_button").loading = true;
		await this.search();
		this.shadowRoot.querySelector("#submit_button").loading = false;
	}

	async onNota(event) {
		const target = event.target;
		target.loading = true;

		try {
			const id = target.getAttribute("data-id");
			const PDF = await api.get(`/nota/${id}`);

			const a = document.createElement("a");
			a.setAttribute("href", `data:application/pdf;base64,${PDF.nota}`);
			a.setAttribute("download", "fundmarket_nota.pdf");
			a.click();
			target.loading = false;
		} catch (err) {
			Sentry.captureException(err);
			console.log(err);
			toast(err);
			target.loading = false;
		}
	}

	sort(event) {
		const key = event.target.getAttribute("data-key");
		this.rows = sort(this.rows, key);
	}

	async onDownload() {
		this.shadowRoot.querySelector("#download_button").loading = true;
		await this.search();

		const csvHeaders = [
			"dato",
			"tekst",
			"inv.bevis",
			"Antal",
			"Beløb",
			"Beholdning",
		];
		const csvFields1 = [
			"dato",
			"tekst",
			"inv_bevis",
			"antal_stk",
			"beloeb",
			"beholdning_efter",
		];
		const csvFields2 = [
			"stmt_date",
			"stmt_text",
			"security_name",
			"quantity",
			"amountqc",
			"position_after",
		];
		const csvFormats = ["t", "t", "t", "q", "m", "q"];

		let txtdata = toCsvF(
			csvHeaders,
			csvFields1,
			csvFormats,
			this.statement.primo,
		);
		txtdata = txtdata + toCsvF([], csvFields2, csvFormats, this.statement.tran);
		txtdata =
			txtdata + toCsvF([], csvFields1, csvFormats, this.statement.ultimo);

		txtdata = btoa(txtdata);

		const a = document.createElement("a");
		a.setAttribute("href", `data:text/csv;base64,${txtdata}`);
		a.setAttribute("download", "fm_kontoudtog.csv");
		a.click();
		this.shadowRoot.querySelector("#download_button").loading = false;
	}
}

customElements.define("reports-statement-view", ReportStatementView);
