import { LitElement, html } from "lit";

import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { money } from "../../formatting/numberformats.js";

export default class AMLUnAnsweredView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			question_id: {
				type: Number,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.dateStr = "";
		this.onShow = this.onShow.bind(this);
		this.details = [];
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1200px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h2>Unanswered questions ${this.dateStr}</h2>
        </div>
        <table>
          <thead>
            <tr>
             <th></th>
             <th>Code</th>
             <th>Question</th>
             <th class="numeric">Count</th>
             <th class="numeric">Warned</th>
            </tr>
          </thead>
          <tbody>
            ${this.rows.map(
							(row) => html`
              <tr>
                <td><fm-button type="button" data-id="${row.id}" @click="${
								this.onShow
							}">Show</fm-button></td>
                <td>${row.code}</td>
                <td>${row.question}</td>
                <td class="numeric">${row.rowcount}</td>
                <td class="numeric">${row.warncount}</td>
              </tr>
              ${this.renderDetails(row)}
            `,
						)}
          </tbody>
        </table>
      </div>
    `;
	}

	renderDetails(row) {
		if (row.id === this.question_id) {
			return html`
    <tr>
      <td colspan="4">
        <table>
          <thead>
            <tr>
              <th>Advisor</th>
              <th>account ID</th>
              <th>Name</th>
              <th>Warned</th>
              <th>Type</th>
              <th>PID</th>
              <th>Ask Date</th>
              <th class="numeric">Invested</th>
              <th class="numeric">#orders</th>
            </tr>
          </thead>
          <tbody>
            ${this.details.map(
							(r) => html`
              <tr>
               <td>${r.advisor}</td>
               <td class="numeric">${r.account_id}</td>
               <td>${r.name}</td>
               <td>${r.warn_date}</td>
               <td>${r.type_code}</td>
               <td>${r.uniquename}</td>
               <td>${r.ask_date}</td>
               <td class="numeric">${money(r.current_value)}</td>
               <td class="numeric">${r.order_count}</td>
              </tr>`,
						)}
          </tbody>
        </table>
      </td>
  </tr>`;
		} else {
			return "";
		}
	}

	async connectedCallback() {
		super.connectedCallback();
		const dt = new Date();
		this.dateStr = dt.toLocaleString();
		this.rows = await api.get("/aml/uquestions");
	}

	async onShow(event) {
		const target = event.target;
		const id = target.getAttribute("data-id");
		this.details = await api.get(`/aml/uquestions/${id}`);
		this.question_id = Number(id);
	}
}

customElements.define("aml-unanswered-view", AMLUnAnsweredView);
