import { LitElement, html } from "lit";

import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import inputStyles from "../../styles/input.js";
import sharedStyles from "../../styles/shared.js";
import { DateFormatter } from "../../formatting/dateformats.js";
import { quantity, money } from "../../formatting/numberformats.js";

export default class ReportAccountReturnView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
		};
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1400px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h2>Account return</h2>
        </div>
        <fm-form id="parms" class="reportform no-print" >
          <div class="form-space">
            <label>Account</label>
            <fm-autocomplete
              name="account_id"
              style="min-width:300px;"
              url="/lov/accounts"
              display="name"
              select="id"
            ></fm-autocomplete>
          </div>
          <div class="form-space">
            <label>From</label>
            <input type="date"  class="date-input" name="from"  value="${
							this.from
						}" >
          </div>
          <div class="form-space">
          <label>To</label>
          <input type="date"  class="date-input" name="to" value="${this.to}" >
          </div>
          <div class="form-space">
          <fm-button id="submit_button" class="btn submit" @click="${
						this.refresh
					}">Refresh</fm-button>
        </div>
      </fm-form>
      </div>
      <table>
        <thead>
          <tr>
            <th>Custody</th>
            <th>Name</th>
            <th>Type</th>
            <th>Owner</th>
          </tr>
        </thead>
      <tbody>
        ${this.rows.map(
					(r) => html`
        <tr>
          <td>${r.custody_code}</td>
          <td>${r.name}</td>
          <td>${r.custodytype}</td>
          <td>${r.owner}</td>
        </tr>
        <tr>
          <td></td>
          <td colspan="3">
            <table>
              <thead>
                <tr>
                  <th>Security</th>
                  <th class="numeric">Quantity</th>
                  <th class="numeric">Market Value</th>
                  <th class="numeric">Bought</th>
                  <th class="numeric">Sold</th>
                  <th class="numeric">Advisor fee</th>
                  <th class="numeric">Profit/Loss</th>
                  <th class="numeric">Dividend</th>
                  <th class="numeric">Return</th>
                  <th class="numeric">Tax</th>
                </tr>
              </thead>
              <tbody>
                ${(r.portfolio || []).map(
									(p) => html`
                <tr>
                  <td>${p.security_name}</td>
                  <td class="numeric">${quantity(p.quantity)}</td>
                  <td class="numeric">${money(p.marketvalue_pc)}</td>
                  <td class="numeric">${money(p.buyvalue_pc)}</td>
                  <td class="numeric">${money(p.sellvalue_pc)}</td>
                  <td class="numeric">${money(p.advisorfee_pc)}</td>
                  <td class="numeric">${money(p.pl_pc)}</td>
                  <td class="numeric">${money(p.intdiv_pc)}</td>
                  <td class="numeric">${money(p.return_pc)}</td>
                  <td class="numeric">${money(p.tax_pc)}</td>
                </tr>`,
								)}
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td class="numeric"></td>
                  <td class="numeric"><b>${money(r.totals[0].marketvalue_pc)}</b></td>
                  <td class="numeric"><b>${money(r.totals[0].buyvalue_pc)}</b></td>
                  <td class="numeric"><b>${money(r.totals[0].sellvalue_pc)}</b></td>
                  <td class="numeric"><b>${money(r.totals[0].advisorfee_pc)}</b></td>
                  <td class="numeric"><b>${money(r.totals[0].pl_pc)}</b></td>
                  <td class="numeric"><b>${money(r.totals[0].intdiv_pc)}</b></td>
                  <td class="numeric"><b>${money(r.totals[0].return_pc)}</b></td>
                  <td class="numeric"><b>${money(r.totals[0].tax_pc)}</b></td>
                </tr>
              </tfoot>
            </table>
          </td>
        </tr>
    `,
				)}
      </tbody>
    </table>
    `;
	}

	constructor() {
		super();
		this.rows = [];
		this.refresh = this.refresh.bind(this);
		const todate = new Date();
		const fromdate = new Date(todate.getFullYear(), 0, 1);
		this.from = DateFormatter.format(fromdate, "yyyy-mm-dd");
		this.to = DateFormatter.format(todate, "yyyy-mm-dd");
	}

	connectedCallback() {
		super.connectedCallback();
		const todate = new Date();
		const fromdate = new Date(todate.getFullYear(), 0, 1);
		this.from = DateFormatter.format(fromdate, "yyyy-mm-dd");
		this.to = DateFormatter.format(todate, "yyyy-mm-dd");
	}

	async onChange() {
		const id = this.account_id;
		const from = this.from;
		const to = this.to;
		this.rows = await api.get(`/reports/${id}/return?from=${from}&to=${to}`);
	}

	async refresh(event) {
		const target = event.target;
		target.loading = true;
		const params = this.shadowRoot.querySelector("#parms").value;
		this.from = params.from;
		this.to = params.to;
		this.account_id = params.account_id;
		if (this.account_id) {
			await this.onChange();
		}
		target.loading = false;
	}
}

customElements.define("report-accountreturn-view", ReportAccountReturnView);
