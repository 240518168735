import { LitElement, html } from "lit";

import sharedStyles from "../../styles/shared.js";
import tableStyles from "../../styles/tables.js";

export default class ReportView extends LitElement {
	render() {
		return html`
    ${sharedStyles}
    ${tableStyles}
      <header class="horizontal-menu-header">
        <router-link to="/reports/companies">
          <a>Companies</a>
        </router-link>
        <router-link to="/reports/participation">
        <a>Participation in companies</a>
       </router-link>
        <router-link to="/reports/accountreturn">
          <a>Account return</a>
        </router-link>
        <router-link to="/reports/cashflow">
        <a>Cashflow</a>
      </router-link>
      <router-link to="/reports/sql">
        <a>SQL</a>
      </router-link>
      <router-link to="/reports/fundstatus">
        <a>FundStatus</a>
      </router-link>
      <router-link to="/reports/statement">
       <a>Custody Statement</a>
      </router-link>
      <router-link to="/reports/pepdata">
      <a>PEP Data</a>
     </router-link>
     <router-link to="/reports/messages">
      <a>System messages</a>
     </router-link>
     </header>
      <slot></slot>
    `;
	}
}

customElements.define("report-view", ReportView);
