const moneyFormatter = new Intl.NumberFormat("da-DK", {
	useGrouping: true,
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
});

export const money = (n: number) => {
	return isNaN(n) ? "" : moneyFormatter.format(n);
};

const quantityFormatter = new Intl.NumberFormat("da-DK", {
	useGrouping: true,
	minimumFractionDigits: 6,
	maximumFractionDigits: 6,
});

export const quantity = (n: number) => {
	return isNaN(n) ? "" : quantityFormatter.format(n);
};

const priceFormatter = new Intl.NumberFormat("da-DK", {
	useGrouping: true,
	minimumFractionDigits: 6,
	maximumFractionDigits: 6,
});

export const price = (n: number) => {
	return isNaN(n) ? "" : priceFormatter.format(n);
};

export const number = (n: number, f: string) => {
	return isNaN(n)
		? ""
		: f === "m"
		? moneyFormatter.format(n)
		: quantityFormatter.format(n);
};
