import { LitElement, html, css } from "lit-element";
import { customElement } from "lit/decorators.js";

import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";

@customElement("shareholders-view")
export class ShareholdersView extends LitElement {
	static styles = [
		sharedStyles,
		tableStyles,
		css`.horizontal-menu-header {
    display: flex;
    align-items: center;
    height: 48px;
    width: 100%;
    padding: 0 16px;
    background: white;
    border-bottom: 1px solid #ddd;
  }

  router-link a {
    text-decoration: none;
    margin: 0 8px;
    padding: 6px;
    font-weight: 500;
    font-size: 15px;
    color: #666;
  }

  router-link[active] a {
    color: var(--primary-color);
  }`,
	];

	protected render() {
		return html`
      <header class="horizontal-menu-header">
        <router-link to="/shareholders" exact>
          <a>List</a>
        </router-link>
        <router-link to="/shareholders/positions" exact>
        <a>Positions</a>
      </router-link>
        <router-link to="/shareholders/custodians">
          <a>Custodians</a>
        </router-link>
        <router-link to="/shareholders/transactions">
          <a>Transactions</a>
        </router-link>
      </header>
      <slot></slot>
    `;
	}
}
