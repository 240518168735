import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { toast } from "../../utils.js";
import router from "../../router/index.js";

export default class UserNewEventView extends LitElement {
	static get properties() {
		return {
			user_id: {
				type: String,
			},
			row: {
				type: Object,
			},
			rerender: {
				type: Number,
			},
		};
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1000px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>User Event</h1>
        </div>
        <fm-form id="form" class="form-grid"  method="put">
        <label class="form-field">
            Event Type
            <fm-autocomplete name="type_id" url="/lov/eventcodes" display="description" select="id"></fm-autocomplete>
          </label>
        <label class="form-field">
            Comment
            <input type="text" name="admin_comment" >
          </label>
          <button class="button" @click="${this.save}">Save</button>
        </fm-form>
      </div>`;
	}

	constructor() {
		super();
		this.user_id = "";
		this.save = this.save.bind(this);
		this.rerender = 0;
	}

	async save() {
		try {
			const formdata = this.shadowRoot.querySelector("#form").value;
			await api.post(`/users/${this.user_id}/events`, formdata);
			router.push(`/accounts/users/${this.user_id}`);
			toast("Event added");
		} catch (err) {
			Sentry.captureException(err);
			const error = await err.json();
			console.log(error);
			toast(error.message || " Error occured");
		}
	}
}
customElements.define("user-newevent-view", UserNewEventView);
