import { LitElement, html } from "lit";

import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { date } from "../../formatting/dateformats.js";
import { quantity } from "../../formatting/numberformats.js";

import { sort } from "../../utils.js";

export default class FundsCategoryView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			rerender: {
				type: Number,
			},
		};
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1000px;
      }
      </style>
       <div class="card">
        <div class="card-header">
          <h1>Categories</h1>
        </div>
        <div class="card-block">
         <input type="date"  class="date-input" id="asofdate" value="${
						this.asofdate
					}" @change="${this.onInput}">
          <fm-select id="category" url="/lov/categories"></fm-select>
          <button class="btn" @click="${this.fetch}" >Refresh</button>
        </div>
        <table>
          <thead>
            <tr>
              <th data-key="fund_name" @click="${this.sort}">Fund Name</th>
              <th data-key="subcategory" @click="${this.sort}">Subcategory</th>
              <th class="numeric" data-key="pct_share" @click="${this.sort}">Pct. Share</th>
            </tr>
          </thead>
          <tbody>
            ${this.rows.map(
							(row) => html`
            <tr>
              <th colspan="3">${row.fund_name}</th>
            </tr>
            ${row.subcategories.map(
							(s) => html`
              <tr>
				<td></td>
              	<td>${s.subcategory}</td>
              	<td class="numeric">${quantity(s.pct_share)}</td>
              </tr>
            `,
						)}
            `,
						)}
          </tbody>
        </table>
      </div>
   `;
	}

	constructor() {
		super();
		this.rows = [];
		this.asofdate = date(Date.now());
		this.onInput = this.onInput.bind(this);
		this.fetch = this.fetch.bind(this);
		this.sort = this.sort.bind(this);
		this.rerender = 0;
	}

	onInput() {
		this.query = this.shadowRoot.querySelector("#query").value;
		this.asofdate = this.shadowRoot.querySelector("#asofdate").value;
	}

	async fetch() {
		const asofdate = this.shadowRoot.querySelector("#asofdate").value;

		this.rows = await api.get(
			`/funds/category?asofdate=${this.asofdate}&id=161`,
		);
	}

	sort(event) {
		const key = event.target.getAttribute("data-key");
		this.rows = sort(this.rows, key);
		this.rerender = this.rerender + 1;
	}
}

customElements.define("funds-category-view", FundsCategoryView);
