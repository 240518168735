import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { toast } from "../../utils.js";

export default class UserNewQuestionView extends LitElement {
	static get properties() {
		return {
			users: {
				type: Array,
			},
			questions: {
				type: Array,
			},
		};
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1500px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>Post Question to one or many users</h1>
        </div>
        <fm-form id="paramform"  @submit="${this.postQuestion}" style="width:100%">
        <table>
        <tr>
        <td style="width:10%">Pre-defined Question</td>
        <td style="width:90%"> <select name="question_id">
          ${this.questions.map(
						(q) => html`<option value="${q.id}">${q.question}</option>`,
					)}
        </select>
        </td>
        </tr>
        <tr>
        <td style="width:10%">Specific Question</td>
        <td style="width:90%"> <input type="text" name="question" style="width:100%">
        (leave this field blank, if you want to post a pre-defined question)
        </td>
        </tr>
        <tr>
        <td>User-id's (separated by comma (,))</td>
        <td><textarea name="users" rows="20" cols="100"></textarea></td>
        </tr>
        <tr>
        <td></td>
        <td>
        <div style="display:flex">
          <fm-button style="max-width:80px" type="button" @click="${
						this.getUsers
					}">Show Users</fm-button>
           <fm-button style="max-width:80px"  type="submit" ">Send</fm-button>
        </div>
       </td>
       </tr>
       </table>
       </fm-form>
      </div>
      <table>
      <tr>
      <th>ID</th><th>Name</th>
      </tr>
      ${this.users.map(
				(u) => html`
        <tr>
           <td>${u.id}</td><td>${u.name}</td>
        </tr>
          `,
			)}
      </table>
    `;
	}

	constructor() {
		super();
		this.users = [];
		this.questions = [];
		this.postQuestion = this.postQuestion.bind(this);
		this.getUsers = this.getUsers.bind(this);
	}

	async connectedCallback() {
		super.connectedCallback();
		this.questions = await api.get("/lov/questions");
	}

	async postQuestion(event) {
		const f = this.shadowRoot.querySelector("#paramform").value;
		const l = this.users.length;
		for (let i = 0; i < this.users.length; i++) {
			toast(this.users[i].name);
			try {
				await api.post(`/users/${this.users[i].id}/question`, f);
			} catch (err) {
				Sentry.captureException(err);
				const response = await err.json();
				toast(`Error ${response.message || ""}`);
			}
		}
	}

	async getUsers() {
		const f = this.shadowRoot.querySelector("#paramform").value;
		try {
			this.users = await api.get(`/mails/broadcast?str=${f.users}`);
		} catch (err) {
			Sentry.captureException(err);
			toast("Det lykkedes ikke at hente liste over brugere");
		}
	}
}

customElements.define("user-newquestion-view", UserNewQuestionView);
