import { css, html } from "lit";

export const styles = css`
  .form-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 16px;
  }

  .form-grid .form-field {
    width: calc(50% - 8px);
  }

  .form-grid .form-field3 {
    width: calc(33% - 8px);
  }


  .form-grid .btn {
    width: 100%;
    margin-top: 8px;
  }

  /*
  .reportform input,label,fm-button {display:block;}
  */
 .reportform {
   display: flex;
 }
  .form-space {float:left;margin:10px;}

  label.search {
    width:24px;
    height:24px;
    float: left;
  }
 
  .form-field {
    display: block;
    padding: 4px 0;
    font-size: 14px;
    font-weight: 500;
  }

  .form-field input[type="text"],
  .form-field input[type="search"],
  .form-field input[type="number"],
  .form-field input[type="password"],
  .form-field input[type="date"],
  .form-field input[type="email"],
  .form-field input[type="tel"] {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    border: 1px solid #ddd;
    padding: 8px;
    margin-top: 4px;
    width: 100%;
    min-width:140px;
    height: 38px;
  }
  

  input.inline {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    border: 1px solid #ddd;
    padding: 8px;
    width: 100%;
    height: 38px;
  }

  .form-field input[type="checkbox"] {
    margin: 8px;
  }

  .form-field select {
    width: 100%;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    padding: 8px;
    margin-top: 4px;
    border: 1px solid #ddd;
  }

  input[data-invalid],
  *[data-invalid] {
    border: 1px solid #F44336;
  }

  .form-field .field-error {
    display: none;
    color: #F44336;
    padding: 8px 0;
  }

  .form-field *[data-invalid] + .field-error {
    display: block;
  }

  .form-grid .panel {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .text-input {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    border: 1px solid #ddd;
    padding: 8px;
    width: 100%;
  }

  .search-input {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    padding: 8px;
    border: 1px solid grey;
    
  }

  .date-input {
    max-width: 160px;
    min-width: 160px;
    padding: 8px;
  }

  input[type="checkbox"] {
    height: auto;
  }

  input:required:hover {
    opacity: 1;
  }

  .button {
    background: var(--primary-color);
    color: white;
    border: none;
    padding: 6px 6px;
    min-width: 80px;
    max-width: 100px;
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    text-transform: uppercase;
    text-align: center;
    transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  .button-changed {
    background: red;
    color: white;
    border: none;
    padding: 6px 6px;
    min-width: 100px;
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    text-transform: uppercase;
    text-align: center;
    transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  .button:not([disabled]) {
    cursor: pointer;
  }

  button:disabled {
    background:grey;
  }


  .button:not([disabled]):hover,
  .button:focus {
    opacity: 0.8;
  }

  .suggest-dropdown {
    position: absolute; 
    display: none;
    z-index: 2;
  }


  .button-panel {
    display: table;
    height: 28px;
    width: 100%
  }

  .button-right {
    float: right;
  }

  .warning {
    background: yellow;
  }

  .transparent {
    background: transparent;
  }

  .button-link {
    background: none!important;
    border: none;
    padding: 0!important;
    color: #069;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export default html`
<style>
  ${styles}
</style>
`;
