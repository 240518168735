import { LitElement, html } from "lit";
import { customElement } from "lit/decorators.js";

import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as tableStyles } from "../../styles/tables.js";

@customElement("messages-view")
export class MessagesView extends LitElement {
	static styles = [sharedStyles, tableStyles];

	render() {
		return html`
      <header class="horizontal-menu-header">
        <router-link to="/messages" exact>
          <a>List</a>
        </router-link>
        <router-link to="/messages/new">
          <a>New</a>
        </router-link>
      </header>
      <slot></slot>
    `;
	}
}
