import { LitElement } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import { DateFormatter } from "../../formatting/dateformats.js";
import { toast } from "../../utils.js";
import { orderRender } from "./sign-orders-render";

export default class SignOrdersView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			signorder_id: {
				type: Number,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.refresh = this.refresh.bind(this);
		this.onShow = this.onShow.bind(this);

		let todate = localStorage.getItem("signatures-todate");
		if (!todate) {
			todate = DateFormatter.format(new Date(), "yyyy-mm-dd");
		}
		const fromdate = localStorage.getItem("signatures-fromdate");
		if (!fromdate) {
			localStorage.setItem("signatures-fromdate", todate);
		}
		this.querydoc = localStorage.getItem("signatures-querydoc") || "";
		this.queryuser = localStorage.getItem("signatures-queryuser") || "";
	}

	render() {
		return orderRender(this);
	}

	async refresh() {
		const f = this.shadowRoot.querySelector("#params").value;
		const d = this.shadowRoot.querySelector("#dates").value;
		localStorage.setItem("signatures-queryuser", f.queryuser || "");
		localStorage.setItem("signatures-querydoc", f.querydoc || "");
		localStorage.setItem("signatures-fromdate", d.from);
		localStorage.setItem("signatures-todate", d.to);
		const l_q = `?from=${d.from}&to=${d.to}&query=${f.queryuser || ""}&status=${
			f.status
		}&doc=${f.querydoc || ""}`;
		try {
			this.signorder_id = null;
			this.rows = await api.get(`/sign${l_q}`);
		} catch (err) {
			Sentry.captureException(err);
			toast("Refresh failed");
		}
	}

	async onShow(event) {
		const target = event.target;
		const id = target.getAttribute("data-id");
		this.signorder_id = id;
	}
}

customElements.define("sign-orders-view", SignOrdersView);
