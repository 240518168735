import { LitElement } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import { DateFormatter } from "../../formatting/dateformats.js";
import { toCsvF, sort } from "../../utils.js";
import { toast } from "../../utils.js";
import { ext_render } from "./orders-report-render.js";

export default class OrdersReportView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			orderstatus: {
				type: Array,
			},
			edit_id: {
				type: Number,
			},
			status_change: {
				type: Array,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.orders = [];
		this.s_query = "";
		this.c_query = "";
		this.fetch = this.fetch.bind(this);
		this.refresh = this.refresh.bind(this);
		this.sort = this.sort.bind(this);
		this.nota = this.nota.bind(this);
		this.statuschange = this.statuschange.bind(this);
		this.statuschange_one = this.statuschange_one.bind(this);
		this.chkbox = this.chkbox.bind(this);
		this.download = this.download.bind(this);
		this.edit = this.edit.bind(this);
		this.render = ext_render.bind(this);
		this.orderstatus = [];
		this.account_sum = 0;
		this.account_request_sum = 0;
		this.total_sum = 0;
		this.total_request_sum = 0;
		this.account_id = -1;
		this.status_change = [];
		let todate = localStorage.getItem("order-todate");
		if (!todate) {
			todate = DateFormatter.format(new Date(), "yyyy-mm-dd");
		}
		const fromdate = localStorage.getItem("order-fromdate");
		if (!fromdate) {
			localStorage.setItem("order-fromdate", todate);
		}
		this.s_query = localStorage.getItem("order-squery") || "";
		this.c_query = localStorage.getItem("order-cquery") || "";

		this.qorderstatus = localStorage.getItem("order-status") || "";
	}

	async connectedCallback() {
		super.connectedCallback();
		this.orderstatus = await api.get(
			`/lov/orderstatus?query=${this.qorderstatus}`,
		);
		let st = "";
		for (let i = 0; i < this.orderstatus.length; i++) {
			if (this.orderstatus[i].selected === "Y") {
				st = `${st},${this.orderstatus[i].id}`;
			}
		}
		st = `${st},`;
		this.status_change = await api.get(`/lov/orderstatuschange?query=${st}`);
		this.account_sum = 0;
		this.account_request_sum = 0;
		this.total_sum = 0;
		this.total_request_sum = 0;
	}

	async chkbox(event) {
		const target = event.target;
		const id = Number(target.getAttribute("data-id"));
		let st = "";
		for (let i = 0; i < this.orderstatus.length; i++) {
			if (this.orderstatus[i].id === id) {
				if (target.checked) {
					this.orderstatus[i].selected = "Y";
				} else {
					this.orderstatus[i].selected = "N";
				}
			}
		}
		for (let i = 0; i < this.orderstatus.length; i++) {
			if (this.orderstatus[i].selected === "Y") {
				st = `${st},${this.orderstatus[i].id}`;
			}
		}
		st = `${st},`;
		this.status_change = await api.get(`/lov/orderstatuschange?query=${st}`);
	}

	async fetch() {
		const parms = this.shadowRoot.querySelector("#parms").value;
		let openclose = "";
		if (parms.chkopen) {
			openclose = `${openclose},O`;
		}
		if (parms.chkclosed) {
			openclose = `${openclose},C`;
		}
		openclose = `${openclose},`;
		const dates = this.shadowRoot.querySelector("#dates").value;
		const reducer = (a, t) => (t.selected === "Y" ? `${a}${t.id},` : a);
		const status = this.orderstatus.reduce(reducer, ",");
		localStorage.setItem("order-status", status);
		localStorage.setItem("order-fromdate", dates.from);
		localStorage.setItem("order-todate", dates.to);
		localStorage.setItem("order-cquery", parms.c_query);
		localStorage.setItem("order-squery", parms.s_query);
		this.c_query = parms.c_query;
		this.s_query = parms.s_query;
		let q = `?tradefrom=${dates.from}&tradeto=${dates.to}&s_query=${parms.s_query}&status=${status}&account_from=${parms.account_from}&account_to=${parms.account_to}&c_query=${parms.c_query}`;
		q = `${q}&openclose=${openclose}`;
		try {
			const rows = await api.get(`/orders/report${q}`);
			this.rows = rows.report;
			this.orders = rows.orders;
		} catch (err) {
			Sentry.captureException(err);
			try {
				const response = err.json();
				toast(response.message || "Fejl");
			} catch (err2) {
				Sentry.captureException(err2);
				toast("Fejl");
			}
		}
	}

	async refresh() {
		const submit_button = this.shadowRoot.querySelector("#submit_button");
		submit_button.loading = true;
		this.edit_id = null;
		await this.fetch();
		submit_button.loading = false;
	}

	sort(event) {
		const key = event.target.getAttribute("data-key");
		this.rows = sort(this.rows, key);
	}

	async nota(event) {
		const target = event.target;
		const id = target.getAttribute("data-id");
		const PDF = await API.get(`/nota/${id}`);
		const a = document.createElement("a");
		a.setAttribute("href", `data:application/pdf;base64,${PDF.nota}`);
		a.setAttribute("download", "fm_order.pdf");
		a.click();
		target.loading = false;
	}

	async statuschange(event) {
		const target = event.target;
		target.loading = true;
		const from_id = target.getAttribute("data-from");
		const to_id = target.getAttribute("data-to");
		let cnt = 0;
		for (let i = 0; i < this.orders.length; i++) {
			if (this.orders[i].status_id === from_id) {
				try {
					await api.put(`/orders/${this.orders[i].fundorder_id}/status`, {
						status_id: to_id,
					});
					cnt += 1;
					toast(`order_id ${this.orders[i].fundorder_id} changed`, 1000);
				} catch (err) {
					Sentry.captureException(err);
					console.log(err);
					try {
						const err2 = await err.json();
						toast(err2.message);
					} catch (err2) {}
				}
			}
		}
		toast(` ${cnt} orders changed`);
		target.loading = false;
		await this.fetch();
	}

	async statuschange_one(event) {
		const target = event.target;
		target.loading = true;
		const order_id = target.getAttribute("data-id");
		const to_id = target.getAttribute("data-to");
		try {
			await api.put(`/orders/${order_id}/status`, { status_id: to_id });
			toast(`status for order ${order_id} changed`);
		} catch (err) {
			Sentry.captureException(err);
			console.log(err);
			try {
				const err2 = await err.json();
				toast(err2.message);
			} catch (err2) {}
		}
		target.loading = false;
		await this.fetch();
	}

	async ownership(event) {
		const target = event.target;
		const id = target.getAttribute("data-id");
		const PDF = await API.get(`/nota/${id}/ownership`);
		const a = document.createElement("a");
		a.setAttribute("href", `data:application/pdf;base64,${PDF}`);
		a.setAttribute("download", "fundmarket_ea.pdf");
		a.click();
		target.loading = false;
	}

	edit(event) {
		const target = event.target;
		const id = Number(target.getAttribute("data-id"));
		this.edit_id = id;
	}

	async download(event) {
		const download_button = this.shadowRoot.querySelector("#download_button");
		try {
			download_button.loading = true;
			await this.fetch();

			const csvHeaders = [
				"id",
				"account_name",
				"custody_owner",
				"custody_code",
				"status_code",
				"order_code",
				"security",
				"security_name",
				"amountqc",
				"requestamountqc",
				"position",
				"quantity",
				"costqc",
				"taxqc",
				"price",
				"nav",
				"estimated_inflow",
				"source",
				"message",
				"reg_date",
				"tradedate",
				"valuedate",
				"latest_nav",
			];

			const csvFields = csvHeaders;
			const csvFormats = [
				"t",
				"t",
				"t",
				"t",
				"t",
				"t",
				"t",
				"t",
				"m",
				"m",
				"q",
				"q",
				"m",
				"m",
				"m",
				"m",
				"m",
				"t",
				"t",
				"t",
				"t",
				"t",
				"m",
			];

			let txtdata = toCsvF(csvHeaders, csvFields, csvFormats, this.rows);

			try {
				txtdata = btoa(txtdata);
			} catch (err) {
				Sentry.captureException(err);
				txtdata = btoa(unescape(encodeURIComponent(txtdata)));
			}
			const a = document.createElement("a");
			a.setAttribute("href", `data:text/csv;base64,${txtdata}`);
			a.setAttribute("download", "fm_orders.csv");
			a.click();
			download_button.loading = false;
		} catch (err) {
			Sentry.captureException(err);
			console.log(err);
			toast(err);
			download_button.loading = false;
		}
	}
}

customElements.define("orders-report-view", OrdersReportView);
