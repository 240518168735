import { LitElement, css, html } from "lit";
import { customElement, property } from "lit/decorators.js";

declare global {
	interface HTMLElementTagNameMap {
		"fm-form-field": FormFieldElement;
	}
}

@customElement("fm-form-field")
export class FormFieldElement extends LitElement {
	static styles = css`
        :host {
            display: flex;
        }

        label {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            font-weight: 500;
            width: 100%;
        }

        span {
            margin-bottom: 4px;
        }
    `;

	@property()
	label = "";

	render() {
		return html`
            <label>
                <span>${this.label}</span>
                <slot></slot>
            </label>
        `;
	}
}
