import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { toast } from "../../utils.js";
import router from "../../router/index.js";

export default class AccountNewView extends LitElement {
	static get properties() {
		return {
			row: {
				type: Object,
			},
			rerender: {
				type: Number,
			},
		};
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1000px;
      }
      </style>
      <div class="card">
        <div class="card-header">
          <h1>New Account</h1>
        </div>
        <fm-form id="form" class="form-grid" url="/accounts" method="post">
        <label class="form-field">
        Account type
        <fm-autocomplete 
          name="pmaccounttype_id" 
          url="/lov/accounttypes" 
          display="description" 
          select="id"
          >
          </fm-autocomplete>
        </label> 
        <label class="form-field">
            Account Name
            <input type="text" name="name">
          </label>
          <label class="form-field">
            CVR (Company)
            <input type="text" name="cvr" >
          </label>
          <label class="form-field">
            CPR (Person)
            <input type="text" name="cpr" >
          </label>
          <label class="form-field">
            E-mail
            <input type="text" name="email" >
          </label>
          <label class="form-field">
            Phone
            <input type="text" name="phone" >
          </label>
          <label class="form-field">
            Address1
            <input type="text" name="address1" >
          </label>
          <label class="form-field">
            Address2
            <input type="text" name="address2">
          </label>
          <label class="form-field">
            Zipcode
            <input type="text" name="zipcode" >
          </label>
          <label class="form-field">
            City
            <input type="text" name="city">
          </label>  
          <button type="button" class="btn btn--light" @click="${this.save}" >Save</button>
        </fm-form>
      </div>
    `;
	}

	constructor() {
		super();
		this.row = {};
		this.save = this.save.bind(this);
		this.rerender = 0;
	}

	async save(event) {
		event.preventDefault();
		const formdata = this.shadowRoot.querySelector("#form").value;
		try {
			const data = await api.post("/accounts", formdata);
			router.push(`/accounts/${data.id}`);
			toast("Account created");
		} catch (err) {
			Sentry.captureException(err);
			const e = await err.json();
			toast(e.message || "Der opstod en fejl");
		}
	}
}
customElements.define("account-new-view", AccountNewView);
