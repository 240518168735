import { html } from "lit";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";

export function ext_render() {
	return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 800px;
      }
      </style>
      <div class="card" style="border:1px solid black">
      <div class="card-header">
       <h2 style="padding:10px;">Edit order</h2>
       </div>
       <div class="card-body">
        <table style="border:1px solid black">
          <tr>
            <td>Order ID</td><td>${this.row.id}</td>
          </tr>
          <tr>
            <td>Status</td><td>${this.row.status_code} ${
		this.row.status
	} ${renderStatusChange(this, this.row)}</td>
          </tr>
          <tr>
            <td>Custody</td><td>${this.row.custody_code} ${
		this.row.custody_owner
	}</td>
          </tr>
      </table>
      <br><br>
        ${this.row.transactions.map((t) => renderTransaction(this, t))}
    </div>
    </div>
`;
}

function renderTransaction(cl, t) {
	return html`
    <fm-form @submit="${cl.saveTran}">
    <input type="hidden" name="id" value="${t.id}" />
    <table style="border:1px solid black">
      <tr>
        <td>Transaction</td><td>${t.id}</td>
      </tr>
      <tr>
        <td>Security</td><td>${t.security} ${t.security_name}</td>
      </tr>
      <tr>
       <td>Order type</td><td>${t.order_code} </td>
      </tr>
      <tr>
       <td>Trade date</td><td><input type="date" name="tradedate" value="${t.tradedate}" /> </td>
      </tr>
      <tr>
       <td>Value date</td><td><input type="date" name="valuedate" value="${t.valuedate}" /> </td>
      </tr>
      <tr>
        <td>Quantity</td><td><input type="number" name="quantity" value="${t.quantity}" /> </td> </td>
      </tr>
      <tr>
        <td>NAV</td><td><input type="number" name="nav" value="${t.nav}" /> </td>
      </tr>
      <tr>
        <td>Price</td><td><input type="number" name="price" value="${t.price}" /> </td>
      </tr>
      <tr>
        <td>Amount</td><td><input type="number" name="amountqc" value="${t.amountqc}" /> </td>
      </tr>
      <tr>
        <td>Requested Amount</td><td><input type="number" name="requestamountqc" value="${t.requestamountqc}" /></td>
      </tr>
      <tr>
      <tr>
        <td>Nav Priority( 10=Official)</td><td><input type="number" name="navpriority" value="${t.navpriority}" /></td>
      </tr>
      <tr>
        <td colspan="2"><fm-button type="submit" >Save</fm-button></td>
      </tr>
    </table>
  </fm-form>`;
}

function renderStatusChange(cl, row) {
	return html`
    ${(row.status_change || []).map(
			(ch) => html`
      <fm-button data-id="${row.id}" 
              data-status="${ch.tostatus_id}" 
              class="btn" 
              @click="${cl.statuschange}" 
              style="display:inline;max-width:80px">${ch.description}</fm-button>`,
		)}`;
}
