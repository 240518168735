import { LitElement, css, html } from "lit";
import { customElement, property } from "lit/decorators.js";

declare global {
	interface HTMLElementTagNameMap {
		"fm-form-v2": FormElementV2;
	}
}

const onKeyup = Symbol("onKeyup");
const onClick = Symbol("onClick");
const onFocusout = Symbol("onFocusout");

@customElement("fm-form-v2")
export class FormElementV2 extends LitElement {
	static styles = css`
		:host {
			display: contents;
		}
	`;

	@property({ reflect: true })
	role = "form";

	constructor() {
		super();
		this[onClick] = this[onClick].bind(this);
		this[onKeyup] = this[onKeyup].bind(this);
		this[onFocusout] = this[onFocusout].bind(this);
	}

	connectedCallback(): void {
		super.connectedCallback();
		this.addEventListener("click", this[onClick]);
		this.addEventListener("keyup", this[onKeyup]);
		this.addEventListener("focusout", this[onFocusout]);
	}

	disconnectedCallback(): void {
		super.disconnectedCallback();
		this.removeEventListener("click", this[onClick]);
		this.removeEventListener("keyup", this[onKeyup]);
		this.removeEventListener("focusout", this[onFocusout]);
	}

	render() {
		return html`<slot></slot>`;
	}

	[onClick](event: MouseEvent) {
		const target = event.target;
		if (!(target instanceof Element)) {
			return;
		}

		const type = target?.getAttribute("type");
		if (type === null) {
			return;
		}

		if (type.toLowerCase() !== "submit") {
			return;
		}

		if (this.reportValidity()) {
			this.dispatchEvent(
				new CustomEvent("submit", {
					bubbles: true,
					cancelable: true,
				}),
			);
		}
	}

	[onKeyup](event: KeyboardEvent) {
		if (event.key !== "Enter") {
			return;
		}

		const target = event.target;
		if (!(target instanceof Element)) {
			return;
		}

		const nodeName = target.nodeName;
		if (nodeName.toLowerCase() === "textarea") {
			return;
		}

		if (this.reportValidity()) {
			this.dispatchEvent(
				new CustomEvent("submit", {
					bubbles: true,
					cancelable: true,
				}),
			);
		}
	}

	[onFocusout](event: FocusEvent) {
		const target = event.target;
		if (!(target instanceof Element)) {
			return;
		}

		if (canReportValidity(target)) {
			if (!target.reportValidity()) {
				return false;
			}
		} else if (canBeValidated(target)) {
			if (!target.valid) {
				this.dispatchEvent(new CustomEvent("invalid"));
				return false;
			}
		}
	}

	serialize(): Map<string, unknown> {
		const values = new Map();
		const elements = Array.from(this.querySelectorAll("*"));
		for (const element of elements) {
			if (!isControlElement(element)) {
				continue;
			}

			if (isCheckbox(element)) {
				if (element.checked) {
					values.set(
						element.name,
						(values.get(element.name) ?? []).concat(element.value),
					);
				}
			} else if (isNativeRadio(element)) {
				if (element.checked) {
					values.set(element.name, element.value);
				}
			} else if (isNativeMultiSelect(element)) {
				values.set(element.name, getSelectValues(element));
			} else {
				values.set(element.name, element.value);
			}
		}
		return values;
	}

	checkValidity(): boolean {
		const elements = Array.from(this.querySelectorAll("*"));
		for (const element of elements) {
			if (!isControlElement(element)) {
				continue;
			}

			if (canCheckValidity(element)) {
				if (!element.checkValidity()) {
					return false;
				}
			} else if (canBeValidated(element)) {
				if (!element.valid) {
					this.dispatchEvent(new CustomEvent("invalid"));
					return false;
				}
			}
		}
		return true;
	}

	reportValidity(): boolean {
		const elements = Array.from(this.querySelectorAll("*"));
		for (const element of elements) {
			if (!isControlElement(element)) {
				continue;
			}

			if (canReportValidity(element)) {
				if (!element.reportValidity()) {
					return false;
				}
			} else if (canBeValidated(element)) {
				if (!element.valid) {
					this.dispatchEvent(new CustomEvent("invalid"));
					return false;
				}
			}
		}
		return true;
	}
}

interface ControlElement {
	name: string;
	value: unknown;
}

function isControlElement(
	element: Element,
): element is Element & ControlElement {
	return (
		"name" in element &&
		typeof element.name === "string" &&
		"value" in element &&
		element.value != null
	);
}

function isCheckbox(element: Element): element is HTMLInputElement {
	const checkbox = "[type=checkbox], [role=checkbox]";
	return element.matches(checkbox);
}

function isNativeRadio(element: Element): element is HTMLInputElement {
	const radio = "input[type=radio]";
	return element.matches(radio);
}

function isNativeMultiSelect(element: Element): element is HTMLSelectElement {
	const multiselect = "select[multiple]";
	return element.matches(multiselect);
}

function getSelectValues(element: HTMLSelectElement): string[] {
	return Array.from(element.options)
		.filter((option) => option.selected)
		.map((option) => option.value);
}

interface CheckValidity {
	checkValidity(): boolean;
}

function canCheckValidity(
	element: Element,
): element is Element & CheckValidity {
	return (
		"checkValidity" in element && typeof element.checkValidity === "function"
	);
}

interface ReportValidity {
	reportValidity(): boolean;
}

function canReportValidity(
	element: Element,
): element is Element & ReportValidity {
	return (
		"reportValidity" in element && typeof element.reportValidity === "function"
	);
}

interface Valid {
	valid: boolean;
}

function canBeValidated(element: Element): element is Element & Valid {
	return "valid" in element && typeof element.valid === "boolean";
}
