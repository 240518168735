import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { money } from "../../formatting/numberformats.js";
import { toast } from "../../utils.js";

export default class OrdersRedemptionsView extends LitElement {
	static get properties() {
		return {
			rows: {
				type: Array,
			},
			redemption_id: {
				type: Number,
			},
		};
	}

	constructor() {
		super();
		this.rows = [];
		this.query = "";
		this.refresh = this.refresh.bind(this);
		this.onNew = this.onNew.bind(this);
		this.onEdit = this.onEdit.bind(this);
		this.onBook = this.onBook.bind(this);
		api.get("/advisors/redemptions/parms").then((p) => {
			this.dateparms = p;
			localStorage.setItem("redemption-list-todate", p.todate);
			localStorage.setItem("redemption-list-fromdate", p.fromdate);
		});
		this.query = localStorage.getItem("redemption-list-query");
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1600px;
      }

      .row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
      }
  
      tr.l1 td {border:none}

      </style>
     
      <div class="card">
      <div class="card-header">
       <h2>Redemption standing orders</h2>
       </div>
       <fm-form id = "parms" class="form-grid" @submit="${this.refresh}">
        <div class="form-field">
        <label>Next tradedate
          <fm-date-range id="dates" name="dates" names="redemption-list-"  ></fm-date-range>
          </label>
        </div>
        <div class="form-field">
            <label>Search
              <input type="search"  name="query" value="${this.query || ""}" class="search-input" style="width:100%">
            </label>
         </div>
       <div class="form-field">
            <fm-button type="submit" id="submit_button" class="btn" style="max-width:80px">Refresh</fm-button>
        </div>
      </fm-form>
      <fm-button type="button" class="btn" style="max-width:80px" @click="${
				this.onNew
			}">New</fm-button>
    </div>
    <div class="card">
      ${this.renderEdit()}
         <table>
          <thead>
            <tr>
            <th></th>
              <th>Custody</th>
              <th>CustodyOwner</th>
              <th>Type</th>
              <th>Method</th>
              <th class="numeric">Amount</th>
              <th>First date</th>
              <th>Last date</th>
              <th>Next date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            ${this.rows.map(
							(row) => html`
              <tr class="l1">
                <td>
                  <fm-button type="button" style="max-width:60px;" data-id="${
										row.id
									}" @click="${this.onEdit}">Edit</fm-button>
                </td>
                <td> ${row.custody_code} </td>
                <td> ${row.custody_owner} </td>
                <td>${row.custodytype_code}</td>
                <td>${row.method_code}</td>
                <td class="numeric">${money(row.amount)}</td>
                <td>${row.txt_firstdate}</td>
                <td>${row.txt_lastdate}</td>
                <td>${row.txt_nextdate}</td>
                <td>
                  <fm-button type="button" style="max-width:60px;" data-id="${
										row.id
									}" @click="${this.onBook}">Book</fm-button>
                </td>
                </tr>
            `,
						)}
          </tbody>
        </table>
      </div>
   `;
	}

	renderEdit() {
		if (this.redemption_id === 0) {
			return html`<redemption-edit-view></redemption-edit-view>`;
		}
		if (this.redemption_id) {
			return html`<redemption-edit-view redemption_id="${this.redemption_id}"></redemption-edit-view>`;
		}
		return "";
	}

	async refresh() {
		this.redemption_id = null;
		const p = this.shadowRoot.querySelector("#parms").value;
		const td = this.shadowRoot.querySelector("#dates").value;
		const but = this.shadowRoot.querySelector("#submit_button");
		but.loading = true;
		try {
			this.rows = await api.get(
				`/advisors/redemptions?query=${p.query}&tfrom=${td.from}&tto=${td.to}`,
			);
		} catch (err) {
			Sentry.captureException(err);
			toast("Det lykkedes ikke at hente");
		}
		but.loading = false;
	}

	onNew() {
		this.redemption_id = 0;
	}

	onEdit(event) {
		const target = event.target;
		const id = target.getAttribute("data-id");
		this.redemption_id = id;
	}

	async onBook(event) {
		const target = event.target;
		const id = target.getAttribute("data-id");
		const bookval = await api.get(`/advisors/redemptions/${id}/amount`);
		try {
			const response = await api.post(
				`/advisors/redemptions/${id}/book`,
				bookval,
			);
			toast("Bogført");
			await this.refresh();
		} catch (err) {
			Sentry.captureException(err);
			toast("Det lykkedes ikke at bogføre");
		}
	}
}

customElements.define("orders-redemptions-view", OrdersRedemptionsView);
