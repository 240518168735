import { LitElement, css, html } from "lit";
import { customElement, state } from "lit/decorators.js";
import { get, post, clearToken, saveToken } from "../../api/client.js";
import router from "../../router/index.js";
import store from "../../store/index.js";
import { receiveStatus, invalidateStatus } from "../../store/actions.js";
import { styles as sharedStyles } from "../../styles/shared.js";
import { styles as inputStyles } from "../../styles/input.js";
import { toast } from "../../utils.js";
import { BASE_PATH } from "../../env.js";
import { None, Option, Some } from "../../option.js";
import { Result } from "../../result.js";
import { assertNever } from "../../assert.js";
import "../../components/fm-form-v2.js";
import "../../components/fm-progress-dots.js";
import "../../components/fm-text-input.js";

const ROOT = BASE_PATH === "/" ? "" : BASE_PATH;

type Phase = "1of2" | "2of2";

type State = "Credentials" | "Challenge";

type PostLoginResponse = {
	status: "OK" | "SMS";
	"fundmarket-token": string;
};

type GetLoginChallengeResponse = {
	authenticated: boolean;
	registered: boolean;
	has_uploaded: boolean;
	approved: boolean;
	phase: Phase;
};

@customElement("sign-in-view")
export class SignInView extends LitElement {
	static styles = [
		sharedStyles,
		inputStyles,
		css`
			* {
				box-sizing: border-box;
			}

			:host {
				margin: 0 auto;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				min-height: 80vh;
			}

			.signin-card {
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding: 32px;
				min-width: 360px;
			}

			.logo-container {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				gap: 8px;
			}

			.logo {
				width: 32px;
			}

			.logo-text {
				margin: 16px 0;
			}

			.loading-container {
				width: 100%;
				display: flex;
				justify-content: center;
			}

			.error {
				color: #F44336;
				text-align: center;
			}

			fm-form-field, fm-button-v2 {
				margin-bottom: 16px;
			}
		`,
	];

	@state()
	redirect: string | null = null;

	@state()
	private state: State = "Credentials";

	@state()
	private loading = false;

	@state()
	private error = "";

	constructor() {
		super();
		this.onSubmitCredentials = this.onSubmitCredentials.bind(this);
		this.onSubmitChallenge = this.onSubmitChallenge.bind(this);
	}

	connectedCallback() {
		super.connectedCallback();
		store.dispatch(invalidateStatus());
		clearToken();
	}

	render() {
		return html`
			<div class="card signin-card">
				<div class="logo-container">
					<img class="logo" src="${ROOT}/static/logo.svg" alt="Fundmarket logo">
					<h1 class="logo-text">Fundmarket Admin</h1>
				</div>
				${this.renderForm()}
			</div>
    	`;
	}

	renderForm() {
		if (this.loading) {
			return this.renderLoading();
		} else if (this.state === "Credentials") {
			return this.renderCredentialsForm();
		} else if (this.state === "Challenge") {
			return this.renderChallengeForm();
		} else {
			assertNever(this.state);
		}
	}

	renderLoading() {
		return html`
			<div class="loading-container">
				<fm-progress-dots></fm-progress-dots>
			</div>
		`;
	}

	renderCredentialsForm() {
		return html`
			<fm-form-v2 id="login-form"  @submit="${this.onSubmitCredentials}">
				<fm-form-field label="Username">
					<fm-text-input name="username" autofocus></fm-text-input>
				</fm-form-field>
				<fm-form-field label="Password">
					<fm-text-input type="password" name="password" autofocus></fm-text-input>
				</fm-form-field>
				<span class="error">${this.error}</span>
				<fm-button-v2 type="submit">Sign in</fm-button-v2>
			</fm-form-v2>
  		`;
	}

	renderChallengeForm() {
		return html`
			<fm-form-v2 id="login-form"  @submit="${this.onSubmitChallenge}">
				<fm-form-field label="SMS Code">
					<fm-text-input name="challenge" autofocus></fm-text-input>
				</fm-form-field>
				<fm-button-v2 type="submit">Sign in</fm-button-v2>
			</fm-form-v2>
 		`;
	}

	async onSubmitCredentials(event: Event) {
		event.preventDefault();
		await this.signInCredentials();
	}

	async signInCredentials() {
		const form = this.shadowRoot?.querySelector("fm-form-v2");
		if (form === null || form === undefined) {
			return;
		}

		const data = Object.fromEntries(form.serialize().entries());

		const response: Option<PostLoginResponse> = await this.fetch(() =>
			post("/login", data),
		);

		if (response.ok === false) {
			return;
		}

		const content = response.value;

		const token = content["fundmarket-token"];
		if (content.status === "OK") {
			saveToken(token);
			store.dispatch(
				receiveStatus({
					authenticated: true,
				}),
			);
			router.push(this.redirect ?? "/accounts");
		} else if (content.status === "SMS") {
			saveToken(token);
			this.state = "Challenge";
		} else if ("status" in content && content.status === "ERROR") {
			this.error = "Incorrect username or password.";
		} else {
			console.error("Unexpected response:", content);
			toast("An error occured");
		}
	}

	async onSubmitChallenge(event: Event) {
		event.preventDefault();
		await this.signInChallenge();
	}

	async signInChallenge() {
		const form = this.shadowRoot?.querySelector("fm-form-v2");
		if (form === null || form === undefined) {
			return;
		}

		const challenge = form.serialize().get("challenge");
		if (typeof challenge !== "string") {
			return;
		}

		const response: Option<GetLoginChallengeResponse> = await this.fetch(() =>
			get(`/login/${challenge}`),
		);

		if (response.ok === false) {
			return;
		}

		const content = response.value;

		if (content.authenticated) {
			store.dispatch(
				receiveStatus({
					authenticated: true,
				}),
			);
			router.push(this.redirect || "/accounts");
		} else {
			this.error = "Code incorrect.";
			this.state = "Credentials";
		}
	}

	private async fetch<R>(
		f: () => Promise<Result<R, Response>>,
	): Promise<Option<R>> {
		this.loading = true;
		const response: Result<R, Response> = await f();
		this.loading = false;
		if (response.ok === false) {
			await handleError(response.error);
			return None();
		}

		return Some(response.value);
	}
}

async function handleError(response: Response) {
	const json = await response.json();
	if ("message" in json) {
		toast(json.message);
	} else {
		toast("An error occurred");
	}
}

declare global {
	interface HTMLElementTagNameMap {
		"sign-in-view": SignInView;
	}
}
