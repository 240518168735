import { LitElement, html } from "lit";

import sharedStyles from "../../styles/shared.js";
import tableStyles from "../../styles/tables.js";

export default class FormcalcView extends LitElement {
	render() {
		return html`
    ${sharedStyles}
    ${tableStyles}
      <header class="horizontal-menu-header">
        <router-link to="/formcalc">
          <a>List</a>
        </router-link>
        <router-link class="link">
          <a href="/formcalc/calculations">Calculations</a>
        </router-link>
      </header>
      <slot></slot>
    `;
	}
}

customElements.define("formcalc-view", FormcalcView);
