import { LitElement, html } from "lit";
import * as Sentry from "@sentry/browser";
import api from "../../api/index.js";
import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";
import { toast } from "../../utils.js";
import { quantity, money, number } from "../../formatting/numberformats.js";
import { date } from "../../formatting/dateformats.js";

export default class FundsDividendView extends LitElement {
	static get properties() {
		return {
			calc: {
				type: Object,
			},
			cash: {
				type: Array,
			},
			reinvest: {
				type: Array,
			},
			funds: {
				type: Array,
			},
			dividendsummary: {
				type: Array,
			},
			taxsummary: {
				type: Array,
			},
			cashloading: {
				type: Boolean,
			},
			reinvestloading: {
				type: Boolean,
			},
			summaryloading: {
				type: Boolean,
			},
			calculating: {
				type: Boolean,
			},
			bookmsg: {
				type: String,
			},
		};
	}

	constructor() {
		super();
		this.cash = [];
		this.reinvest = [];
		this.funds = [];
		this.dividendsummary = [];
		this.taxsummary = [];
		this.fetch = this.fetch.bind(this);
		this.book = this.book.bind(this);
		this.cashloading = false;
		this.reinvestloading = false;
		this.calculating = false;
		this.today = date(Date.now());
	}

	render() {
		return html`
      ${sharedStyles}
      ${tableStyles}
      ${inputStyles}
      <style>
      :host {
        display: block;
        margin: 12px auto;
        max-width: 1600px;
      }
      .border {border: 1px solid black;vertical-align:top}
      .small {padding:4px;font-size:12px;}
      </style>
       <div class="card">
        <div class="card-header">
          <h1>Fund Dividend</h1>
        </div>
        <div class="card-block">
            <fm-form id="parms" class="reportform no-print" @submit="${
							this.fetch
						}">
            <div class="form-space">
            <label>Fund (class)
              ${this.renderFunds()}
            </label>
          </div>

            <div class="form-space">
              <label>Calculation Date (General Meeting)
                <input type="date"  class="date-input" name="calculationdate" value="${
									this.today
								}" >
              </label>
            </div>
            <div class="form-space">
            <label>Dividend per unit
              <input type="text"  name="dividendpershare" >
            </label>
            </div>
            <div class="form-space">
            <label>Revinvest NAV
              <input type="text"  name="reinvestnav">
            </label>
            </div>
             <div class="form-space">
                <fm-button id="submit_button" type="submit" class="button submit">Calculate</fm-button>
            </div>
            <div class="form-space">
            <fm-button id="book_button" type="button" class="button" @click="${
							this.book
						}">Book</fm-button>
        </div>

            </fm-form>
        </div>
        ${this.renderStatus()}
        ${this.renderSummary()}
        <table>
            <tr>
                <td class="border">${this.renderCash()}</td>
                <td class="border">${this.renderReinvest()}</td>
            </tr>
        </table>
      </div>
   `;
	}

	renderFunds() {
		return html`
    <select name="security_id">
    ${this.funds.map((f) => html`<option value="${f.id}">${f.name}</option>`)}
    </select>
    `;
	}

	renderStatus() {
		if (this.bookmsg) {
			return html`
    <div class="card">${this.bookmsg}</div>
    `;
		}
		return "";
	}

	renderSummary() {
		if (this.calculating) {
			return html`<div class="card">Calculating...</div>`;
		}
		if (this.cashloading || this.reinvestloading) {
			return html`<div class="card">Loading...</div>`;
		}
		return html`
  <div class="card" style="max-width:800px">
  <table>
  <tr>
  <th colspan="2" style="text-align:center">Summary</th>
  </tr>
  <tr><td class="border">
    <table>
      <tr>
        <th></th><th class="numeric">Cash</th><th class="numeric">Reinvest</th><th class="numeric">Total</th>
      </tr>
        ${(this.dividendsummary || []).map(
					(s) => html`
          <tr>
          <td>${s.component}</td>
          <td class="numeric">${number(s.cash, s.format)}</td>
          <td class="numeric">${number(s.reinvest, s.format)}</td>
          <td class="numeric">${number(s.cash + s.reinvest, s.format)}</td>
        </tr>
        `,
				)}
    </table>
  </td>
  <td class="border">
      <table>
      <tr>
        <th class="numeric">Custodytype</th><th class="numeric">Tax</th>
        </tr>
            ${(this.taxsummary || []).map(
							(s) => html`
              <tr>
              <td>${s.custodytype_code}</td>
              <td class="numeric">${money(s.tax)}</td>
            </tr>
            `,
						)}
      </table>
  </td>
  </tr>
  </table>
  </div> `;
	}

	renderCash() {
		if (this.calculating) {
			return html`Calculating...`;
		}

		if (this.cashloading) {
			return html`Loading...`;
		}

		return html`
    <table>
        <tr>
        <th colspan="7">Cash Dividend</th>
        </tr>
        <tr>
            <th>Custody</th>
            <th>Type</th>
            <th class="small numeric">Position</th>
            <th class="small numeric">Gross</th>
            <th class="small numeric">Tax</th>
            <th class="small numeric">Net</th>
            <th class="small">Tradedate</th>
            <th class="small" >Valuedate</th>
        </tr>
        ${this.cash.map(
					(c) => html`
        <tr>
            <td>${c.custody_code}</td>
            <td>${c.custodytype_code}</td>
            <td class="small numeric">${quantity(c.quantity)}</td>
            <td class="small numeric">${money(c.gross)}</td>
            <td class="small numeric">${money(c.tax)}</td>
            <td class="small numeric">${money(c.net)}</td>
            <td class="small">${c.tradedate}</td>
            <td class="small">${c.valuedate}</td>
        </tr>
        `,
				)}
    </table>
    `;
	}

	renderReinvest() {
		if (this.calculating) {
			return html`Calculating...`;
		}

		if (this.reinvestloading) {
			return html`Loading...`;
		}

		return html`
  <table>
      <tr>
      <th colspan="8">Reinvested Dividend</th>
      </tr>
      <tr>
          <th>Custody</th>
          <th>Type</th>
          <th class="small numeric">Position</th>
          <th class="small numeric">Gross</th>
          <th class="small numeric">Tax</th>
          <th class="small numeric">Net</th>
          <th class="small numeric">Reinvest</th>
          <th class="small">Tradedate</th>
          <th class="small">Valuedate</th>
      </tr>
      ${this.reinvest.map(
				(c) => html`
        <tr>
        <td>${c.custody_code}</td>
        <td>${c.custodytype_code}</td>
        <td class="small numeric">${quantity(c.quantity)}</td>
        <td class="small numeric">${money(c.gross)}</td>
        <td class="small numeric">${money(c.tax)}</td>
        <td class="small numeric">${money(c.net)}</td>
        <td class="small numeric">${quantity(c.reinvest_quantity)}</td>
        <td class="small">${c.tradedate}</td>
        <td class="small">${c.valuedate}</td>
        </tr>
      `,
			)}
  </table>`;
	}

	async connectedCallback() {
		super.connectedCallback();
		this.funds = await api.get("/lov/funds");
	}

	async fetch(event) {
		const target = event.target;
		const button = this.shadowRoot.querySelector("#submit_button");
		button.loading = true;
		try {
			const parms = this.shadowRoot.querySelector("#parms").value;
			this.calculating = true;
			this.cashloading = true;
			this.reinvestloading = true;
			this.summaryloading = true;
			const q = `?security_id=${parms.security_id}&calculationdate=${parms.calculationdate}&dividendpershare=${parms.dividendpershare}&reinvestnav=${parms.reinvestnav}`;
			this.calc = await api.get(`/funds/calcdividend${q}`);
			this.calculating = false;
			this.cash = await api.get(`/funds/cashdividend?calc_id=${this.calc.id}`);
			this.cashloading = false;
			this.reinvest = await api.get(
				`/funds/reinvestdividend?calc_id=${this.calc.id}`,
			);
			this.reinvestloading = false;
			this.dividendsummary = await api.get(
				`/funds/dividendsummary?calc_id=${this.calc.id}`,
			);
			this.taxsummary = await api.get(
				`/funds/taxsummary?calc_id=${this.calc.id}`,
			);
			this.summaryloading = false;
		} catch (err) {
			Sentry.captureException(err);
			toast("Could not produce list");
		}
		button.loading = false;
	}

	async book() {
		const button = this.shadowRoot.querySelector("#book_button");
		button.loading = true;
		this.booking = true;
		let resp = {};
		try {
			let order_count = 0;
			for (let i = 0; i < this.cash.length; i++) {
				this.bookmsg = `Cash ${this.cash[i].custody_code}`;
				resp = await api.post(`/orders/dividend/${this.cash[i].data_id}`);
				order_count += 1;
			}
			for (let i = 0; i < this.reinvest.length; i++) {
				this.bookmsg = `Reinvest ${this.reinvest[i].custody_code}`;
				resp = await api.post(`/orders/dividend/${this.reinvest[i].data_id}`);
				order_count += 1;
			}
			this.bookmsg = `${order_count} orders booked`;
		} catch (err) {
			Sentry.captureException(err);
			toast("Could not Book Dividends");
		}
		button.loading = false;
		this.booking = false;
	}
}

customElements.define("funds-dividend-view", FundsDividendView);
