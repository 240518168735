import { html } from "lit";

import tableStyles from "../../styles/tables.js";
import sharedStyles from "../../styles/shared.js";
import inputStyles from "../../styles/input.js";

export function ext_render(classobj) {
	return html`
    ${sharedStyles} ${tableStyles} ${inputStyles}
    <style>
      :host {
        display: block;
        margin: 24px auto;
        max-width: 1800px;
      }

      tr.expanded td {
        font-weight: bold;
        background-color: lightgray;
      }

      tr.details {
        background-color: lightgray;
      }

      tr.deleted td {
        color: gray;
      }

      tr.deleted td a {
        color: lightgray;
        text-decoration: underline;
      }
    </style>
    <div class="card">
      <div class="card-header">
        <h1>Answers to AML-questions</h1>
      </div>

      <fm-form id="parms" class="form-grid" @submit="${classobj.refresh}">
        <div class="form-field">
          <label
            >Asked date
            <fm-date-range
              id="regdate"
              name="regdate"
              names="ans-"
            ></fm-date-range>
          </label>
        </div>
        <div class="form-field"></div>
        <div class="form-field">
          <label
            >Search User
            <input
              type="search"
              name="iquery"
              value="${classobj.iquery || ""}"
              class="search-input"
              style="width:45%"
            />
          </label>
        </div>
        <div class="form-field">
          <label
            >Search Question
            <input
              type="search"
              name="qquery"
              value="${classobj.qquery || ""}"
              class="search-input"
              style="width:45%"
            />
          </label>
        </div>

        <fm-button
          type="submit"
          id="submit_button"
          class="btn"
          style="max-width:80px"
          >Refresh</fm-button
        >
      </fm-form>

      <table>
        <thead>
          <tr>
            <th>Question</th>
            <th>Asked</th>
            <th>Custody</th>
            <th>Investor</th>
            <th>Answer</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          ${classobj.rows.map(
						(r) => html` ${renderAnswer(r, classobj)}
            ${renderDetails(r, classobj)}`,
					)}
        </tbody>
      </table>
    </div>
  `;
}

function renderAnswer(row, classobj) {
	return html` <tr class="${row.ask_id === classobj.ask_id ? "expanded" : ""}">
    <td>${row.code} ${row.question}</td>
    <td>${row.ask_date}</td>
    <td>${row.custody_code}</td>
    <td>${row.user_name}</td>
    <td>${row.answer}</td>
    <td>
      ${
				row.id
					? html` <fm-button
            type="button"
            data-id="${row.ask_id}"
            @click="${classobj.onShowDetails}"
            class="btn--small"
            >Vis detaljer</fm-button
          >`
					: ""
			}
    </td>
  </tr>`;
}

function renderDetails(row, classobj) {
	if (row.ask_id === classobj.ask_id) {
		return html` ${
			classobj.answers
				? html`${classobj.answers.map(
						(a, idx) => html`
      <tr class="details ${a.deleted ? "deleted" : ""}">
        <td valign="top">
          <strong>Dato:</strong> ${a.reg_date}<br />
          <strong>Svar:</strong> ${a.answer}<br />
          <strong>Uddybning:</strong> ${a.answer_other}<br />
          <strong>Kræver dokumentation?</strong></th> ${
						a.requireddocumentation ? "Ja" : "Nej"
					}<br />
          <strong>File(r):</strong> ${
						a.files
							? html`${a.files.map(
									(f, idx) =>
										html`<a
                      href="#"
                      data-id="${f.id}"
                      data-name="${f.name}"
                      @click="${classobj.onFileClick}"
                      >${f.name}${idx < a.files.length - 1 ? ", " : ""}</a
                    >`,
							  )}`
							: "Ingen filer vedhæftet"
					}
        </td>
        <td colspan="5" valign="top">
          <div style="display: flex; flex-direction: column; row-gap: 8px;">
            ${html`
              ${
								!a.deleted
									? html` <strong>Ny handling</strong>
                    <select id="answer-actions">
                      <option></option>
                      ${classobj.answeractions.map(
												(aa) =>
													html`<option value="${aa.id}">${aa.name}</option>`,
											)}
                    </select>
                    <textarea
                      id="answer-actions-comment"
                      placeholder="Kommentar"
                      style="height: 80px;"
                    ></textarea>
                    <fm-button
                      type="button"
                      data-id="${a.id}"
                      @click="${classobj.onSaveAnswerAction}"
                      class="btn--xsmall"
                      style="max-width: 40px; align-self: flex-end;"
                      >Gem svar</fm-button
                    >`
									: ""
							}
              <strong>Handlingshistorik</strong>
              ${
								a.actions
									? html`${a.actions.map(
											(aa, idx) =>
												html` <div style="margin-bottom: 16px">
                        <div><strong>Dato:</strong> ${aa.reg_date}</div>
                        <div><strong>Person:</strong> ${aa.name}</div>
                        <div>
                          <strong>Handling:</strong> ${aa.code}
                          ${aa.description}
                        </div>
                        <div><strong>Kommentar:</strong> ${aa.adm_comment}</div>
                      </div>`,
									  )}`
									: html`<div>(ingen handlinger foretaget)</div>`
							}
            `}
          </div>
        </td>
      </tr>
      `,
				  )}`
				: ""
		}`;
	} else {
		return "";
	}
}
